const imageServer = 'https://images.evetech.net'
const defaultSize = 128

const moonRarityMap = {
  4: ['bitumens', 'coesite', 'sylvite', 'zeolites'],
  8: ['cobaltite', 'euxenite', 'scheelite', 'titanite'],
  16: ['chromite', 'otavite', 'sperrylite', 'vanadinite'],
  32: ['carnotite', 'cinnabar', 'pollucite', 'zircon'],
  64: ['loparite', 'monazite', 'xenotime', 'ytterbite']
}

const utils = {
  DateFormat: 'YYYY/MM/DD HH:mm',
  ZKillboard: (type, id) => `https://zkillboard.com/${type}/${id}/`,
  EVEWho: (type, id) => `https://evewho.com/${type}/${id}/`,
  GetDiscordAvatar: discordUser =>
    `https://cdn.discordapp.com/avatars/${discordUser.id}/${discordUser.avatar}`,
  GetAllianceLogo: (AllianceID, size = defaultSize) =>
    `${imageServer}/alliances/${AllianceID}/logo?${new URLSearchParams({ size }).toString()}`,
  GetCharacterPortrait: (CharacterID, size = defaultSize) =>
    `${imageServer}/characters/${CharacterID}/portrait?${new URLSearchParams({ size }).toString()}`,
  GetCorporationLogo: (CorporationID, size = defaultSize) =>
    `${imageServer}/corporations/${CorporationID}/logo?${new URLSearchParams({ size }).toString()}`,
  GetTypeIcon: (TypeID, size = defaultSize) =>
    `${imageServer}/types/${TypeID}/icon?${new URLSearchParams({ size }).toString()}`,
  GetTypeBlueprint: (TypeID, size = defaultSize) =>
    `${imageServer}/types/${TypeID}/bp?${new URLSearchParams({ size }).toString()}`,
  GetTypeRender: (TypeID, size = defaultSize) =>
    `${imageServer}/types/${TypeID}/render?${new URLSearchParams({ size }).toString()}`,
  ThousandSeparator: num =>
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  FindMoonRarityValue: name => {
    const normlizedName = name.split(' ').pop()
    for (const rarity in moonRarityMap) {
      if (moonRarityMap[rarity].includes(normlizedName.toLowerCase())) { return rarity * 1 }
    }
    return 'Error'
  }
}

export default utils
