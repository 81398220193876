import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'

import orderBy from 'lodash.orderby'

import {
  EuiPageTemplate,
  EuiText,
  EuiSpacer,
  EuiBasicTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth
} from '@elastic/eui'

import api from '../api'
import Utils from '../Utils'

const StatusIcon = ({ status }) => {
  if (status === 0) return <EuiHealth color='danger'>RED</EuiHealth>
  if (status === 1) return <EuiHealth color='warning'>YELLOW</EuiHealth>
  if (status === 2) return <EuiHealth color='success'>GREEN</EuiHealth>
  return <EuiHealth color='active'>ERROR <small>#{status}</small></EuiHealth>
}
StatusIcon.propTypes = {
  status: PropTypes.number.isRequired
}

const ItemIcon = ({ typeID }) => {
  const [typeInfo, setTypeInfo] = useState({})
  useEffect(() => {
    (async () => {
      if (Object.keys(typeInfo || {}).length === 0) setTypeInfo(await api.SDE.GetType(typeID))
    })()
  }, [typeInfo, typeID])

  return <img src={Utils.GetTypeIcon(typeID, 64)} width={32} className='radius50' alt={(typeInfo?.name || {}).en || 'Loading...'} />
}
ItemIcon.propTypes = {
  typeID: PropTypes.number.isRequired
}

const ItemName = ({ typeID }) => {
  const [typeInfo, setTypeInfo] = useState({})
  useEffect(() => {
    (async () => {
      if (Object.keys(typeInfo || {}).length === 0) setTypeInfo(await api.SDE.GetType(typeID))
    })()
  }, [typeInfo, typeID])

  return (typeInfo?.name || {}).en || 'Loading...'
}
ItemName.propTypes = {
  typeID: PropTypes.number.isRequired
}
const LocationName = ({ systemID }) => {
  const [systemInfo, setSystemInfo] = useState({})
  useEffect(() => {
    (async () => {
      if (Object.keys(systemInfo || {}).length === 0) setSystemInfo(await api.GetSystem(systemID))
    })()
  }, [systemInfo, systemID])

  return <EuiText size='s'>{systemInfo?.name || 'Loading...'} <small>({(systemInfo?.security_status || 0).toFixed(2)})</small></EuiText>
}
LocationName.propTypes = {
  systemID: PropTypes.number.isRequired
}

const AdminStatuses = () => {
  const [statuses, setStatuses] = useState([])

  useEffect(() => {
    (async () => setStatuses(await api.AdminGetStatuses()))()
  }, [])

  const columns = [
    {
      field: 'index',
      name: 'N.',
      render: index => <small>#{index}</small>,
      width: '50px'
    },
    {
      field: 'skillpoints',
      name: 'Skillpoints',
      render: sp => <NumericFormat value={sp} displayType='text' thousandSeparator suffix=' SP' />,
      width: '200px'
    },
    {
      field: 'assets',
      name: 'Assets (at location)',
      render: assets => <EuiFlexGroup direction='column'>
        {(assets || []).map(asset => <EuiFlexItem key={`${asset.type}_${asset.location}`}>
          <EuiFlexGroup gutterSize='s' alignItems='center'>
            <EuiFlexItem grow={false}><ItemIcon typeID={asset.type} /></EuiFlexItem>
            <EuiFlexItem><strong><ItemName typeID={asset.type} /></strong></EuiFlexItem>
            <EuiFlexItem><LocationName systemID={asset.location} /></EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>)}
      </EuiFlexGroup>
    },
    {
      field: 'skills',
      name: 'Skills (at level)',
      render: skills => <EuiFlexGroup direction='column'>
        {(skills || []).map(skill => <EuiFlexItem key={skill.skill}>
          <strong><ItemName typeID={skill.skill} /></strong>
          <div className='skillLevelContainer'>
            {[1, 2, 3, 4, 5].map(l => <div key={l} className={`skillLevel ${l <= skill.level ? 'done' : 'next'}`} />)}
          </div>
                                     </EuiFlexItem>)}
      </EuiFlexGroup>
    },
    {
      field: 'status',
      name: 'Status',
      render: status => <StatusIcon status={status} />,
      width: '150px'
    }
  ]

  return (
    <EuiPageTemplate.Section grow>
      <EuiText><h1>Statuses</h1></EuiText>
      <EuiSpacer />
      <EuiBasicTable items={orderBy(statuses, 'index')} columns={columns} />
    </EuiPageTemplate.Section>
  )
}

export default AdminStatuses
