import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Countdown from 'react-countdown'
import orderBy from 'lodash.orderby'
import moment from 'moment'

import {
  EuiPageTemplate,
  EuiText,
  EuiSpacer,
  EuiBasicTable,
  EuiAvatar,
  EuiBeacon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth
} from '@elastic/eui'

import ItemIcon from '../components/ItemIcon'
import ItemName from '../components/ItemName'

import Utils from '../Utils'
import api from '../api'
import utils from '../Utils'

// typeID: multiplier
// Strontium = 100 * multiplier / h
// Fuel      =  10 * multiplier / h
const starbaseFuelMultiplier = {
  20060: 1, // Amarr Control Tower Small
  27610: 1, // Angel Control Tower Small
  27592: 1, // Blood Control Tower Small
  20062: 1, // Caldari Control Tower Small
  27594: 1, // Dark Blood Control Tower Small
  27612: 1, // Domination Control Tower Small
  27600: 1, // Dread Guristas Control Tower Small
  20064: 1, // Gallente Control Tower Small
  27598: 1, // Guristas Control Tower Small
  20066: 1, // Minmatar Control Tower Small
  27784: 1, // Sansha Control Tower Small
  27604: 1, // Serpentis Control Tower Small
  27606: 1, // Shadow Control Tower Small
  27790: 1, // True Sansha Control Tower Small
  20059: 2, // Amarr Control Tower Medium
  27607: 2, // Angel Control Tower Medium
  27589: 2, // Blood Control Tower Medium
  20061: 2, // Caldari Control Tower Medium
  27591: 2, // Dark Blood Control Tower Medium
  27609: 2, // Domination Control Tower Medium
  27597: 2, // Dread Guristas Control Tower Medium
  20063: 2, // Gallente Control Tower Medium
  27595: 2, // Guristas Control Tower Medium
  20065: 2, // Minmatar Control Tower Medium
  27782: 2, // Sansha Control Tower Medium
  27601: 2, // Serpentis Control Tower Medium
  27603: 2, // Shadow Control Tower Medium
  27788: 2, // True Sansha Control Tower Medium
  12235: 4, // Amarr Control Tower
  27539: 4, // Angel Control Tower
  27530: 4, // Blood Control Tower
  16213: 4, // Caldari Control Tower
  27532: 4, // Dark Blood Control Tower
  27540: 4, // Domination Control Tower
  27535: 4, // Dread Guristas Control Tower
  12236: 4, // Gallente Control Tower
  27533: 4, // Guristas Control Tower
  16214: 4, // Minmatar Control Tower
  27780: 4, // Sansha Control Tower
  27536: 4, // Serpentis Control Tower
  27538: 4, // Shadow Control Tower
  27786: 4, // True Sansha Control Tower
}

const getEta = (days, hours, minutes, seconds, completed) => {
  if (completed) return '-'
  const slices = []
  if (days > 0) slices.push(`${days}d`)
  if (hours > 0) slices.push(`${hours}h`)
  if (minutes > 0) slices.push(`${minutes}m`)
  if (seconds > 0) slices.push(`${seconds}s`)
  return slices.join(' ')
}

const getCountdown = dt => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return <span>{getEta(days, hours, minutes, seconds, completed)}</span>
  }
  renderer.propTypes = {
    days: PropTypes.number,
    hours: PropTypes.number,
    minutes: PropTypes.number,
    seconds: PropTypes.number,
    completed: PropTypes.bool
  }
  return <Countdown date={moment.utc(dt).toDate()} renderer={renderer} />
}

const structureColumns = [
  {
    render: function renderType (struct) {
      return <EuiAvatar imageUrl={Utils.GetTypeRender(struct.structure.type_id)} size='l' name={struct.structureInfo.name} />
    },
    width: '45px'
  },
  {
    name: 'Structure',
    render: function renderStructure (struct) {
      return (
        <EuiText>
          <strong>{struct.structureInfo.name}</strong><br />
          {struct.system.name} <small>({struct.system.security_status.toFixed(2)})</small> / {struct.region.name}
        </EuiText>
      )
    }
  },
  {
    name: 'State',
    render: function renderState (struct) {
      if (struct.structure.unanchors_at !== '0001-01-01T00:00:00Z') {
        return <EuiText color='accent'>
          unanchoring<br />
          {getCountdown(struct.structure.unanchors_at)}<br />
          <small>{moment.utc(struct.structure.unanchors_at).format(Utils.DateFormat)}</small>
        </EuiText>
      }
      if (struct.structure.state === 'shield_vulnerable') {
        return <EuiText color='success'>
          {struct.structure.state.replace(/_/g, ' ')}
        </EuiText>
      }
      return <EuiText color={struct.structure.state === 'hull_reinforce' ? 'danger' : 'accent'}>
        {struct.structure.state.replace(/_/g, ' ')}<br />
        {getCountdown(struct.structure.state_timer_end)}<br />
        <small>{moment.utc(struct.structure.state_timer_end).format(Utils.DateFormat)}</small>
      </EuiText>
    },
  },
  {
    name: 'Reinforce',
    render: function renderState (struct) {
      if (struct.structure.next_reinforce_apply !== '0001-01-01T00:00:00Z') {
        return <EuiText>
          Next Reinforce Apply:<br />
          {getCountdown(struct.structure.next_reinforce_apply)}<br />
          <small>{moment.utc(struct.structure.next_reinforce_apply).format(Utils.DateFormat)}</small>
        </EuiText>
      }
      return <EuiText>
        <small>Reinforce Hour: {struct.structure.reinforce_hour}:00</small>
      </EuiText>
    }
  },
  {
    name: 'Fuel Expire',
    render: function renderFuel (struct) {
      if (struct.structure.fuel_expires !== '0001-01-01T00:00:00Z') {
        return <EuiText>{getCountdown(struct.structure.fuel_expires)}<br /><small>{moment.utc(struct.structure.fuel_expires).format(Utils.DateFormat)}</small></EuiText>
      }
      return <EuiText color='danger'>Low Power</EuiText>
    },
    width: '150px'
  },
  {
    name: 'Services',
    render: function renderState (struct) {
      return (
        <EuiFlexGroup direction='column' gutterSize='xs'>{orderBy(struct.structure.services, ['name']).map(service => {
          return (
            <EuiFlexItem key={service.name}>
              {
                service.state !== 'online'
                  ? <EuiHealth color='danger'>{service.name}</EuiHealth>
                  : <EuiFlexGroup gutterSize='s' alignItems='center'>
                      <EuiFlexItem grow={false}><EuiBeacon /></EuiFlexItem>
                      <EuiFlexItem grow={false}>{service.name}</EuiFlexItem>
                  </EuiFlexGroup>
              }
            </EuiFlexItem>
          )
        })}
        </EuiFlexGroup>
      )
    },
    width: '200px'
  }
]

const starbasesColumns = [
  {
    render: function renderType (s) {
      return <EuiAvatar imageUrl={Utils.GetTypeRender(s.starbase.type_id)} size='l' name={s.system.name} />
    },
    width: '45px'
  },
  {
    name: 'Structure',
    render: function renderStructure (s) {
      return (
        <EuiText>
          <strong>{s.moon.name}</strong><br />
          {s.system.name} <small>({s.system.security_status.toFixed(2)})</small> / {s.region.name}
        </EuiText>
      )
    }
  },
  {
    name: 'State',
    render: function renderState (s) {
      if (s.starbase.state === 'online')
        return <EuiText color='success'>Online</EuiText>
      if (s.starbase.state === 'offline')
        return <EuiText color='accent'>Offline</EuiText>
      return <EuiText color='danger'>
        {s.starbase.state}<br />
        {getCountdown(s.starbase.reinforced_until)}<br />
        <small>{moment.utc(s.starbase.reinforced_until).format(Utils.DateFormat)}</small>
      </EuiText>
    },
    width: '180px'
  },
  {
    name: 'Fuel',
    render: function renderFuel (s) {
      if (!s.starbaseInfo.fuels) return '-'
      return <EuiFlexGroup direction='column' gutterSize='s'>
        {s.starbaseInfo.fuels.map(fuel => {
          return <EuiFlexItem>
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <ItemIcon typeID={fuel.type_id} /> 
              </EuiFlexItem>
              <EuiFlexItem>
                {fuel.quantity.toFixed(0)}x <ItemName typeID={fuel.type_id} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        })}
      </EuiFlexGroup>
    }
  },
  {
    name: 'Fuel Duration',
    render: function renderFuelDuration (s) {
      const multiplier = starbaseFuelMultiplier[s.starbase.type_id]
      const fuels = s.starbaseInfo.fuels || []
      const strontium = fuels.find(f => f.type_id === 16275)?.quantity || 0
      const blocks = fuels.filter(f => f.type_id !== 16275).reduce((acc, curr) => acc + curr.quantity, 0)
      const fuelExpiration = moment.utc().add(blocks / (10  * multiplier), 'hours')
      return <EuiFlexGroup direction='column' gutterSize='s'>
        <EuiFlexItem>
          {blocks > 0 ? fuelExpiration.format(utils.DateFormat) : '-'}<br />
          {getCountdown(fuelExpiration)}
        </EuiFlexItem>
        <EuiFlexItem>Reinforce: {strontium > 0 ? `${(strontium / (100  * multiplier)).toFixed(1)}h` : '-'}</EuiFlexItem>
      </EuiFlexGroup>
    },
    width: '180px'
  }
]

const AdminStructures = () => {
  const [structuresList, setStructuresList] = useState([])
  const [starbasesList, setStarbasesList] = useState([])

  useEffect(() => {
    api.GetStructures().then(setStructuresList)
    api.GetStarbases().then(setStarbasesList)
  }, [])

  return (
    <EuiPageTemplate.Section grow>
      <EuiText><h1>Structures</h1></EuiText>
      <EuiSpacer />
      <EuiBasicTable
        items={orderBy(structuresList, ['structure.fuel_expires'])}
        columns={structureColumns}
      />
      <EuiSpacer />
      <EuiText><h1>Starbases</h1></EuiText>
      <EuiSpacer />
      <EuiBasicTable
        items={orderBy(starbasesList.map(s => ({
          ...s,
          total_fuel: (s.starbaseInfo.fuels || []).filter(f => f.type_id !== 16275).reduce((acc, curr) => acc + curr.quantity, 0)
        })), ['starbase.state', '-total_fuel']).reverse()}
        columns={starbasesColumns}
      />
    </EuiPageTemplate.Section>
  )
}

export default AdminStructures
