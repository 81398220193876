import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash.groupby'
import orderBy from 'lodash.orderby'

import {
  EuiSpacer,
  EuiTitle,
  EuiAccordion,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiStat,
  EuiPanel,
  EuiFieldSearch,
  EuiSkeletonText
} from '@elastic/eui'

import Utils from '../Utils'
import api from '../api'

const groupsOrder = [
  257, 275, 1216, 1240, 1210, 1209,
  1213, 255, 256, 273, 272, 1217,
  269, 278, 258, 266, 274, 1220,
  268, 270, 1218, 1241, 1545
]

const SkillEntry = ({ skill }) => {
  return (
    <EuiFlexGroup alignItems='center' className='hoverable' gutterSize='none'>
      <EuiFlexItem>
        <EuiText>{skill.skill_name}</EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <div className='skillLevelContainer'>
          {[1, 2, 3, 4, 5].map(l => {
            return (
              <div
                key={l}
                className={`skillLevel ${
                  l <= skill.trained_skill_level ? 'done' : 'next'
                }`}
              />
            )
          })}
        </div>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

SkillEntry.propTypes = {
  skill: PropTypes.object.isRequired
}

const processSkills = async (skills, filter) => {
  const filteredSkills = []
  const typesData = await Promise.all(skills.map(s => s.skill_id).map(api.SDE.GetType))
  for (const i in skills) {
    const skill = skills[i]
    const typeData = typesData[i] || { groupID: 0, name: { en: '' } }
    const skillObject = {
      ...skill,
      group_id: typeData.groupID,
      skill_name: typeData.name.en
    }
    if (filter.length) {
      filteredSkills.push(
        skillObject.skill_name
          .toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase())
          ? skillObject
          : false
      )
      continue
    }
    filteredSkills.push(skillObject)
  }

  const groupedSkills = groupBy(
    filteredSkills.filter(x => x),
    'group_id'
  )

  const result = []

  const groupsData = await Promise.all(Object.keys(groupedSkills).map(groupId => api.SDE.GetTypeGroup(groupId)))
  for (const i in Object.keys(groupedSkills)) {
    const groupId = Object.keys(groupedSkills)[i]
    const groupData = groupsData[i]
    const skillGroup = {
      group_id: groupId,
      group_name: groupData.name.en,
      skills: orderBy(groupedSkills[groupId], ['skill_name'])
    }
    result.push(skillGroup.skills.length === 0 ? null : skillGroup)
  }

  return result
}

const Skills = ({ characterId }) => {
  const [filter, setFilter] = useState('')
  const [skillList, setSkillList] = useState({})
  const [characterSkills, setCharacterSkills] = useState([])
  const [errors, setErrors] = useState(null)

  useEffect(
    () => {
      api
      .GetSkills(characterId)
      .then(res => {
        setSkillList(res)
        return res
      })
      .then(res => {
        if (res.error) setErrors(res.error)
      })
      .catch(setErrors)
    },
    []
  )
  useEffect(
    () => {
      if (!skillList || skillList.error || Object.keys(skillList).length === 0) return
      processSkills(skillList.skills, filter).then(setCharacterSkills)
    },
    [skillList, filter]
  )

  if (errors) return <EuiText color='danger'>{errors.toString()}</EuiText>
  if (!skillList) return <EuiSkeletonText lines={3} />

  return (
    <>
      <EuiSpacer />
      <EuiTitle>
        <h3>Skills</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel>
            {Object.keys(skillList).length !== 0 && (
              <EuiStat
                title={Utils.ThousandSeparator(
                  skillList.total_sp + (skillList.unallocated_sp || 0)
                )}
                description='Total skill points'
                textAlign='center'
              />
            )}
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>
            {
              Object.keys(skillList).length !== 0 &&
                <EuiStat
                  title={Utils.ThousandSeparator(skillList.unallocated_sp || 0)}
                  description='Unallocated skill points'
                  textAlign='center'
                />
            }
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>
            {
              Object.keys(skillList).length !== 0 &&
                <EuiStat
                  title={Utils.ThousandSeparator(skillList.total_sp)}
                  description='Used skill points'
                  textAlign='center'
                />
            }
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFieldSearch
            fullWidth
            placeholder='Search Skill..'
            value={filter}
            onChange={e => setFilter(e.target.value)}
            aria-label='Filter Skills'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      {groupsOrder.map(groupId => {
        const groupData = characterSkills.find(g => g.group_id === `${groupId}`)
        if (!groupData) return null
        return (
          <EuiAccordion
            key={groupId}
            id={`Group${groupId}`}
            buttonContent={groupData.group_name}
            arrowDisplay='right'
            paddingSize='l'
            className='skillGroup'
          >
            {groupData.skills.map(skill => (
              <SkillEntry key={skill.skill_id} skill={skill} />
            ))}
          </EuiAccordion>
        )
      })}
      {characterSkills.length === 0 && <EuiSkeletonText lines={10} />}
    </>
  )
}

Skills.propTypes = {
  characterId: PropTypes.number.isRequired
}

export default Skills
