import {
  EuiPageTemplate,
  EuiTextAlign,
  EuiText
} from '@elastic/eui'

const Login = () => {
  return (
    <EuiPageTemplate.Section grow>
      <EuiTextAlign textAlign='center'>
        <EuiText>
          Page Not Found
        </EuiText>
      </EuiTextAlign>
    </EuiPageTemplate.Section>
  )
}

export default Login
