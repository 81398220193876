import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { locale as momentLocale } from 'moment'
import { ToastContainer } from 'react-toastify'
import { RecoilRoot } from 'recoil'

import { EuiProvider } from '@elastic/eui'
import './theme_dark.scss'
import AppMain from './AppMain'

momentLocale(window.navigator.language || 'it-IT')

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<RecoilRoot>
  <BrowserRouter>
  <EuiProvider colorMode='dark'>
    <AppMain />
  </EuiProvider>
  </BrowserRouter>
  <ToastContainer />
</RecoilRoot>);
