import { useState, useEffect } from 'react'
import { Axis, BarSeries, Chart, Settings } from '@elastic/charts'
import { EUI_CHARTS_THEME_DARK } from '@elastic/eui/dist/eui_charts_theme'
import moment from 'moment'

import {
  EuiPageTemplate,
  EuiText,
  EuiSpacer,
  EuiButtonGroup,
  EuiInMemoryTable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiStat
} from '@elastic/eui'

import FormatNumber from '../components/FormatNumber'

import api from '../api'
import Utils from '../Utils'
import sumBy from 'lodash.sumby'

const corps = [
  {
    id: '0',
    value: 98630834,
    label: 'Very Italian People',
    text: 'VIP'
  },
  {
    id: '1',
    value: 98528272,
    label: 'Pizza Boys',
    text: 'PZBS'
  },
  {
    id: '2',
    value: 98644299,
    label: 'Very Italian Disinfestation',
    text: 'VID'
  },
  {
    id: '3',
    value: 98108996,
    label: 'Unknown Mining Venture',
    text: 'UNKO'
  }
]

const AdminCorpWallet = () => {
  const [selectedCorp, setSelectedCorp] = useState(0)
  const [divisions, setDivisions] = useState([])
  const [walletBalances, setWalletBalances] = useState([])
  const [selectedDivision, setSelectedDivision] = useState(1)
  const [journal, setJournal] = useState([])

  useEffect(() => {
    api.AdminGetDivisions(corps[selectedCorp].value).then(r => setDivisions(r.wallet))
    api.AdminGetWallet(corps[selectedCorp].value).then(setWalletBalances)
  }, [selectedCorp])

  useEffect(() => {
    if (divisions.length === 0) return
    api.AdminGetJournal(corps[selectedCorp].value, divisions[selectedDivision - 1].division).then(setJournal)
  }, [selectedCorp, divisions, selectedDivision])

  const groupedJournal = {}
  for (const journalEntry of journal) {
    if (!groupedJournal[journalEntry.ref_type]) groupedJournal[journalEntry.ref_type] = 0
    groupedJournal[journalEntry.ref_type] += journalEntry.amount || 0
  }

  return (
    <EuiPageTemplate.Section grow>
      <EuiText><h1>Corp Wallet</h1></EuiText>
      <EuiSpacer />
      <EuiButtonGroup
        legend='Corporation'
        options={corps}
        idSelected={`${selectedCorp}`}
        onChange={s => setSelectedCorp(1 * s)}
        buttonSize='compressed'
        isFullWidth
        color='primary'
      />
      <EuiSpacer />
      <EuiButtonGroup
        legend='Divisions'
        options={divisions.map(d => ({
          id: `${d.division}`,
          value: d.division,
          label: <>
            {d.name || (d.division === 1 ? 'Master Wallet' : `Division ${d.division}`)}<br />
            <small><FormatNumber amount={walletBalances.find(w => w.division === d.division)?.balance || 0} isTransaction /></small>
          </>
        }))}
        idSelected={`${selectedDivision}`}
        onChange={s => setSelectedDivision(1 * s)}
        buttonSize='m'
        isFullWidth
        color='primary'
      />
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiStat
            title={<FormatNumber amount={sumBy(journal, j => j.amount > 0 ? j.amount : 0)} isTransaction />}
            description='Income'
            textAlign='center'
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiStat
            title={<FormatNumber amount={sumBy(journal, j => j.amount < 0 ? j.amount : 0)} isTransaction />}
            description='Expenses'
            textAlign='center'
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiStat
            title={<FormatNumber amount={
              sumBy(journal, j => j.amount > 0 ? j.amount : 0) + sumBy(journal, j => j.amount < 0 ? j.amount : 0)
            } isTransaction />}
            description='Delta'
            textAlign='center'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <Chart size={{ height: 500 }}>
        <Settings
          theme={EUI_CHARTS_THEME_DARK.theme}
          rotation={90}
          showLegend={true}
          legendPosition='bottom'
          orderOrdinalBinsBy={true}
        />
        <BarSeries
          id="types"
          name="Types"
          data={
            Object.keys(groupedJournal).map(g => ({
              journalType: g.replace(/_/g, ' '),
              amount: groupedJournal[g],
              stackType: groupedJournal[g] > 0 ? 'Income' : 'Expenses'
            }))}
          xAccessor="journalType"
          yAccessors={['amount']}
          splitSeriesAccessors={['stackType']}
          
          stackAccessors={['stackType']}
          color={[
            groupedJournal[Object.keys(groupedJournal)[0]] > 0 ? '#54b399' : '#f3b3a6',
            groupedJournal[Object.keys(groupedJournal)[0]] > 0 ? '#f3b3a6' : '#54b399'
          ]}
        />
        <Axis
          id="bottom-axis"
          position={'left'}
        />
        <Axis
          id="left-axis"
          showGridLines
          position={'bottom'}
          
        />
      </Chart>
      <EuiSpacer />
      <EuiInMemoryTable
        tableCaption='Wallet Journal'
        items={journal}
        search={true}
        columns={[
          {
            field: 'date',
            name: 'Date',
            truncateText: false,
            textOnly: true,
            sortable: true,
            width: '150px',
            render: r => moment(r).format(Utils.DateFormat)
          },
          {
            field: 'ref_type',
            name: 'Type',
            truncateText: true,
            textOnly: true,
            sortable: true,
            width: '175px',
            render: r => r.replace(/_/g, ' ')
          },
          {
            field: 'amount',
            name: 'Amount',
            truncateText: false,
            textOnly: true,
            sortable: true,
            width: '175px',
            render: r => <FormatNumber amount={r || 0} isTransaction />
          },
          {
            field: 'balance',
            name: 'Balance',
            truncateText: false,
            textOnly: true,
            sortable: true,
            width: '175px',
            render: r => <FormatNumber amount={r || 0} isTransaction />
          },
          {
            field: 'description',
            name: 'Description',
            truncateText: false,
            textOnly: true,
            sortable: true,
            render: r => <small>{r}</small>
          }
        ]}
        pagination={true}
        sorting={{
          sort: {
            field: 'date',
            direction: 'desc',
          },
        }}
      />
    </EuiPageTemplate.Section>
  )
}

export default AdminCorpWallet
