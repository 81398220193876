import { useState } from 'react'
import { useNavigate } from 'react-router'
import { useRecoilState } from 'recoil'

import Api from '../api'
import Utils from '../Utils'
import userState from '../userState'

import {
  EuiPopover,
  EuiHeaderSectionItemButton,
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSpacer,
  EuiButton
} from '@elastic/eui'

const UserMenu = () => {
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [userLogin, setUserLogin] = useRecoilState(userState)
  const [isOpen, setOpen] = useState(false)

  const doLogout = async () => {
    try {
      await Api.Logout()
      localStorage.clear()
      navigate(0)
    } catch (err) {
      console.error(err)
    }
  }

  const button = (
    <EuiHeaderSectionItemButton
      aria-controls='headerUserMenu'
      aria-expanded={isOpen}
      aria-haspopup='true'
      aria-label='Account menu'
      onClick={() => setOpen(!isOpen)}
    >
      <EuiAvatar name={userLogin.CharacterName} imageUrl={Utils.GetCharacterPortrait(userLogin.CharacterID)} size='s' />
    </EuiHeaderSectionItemButton>
  )

  return (
    <EuiPopover
      id='headerUserMenu'
      ownFocus
      button={button}
      isOpen={isOpen}
      closePopover={() => setOpen(false)}
      panelPaddingSize='none'
    >
      <div style={{ width: 320 }}>
        <EuiFlexGroup
          gutterSize='m'
          className='euiHeaderProfile'
          responsive={false}
        >
          <EuiFlexItem grow={false}>
            <EuiAvatar name={userLogin.CharacterName} imageUrl={Utils.GetCharacterPortrait(userLogin.CharacterID)} size='xl' />
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiText>
              <p>{userLogin.CharacterName}</p>
            </EuiText>

            <EuiSpacer size='m' />

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFlexGroup justifyContent='spaceBetween'>
                  <EuiFlexItem grow={false}>
                    <EuiButton size='s' onClick={doLogout}>Logout</EuiButton>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    </EuiPopover>
  )
}

export default UserMenu
