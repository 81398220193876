import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { EuiLink, EuiLoadingSpinner } from '@elastic/eui'

import api from '../api'
import Utils from '../Utils'

import imgUnknown from '../images/unknown.png'

const MailSender = ({ sender, larger, right }) => {
  const getPortraitSelector = t => {
    if (t === 'character') return Utils.GetCharacterPortrait
    if (t === 'corporation') return Utils.GetCorporationLogo
    if (t === 'alliance') return Utils.GetAllianceLogo
  }

  const [publicInfo, setPublicInfo] = useState({})
  const [senderType, setSenderType] = useState('')

  useEffect(() => {
      if (sender && publicInfo && Object.keys(publicInfo).length === 0) {
        ;(async () => {
          let info
          info = await api.GetPublicInfo(sender.sender_id)
          setSenderType('character')
          if (!info) {
            info = await api.GetCorporationPublicInfo(sender.sender_id)
            setSenderType('corporation')
          }
          if (!info) {
            info = await api.GetAlliancePublicInfo(sender.sender_id)
            setSenderType('alliance')
          }
          if (info) setPublicInfo(info)
          else setSenderType('mailing_list')
        })()
      }
    }, [publicInfo, sender])

  let style = {}
  if (larger) style = { width: '220px' }

  if (senderType === 'mailing_list') {
    return (
      <div
        className={`mailRecipient ${right ? 'isRight' : ''}`}
        style={{ ...style, backgroundImage: `url('${imgUnknown}')` }}
      >
        <strong>Unknown Mailing List</strong>
      </div>
    )
  }

  if (!publicInfo || Object.keys(publicInfo).length === 0) { return <EuiLoadingSpinner /> } else {
    const imageUrl = publicInfo.error
      ? imgUnknown
      : getPortraitSelector(senderType || 'character')(sender.sender_id, 64)

    return (
      <div
        className={`mailRecipient ${right ? 'isRight' : ''}`}
        style={{ ...style, backgroundImage: `url('${imageUrl}')` }}
      >
        <EuiLink
          href={Utils.ZKillboard(senderType, sender.sender_id)}
          target='_blank'
          external={false}
        >
          <strong>{publicInfo.name || 'Unknown'}</strong>
        </EuiLink>
      </div>
    )
  }
}

MailSender.propTypes = {
  sender: PropTypes.object.isRequired,
  larger: PropTypes.bool,
  right: PropTypes.bool
}

export default MailSender
