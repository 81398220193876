import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  EuiSpacer,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiFieldSearch,
  EuiSkeletonText,
  EuiPanel,
  EuiStat,
  EuiButton
} from '@elastic/eui'

import Utils from '../Utils'
import FormatNumber from '../components/FormatNumber'
import api from '../api'

const JournalEntry = ({ journal }) => (
  <EuiFlexGroup alignItems='center' className='hoverable' gutterSize='none'>
    <EuiFlexItem grow={false}>
      <EuiText>{moment(journal.date).format(Utils.DateFormat)}</EuiText>
    </EuiFlexItem>
    <EuiFlexItem style={{ maxWidth: '260px' }}>
      <EuiText style={{ textTransform: 'capitalize' }}>
        {journal.ref_type.replace(/_/g, ' ')}
      </EuiText>
    </EuiFlexItem>
    <EuiFlexItem>
      <EuiText>{journal.description}</EuiText>
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiText textAlign='right'>
        <FormatNumber amount={journal.amount || 0} isTransaction />
      </EuiText>
    </EuiFlexItem>
  </EuiFlexGroup>
)

JournalEntry.propTypes = {
  journal: PropTypes.object.isRequired
}

const Journal = ({ characterId }) => {
  const [filter, setFilter] = useState('')
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [journal, setJournal] = useState({})
  const [balance, setBalance] = useState(null)
  const [errors, setErrors] = useState(null)

  useEffect(
    () => {
      setLoading(true)
      if (journal && Object.keys(journal).length > 0) return 
      (async () => {
        try {
          const res = await api.GetWalletJournal(characterId, page) 
          res.length !== 0 && setJournal(res)
        } catch (err) {
          setErrors(err)
        }
      })()
    },
    [characterId, page, journal]
  )

  useEffect(
    () => {
      setLoading(false)
    },
    [journal]
  )

  useEffect(
    () => {
      if (journal && balance === null) { (async () => setBalance(await api.GetWalletBalance(characterId)))() }
    },
    [characterId, balance, journal]
  )

  if (errors) return <EuiText color='danger'>{errors.toString()}</EuiText>
  if (!journal || !balance) return <EuiSkeletonText lines={3} />
  if (journal.error) {
    return (
      <EuiText color='danger'>
        An error has occurred. Please try again later.
      </EuiText>
    )
  }

  const appliedFilter = filter.toLocaleLowerCase()
  const filtered = Object.values(journal).filter(
    j =>
      j.description.toLocaleLowerCase().includes(appliedFilter) ||
      j.ref_type.replace(/_/g, ' ').toLocaleLowerCase().includes(appliedFilter)
  )

  const paginator = (
    <EuiFlexGroup alignItems='center' justifyContent='spaceBetween'>
      <EuiFlexItem grow={false}>
        <EuiButton
          iconType='arrowLeft'
          iconSide='left'
          isLoading={loading}
          onClick={() => setPage(page - 1)}
          disabled={page <= 1}
        >
          Previous
        </EuiButton>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButton
          iconType='arrowRight'
          iconSide='right'
          isLoading={loading}
          onClick={() => setPage(page + 1)}
          disabled={journal.length < 1000}
        >
          Next
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  return (
    <>
      <EuiSpacer />
      <EuiTitle>
        <h3>Wallet Journal</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat
              title={
                <FormatNumber
                  amount={
                    Object.keys(journal)
                      .map(x => journal[x].amount || 0)
                      .reduce((a, b) => a + b, 0) || 0
                  }
                  isTransaction
                />
              }
              description='Last Movements'
              textAlign='center'
            />
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat
              title={<FormatNumber amount={balance || 0} isTransaction />}
              description='Balance'
              textAlign='center'
            />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat
              title={
                <FormatNumber
                  amount={
                    Object.keys(journal)
                      .map(x => journal[x].ref_type === 'bounty_prizes' ? journal[x].amount || 0 : 0)
                      .reduce((a, b) => a + b, 0) || 0
                  }
                  isTransaction
                />
              }
              description='Bounty Prizes'
              textAlign='center'
            />
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat
              title={
                <FormatNumber
                  amount={
                    Object.keys(journal)
                      .map(x => journal[x].ref_type === 'player_donation' ? journal[x].amount || 0 : 0)
                      .reduce((a, b) => a + b, 0) || 0
                  }
                  isTransaction
                />
              }
              description='Player Donations'
              textAlign='center'
            />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiText>
        Number of transactions: <strong>{Object.keys(journal).length}</strong>
      </EuiText>
      {
        filter.length > 0 && <EuiText>
          Filtered: <strong>{filtered.length}</strong>
        </EuiText>
      }
      <EuiSpacer />
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem>
          <EuiFieldSearch
            fullWidth
            placeholder='Search Journal..'
            value={filter}
            onChange={e => setFilter(e.target.value)}
            aria-label='Filter Journal'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      {paginator}
      <EuiSpacer />
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem>
          {filtered.map(j => (
            <JournalEntry key={j.id} journal={j} />
          ))}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      {paginator}
    </>
  )
}

Journal.propTypes = {
  characterId: PropTypes.number.isRequired
}

export default Journal
