import { useEffect, useState } from 'react'
import orderBy from 'lodash.orderby'

import {
  EuiPageTemplate,
  EuiText,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiFieldSearch,
  EuiSwitch
} from '@elastic/eui'

import CharButton from '../components/CharButton'
import ZKillboardStatistics from '../components/ZKillboardStatistics'
import api from '../api'

const AdminCharacters = () => {
  const [charactersList, setCharactersList] = useState({})
  const [filter, setFilter] = useState('')
  const [showAlts, setShowAlts] = useState(false)
  const [showBlues, setShowBlues] = useState(false)
  const [showOthers, setShowOthers] = useState(false)
  const [showVIP, setShowVIP] = useState(true)
  const [showPizza, setShowPizza] = useState(false)
  const [showZKillboard, setZKillboard] = useState(false)

  useEffect(
    () => {
      if (charactersList && Object.keys(charactersList).length === 0) { (async () => setCharactersList(await api.GetCharactersList()))() }
    },
    [charactersList]
  )

  const filteredList = orderBy(charactersList, ['CharacterName']).filter(
    character => {
      if (
        character.CharacterName.toLocaleLowerCase().includes(
          filter.toLocaleLowerCase()
        )
      ) { return true }
      if (
        character.Alts.filter(alt =>
          alt.CharacterName.toLocaleLowerCase().includes(
            filter.toLocaleLowerCase()
          )
        ).length > 0
      ) { return true }
      return false
    }
  )

  return (
    <EuiPageTemplate.Section grow>
      <EuiText>
        <h1>Characters</h1>
      </EuiText>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFieldSearch
            fullWidth
            placeholder='Search Main or Alts..'
            value={filter}
            onChange={e => setFilter(e.target.value)}
            aria-label='Filter Characters'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiSwitch
            label='Show VIP'
            checked={showVIP}
            onChange={e => setShowVIP(e.target.checked)}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiSwitch
            label='Show Pizza Boys'
            checked={showPizza}
            onChange={e => setShowPizza(e.target.checked)}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiSwitch
            label='Show Blues'
            checked={showBlues}
            onChange={e => setShowBlues(e.target.checked)}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiSwitch
            label='Show Others'
            checked={showOthers}
            onChange={e => setShowOthers(e.target.checked)}
          />
        </EuiFlexItem>
        <EuiFlexItem grow />
        <EuiFlexItem grow={false}>
          <EuiSwitch
            label='Show Alts'
            checked={showAlts}
            onChange={e => setShowAlts(e.target.checked)}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiSwitch
            label='zKillboard stats'
            checked={showZKillboard}
            onChange={e => setZKillboard(e.target.checked)}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      {filteredList.map(char => {
        let shouldDisplay = false

        if (showVIP && char.CharacterCorporation === 98630834) { shouldDisplay = true }
        if (showPizza && char.CharacterCorporation === 98528272) { shouldDisplay = true }
        if (showBlues && char.DiscordRole === '681214429714579457') { shouldDisplay = true }
        if (showOthers) {
          if (
            ![98630834, 98528272].includes(char.CharacterCorporation) ||
            char.DiscordRole !== '681214429714579457'
          ) {
            shouldDisplay = true
          }
        }

        if (!shouldDisplay) return null

        let altsBadgeColor = 'primary'
        if (char.Alts.length === 0) altsBadgeColor = 'danger'
        if (char.Alts.length >= 15) altsBadgeColor = 'accent'
        return (
          <EuiPanel key={char.CharacterID} paddingSize='s'>
            <EuiFlexGroup alignItems={showAlts ? 'flexStart' : 'center'}>
              <EuiFlexItem grow={false}>
                <CharButton
                  characterId={char.CharacterID}
                  characterName={char.CharacterName}
                  isAlt
                />
              </EuiFlexItem>
              {char.fullEsi
                ? (
                  <EuiFlexItem grow={false} style={{ minWidth: '80px' }}>
                    <EuiText color={altsBadgeColor}>
                      <strong>{char.Alts.length}</strong> Alt<small>/s</small>
                    </EuiText>
                  </EuiFlexItem>
                  )
                : (
                  <EuiFlexItem grow={false} style={{ minWidth: '80px' }}>
                    <EuiText color='subdued'>
                      <strong>Blue</strong>
                    </EuiText>
                  </EuiFlexItem>
                  )}
              {
                showZKillboard && <ZKillboardStatistics characterId={char.CharacterID} />
              }
              <EuiFlexItem />
              {
              showAlts &&
                <EuiFlexItem grow={false}>
                  {orderBy(char.Alts, ['CharacterName']).map(alt => {
                    return (
                      <CharButton
                        key={alt.CharacterID}
                        isAlt
                        characterId={alt.CharacterID}
                        characterName={alt.CharacterName}
                      />
                    )
                  })}
                </EuiFlexItem>
              }
            </EuiFlexGroup>
          </EuiPanel>
        )
      })}
    </EuiPageTemplate.Section>
  )
}

export default AdminCharacters
