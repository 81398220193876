import { useState, useEffect } from 'react'
import moment from 'moment'
import { NumericFormat } from 'react-number-format'

import {
  EuiPageTemplate,
  EuiText,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonGroup,
  EuiButtonIcon,
  EuiButton,
  EuiAvatar,
  EuiBadge,
  EuiGlobalToastList,
  EuiModal,
  EuiOverlayMask,
  EuiModalHeader,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeaderTitle,
  EuiSelect,
  EuiFieldText
} from '@elastic/eui'

import FormatNumber from '../components/FormatNumber'

import api from '../api'
import Utils from '../Utils'

const ShipName = ({ typeID }) => {
  const [ship, setShip] = useState({ name: { en: 'Loading...' } })
  useEffect(() => {
    (async () => {
      setShip(await api.SDE.GetType(typeID))
    })()
  }, [typeID])
  return (
    <EuiText>
      <strong>{ship.name.en}</strong>
    </EuiText>
  )
}

const SolarSystem = ({ solarSystemID }) => {
  const [solarSystem, setSolarSystem] = useState({ name: 'Loading...', security_status: 0 })
  useEffect(() => {
    (async () => {
      setSolarSystem(await api.ESI.GetSolarSystem(solarSystemID))
    })()
  }, [solarSystemID])
  return (
    <EuiText>
      {solarSystem.name} <small>{solarSystem.security_status.toFixed(2)}</small>
    </EuiText>
  )
}

const SRPStatus = ({ status }) => {
  switch (`${status}`) {
    case '1':
      return <EuiBadge color='primary'>Accepted</EuiBadge>
    case '2':
      return <EuiBadge color='danger'>Rejected</EuiBadge>
    case '0':
    default:
      return <EuiBadge color='default'>Pending</EuiBadge>
  }
}

const SRPPayout = ({ payoutType, payoutValue }) => {
  switch (payoutType) {
    case 1:
      return (
        <EuiBadge color='primary'>
          <NumericFormat value={parseFloat(payoutValue.toFixed(2), 10)} displayType='text' thousandSeparator suffix=' ISK' />
        </EuiBadge>
      )
    case 2:
      return (
        <EuiBadge color='primary'>
          <NumericFormat value={parseFloat(payoutValue.toFixed(2), 10)} displayType='text' thousandSeparator suffix=' ISK Contract' />
        </EuiBadge>
      )
    case 0:
    default:
      return null
  }
}

const AdminSRP = () => {
  const [status, setStatus] = useState(0)
  const [page, setPage] = useState(0)
  const [editing, setEditing] = useState({})
  const [results, setResults] = useState([])
  const [toasts, setToasts] = useState([])

  const [isModalVisible, setIsModalVisible] = useState(false)
  const closeModal = () => setIsModalVisible(false)
  const showModal = () => setIsModalVisible(true)

  useEffect(() => {
    (async () => {
      const { results } = await api.AdminGetSRP(status, page)
      setResults(results)
    })()
  }, [status, page])

  const addToast = success => setToasts(toasts.concat({
    title: 'SRP Request',
    color: success ? 'success' : 'danger',
    text: success
      ? <p>SRP updated successfully</p>
      : <p>Something went wrong updating this request</p>
  }))

  const removeToast = removedToast =>
    setToasts(toasts.filter((toast) => toast.id !== removedToast.id))

  const statuses = [
    {
      id: '0',
      value: 0,
      label: 'Pending',
      text: 'Pending'
    },
    {
      id: '1',
      value: 1,
      label: 'Accepted',
      text: 'Accepted'
    },
    {
      id: '2',
      value: 2,
      label: 'Rejected',
      text: 'Rejected'
    }
  ]
  const payoutTypes = [
    {
      id: '0',
      value: 0,
      label: 'Pending',
      text: 'Pending'
    },
    {
      id: '1',
      value: 1,
      label: 'ISK',
      text: 'ISK'
    },
    {
      id: '2',
      value: 2,
      label: 'Contract',
      text: 'Contract'
    }
  ]

  const editSRP = _id => {
    setEditing(results.find(e => e._id === _id))
    showModal()
  }

  const saveSRPRequest = async () => {
    const { result } = await api.AdminUpdateSRP(editing._id, {
      status: 1 * editing.status,
      payoutType: 1 * editing.payoutType,
      payoutValue: 1 * editing.payoutValue
    })
    addToast(result)
    if (result) closeModal()
    const { results } = await api.AdminGetSRP(status, page)
    setResults(results)
  }

  let modal

  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>SRP Request</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiFlexGroup alignItems='center'>
              <EuiFlexItem grow={false}>
                <EuiAvatar imageUrl={Utils.GetTypeRender(editing.shipType)} size='xl' name={`${editing.shipType}`} />
              </EuiFlexItem>
              <EuiFlexItem>
                <ShipName typeID={editing.shipType} />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup justifyContent='spaceBetween'>
              <EuiFlexItem grow={false}>
                <strong>{editing.CharacterName}</strong>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <SolarSystem solarSystemID={editing.solarSystem} />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup justifyContent='spaceBetween'>
              <EuiFlexItem grow={false}>
                {moment.utc(editing.time).format(Utils.DateFormat)}
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <FormatNumber amount={editing.totalValue} />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiText>{editing.comment}</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />
            <EuiFlexGroup justifyContent='spaceBetween'>
              <EuiFlexItem grow={false}>SRP Status</EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiSelect
                  options={statuses}
                  value={editing.status}
                  onChange={e => setEditing({ ...editing, status: e.target.value })}
                  aria-label='SRP Status'
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup justifyContent='spaceBetween'>
              <EuiFlexItem grow={false}>Payout Type</EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiSelect
                  options={payoutTypes}
                  value={editing.payoutType}
                  onChange={e => setEditing({ ...editing, payoutType: e.target.value })}
                  aria-label='Payout Type'
                />
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup justifyContent='spaceBetween'>
              <EuiFlexItem grow={false}>Payout Value</EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFieldText
                  value={editing.payoutValue}
                  onChange={e => e.target.value.length > 0 && setEditing({ ...editing, payoutValue: e.target.value })}
                  aria-label='Payout Value'
                /><br />
                <FormatNumber isTransaction amount={1 * editing.payoutValue} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton fill onClick={closeModal}>Close</EuiButton>
            <EuiButton fill color='primary' onClick={saveSRPRequest}>Save</EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <EuiPageTemplate.Section grow>
      <EuiText><h1>Ship Replacement Program</h1></EuiText>
      <EuiSpacer />
      <EuiButtonGroup
        legend='SRP Status'
        options={statuses}
        idSelected={`${status}`}
        onChange={s => setStatus(1 * s)}
        buttonSize='compressed'
        isFullWidth
        color='primary'
      />
      <EuiSpacer />
      <EuiFlexGroup direction='column'>
        {
        results.map(result => <EuiFlexItem key={result._id}>
          <EuiFlexGroup alignItems='center'>
            <EuiFlexItem grow={false}>
              <EuiAvatar imageUrl={Utils.GetTypeRender(result.shipType)} size='l' name={`${result.shipType}`} />
            </EuiFlexItem>
            <EuiFlexItem>
              <ShipName typeID={result.shipType} />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <SolarSystem solarSystemID={result.solarSystem} />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText>
                <strong>{result.CharacterName}</strong>: {result.comment}
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText>
                <FormatNumber amount={result.totalValue} />
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText>{moment.utc(result.time).format(Utils.DateFormat)}</EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false} className='fix200'>
              <SRPStatus status={result.status} /><br />
              <SRPPayout payoutType={result.payoutType} payoutValue={result.payoutValue} />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                onClick={() => editSRP(result._id)}
                iconType='indexEdit'
                aria-label='Edit'
                size='s'
              >
                Edit
              </EuiButton>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonIcon
                onClick={() => window.open(`http://zkillboard.com/kill/${result.killmailId}/`)}
                iconType='popout'
                aria-label='Open in zKillboard'
                size='s'
              />
            </EuiFlexItem>
          </EuiFlexGroup>
                              </EuiFlexItem>)
      }
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem grow={false}>
          {
          page === null
            ? null
            : <EuiButton
                iconSide='left' iconType='arrowLeft' fill
                isDisabled={page === 0} onClick={() => setPage(page - 1)}
              >Previous
              </EuiButton>
        }
        </EuiFlexItem>
        <EuiFlexItem />
        <EuiFlexItem grow={false}>
          {
          page === null
            ? null
            : <EuiButton
                iconSide='right' iconType='arrowRight' fill
                isDisabled={results.length !== 10} onClick={() => setPage(page + 1)}
              >Next
              </EuiButton>
        }
        </EuiFlexItem>
      </EuiFlexGroup>
      {modal}
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={6000}
      />
    </EuiPageTemplate.Section>
  )
}

export default AdminSRP
