import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import orderBy from 'lodash.orderby'

import {
  EuiSpacer,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
  EuiHorizontalRule,
  EuiSkeletonText,
  EuiModal,
  EuiOverlayMask,
  EuiModalHeader,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeaderTitle,
  EuiButton,
  EuiButtonIcon
} from '@elastic/eui'

import Utils from '../Utils'
import FormatNumber from '../components/FormatNumber'

import api from '../api'
import utils from '../Utils'

const ContractEntry = ({ contract, characterId }) => {
  const [esiIssuerInfo, setEsiIssuerInfo] = useState({})
  const [esiAcceptorInfo, setEsiAcceptorInfo] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [content, setContent] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const closeModal = () => setIsModalVisible(false)
  const showModal = () => setIsModalVisible(true)

  useEffect(
    () => {
      if (esiIssuerInfo && Object.keys(esiIssuerInfo).length === 0) {
        (async () =>
          setEsiIssuerInfo(await api.GetPublicInfo(contract.issuer_id)))()
      }
    },
    [contract.issuer_id, esiIssuerInfo]
  )

  useEffect(
    () => {
      if (esiAcceptorInfo && Object.keys(esiAcceptorInfo).length === 0) {
        (async () =>
          setEsiAcceptorInfo(await api.GetPublicInfo(contract.acceptor_id)))()
      }
    },
    [contract.acceptor_id, esiAcceptorInfo]
  )

  const openContract = async () => {
    if (content.length === 0) {
      setLoading(true)
      const items = await api.GetContractItems(characterId, contract.contract_id)
      const result = await Promise.all(items.map(item => api.SDE.GetType(item.type_id)))
      for (let i = 0; i < result.length; i++) {
        result[i].quantity = items[i].quantity
      }
      setContent(result)
      setLoading(false)
    }
    showModal()
  }

  let modal

  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Contract Content</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {
              content.map(c => <EuiFlexGroup>
                <EuiFlexItem grow={false}>
                  <object data={utils.GetTypeIcon(c.typeID)} type='image/png' width={48} alt={c.name.en} className='radius50'>
                    <img src={utils.GetTypeBlueprint(c.typeID)} width={48} alt={c.name.en} className='radius50' />
                  </object>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiText>{c.quantity.toFixed(0)}x</EuiText>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiText>{c.name.en}</EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>)
            }
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton fill onClick={closeModal}>Close</EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <>
      <EuiTitle>
        <h4>
          <strong>{contract.title || '-'}</strong>
        </h4>
      </EuiTitle>
      <EuiFlexGroup alignItems='flexEnd'>
        <EuiFlexItem>
          <EuiText textAlign='left' style={{ textTransform: 'capitalize' }}>
            {contract.type.replace(/_/g, ' ')}
            <br />
            <strong>{contract.status.replace(/_/g, ' ')}</strong>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText textAlign='right'>
            {
              contract.price &&
                <>
                  <br />
                  Price: <FormatNumber amount={contract.price} />{' '}
                </>
            }
            {
              contract.reward &&
                <>
                  <br />
                  Reward: <FormatNumber amount={contract.reward} />
                </>
            }
            {
              contract.collateral &&
                <>
                  <br />
                  Collateral: <FormatNumber amount={contract.collateral} />
                </>
            }
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText textAlign='left' style={{ textTransform: 'capitalize' }}>
            <strong>{contract.availability}</strong>
            <br />
            {
              esiIssuerInfo &&
                <>
                  From:{' '}
                  <EuiLink
                    target='_blank'
                    external={false}
                    rel='noopener noreferrer'
                    href={Utils.ZKillboard('character', contract.issuer_id)}
                  >
                    {esiIssuerInfo.name}
                  </EuiLink>
                </>
            }
            <br />
            {
              esiAcceptorInfo &&
                <>
                  To:{' '}
                  <EuiLink
                    target='_blank'
                    external={false}
                    rel='noopener noreferrer'
                    href={Utils.ZKillboard('character', contract.acceptor_id)}
                  >
                    {esiAcceptorInfo.name}
                  </EuiLink>
                </>
            }
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          Issued: {moment(contract.date_issued).format(Utils.DateFormat)}
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ minWidth: '150px' }}>
          {
            contract.volume &&
              <FormatNumber amount={contract.volume} suffix=' m3' ignoreColor />
          }
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            onClick={openContract}
            iconType='popout'
            aria-label='Open Contract'
            disabled={isLoading}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      {modal}
      <EuiHorizontalRule margin='s' />
    </>
  )
}

ContractEntry.propTypes = {
  characterId: PropTypes.number.isRequired,
  contract: PropTypes.object.isRequired
}

const Contracts = ({ characterId }) => {
  const [contractsList, setContractsList] = useState({})
  const [error, setError] = useState(null)

  useEffect(
    () => {
      if (contractsList && Object.keys(contractsList).length === 0) { 
        (async () => {
          try {
            const res = await api.GetContracts(characterId)
            res.length !== 0 && setContractsList(res)
          } catch (err) {
            setError(err)
          }
        })() 
      }
    },
    [characterId, contractsList]
  )

  if (error) return <EuiText color='danger'>{error.toString()}</EuiText>
  if (!contractsList) return <EuiSkeletonText lines={3} />
  if (contractsList?.error) {
    return (
      <EuiText color='danger'>
        An error has occurred. Please try again later.
      </EuiText>
    )
  }

  return (
    <>
      <EuiSpacer />
      <EuiTitle>
        <h3>Contracts</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem>
        {error && `${error}`}
          {orderBy(contractsList, ['date_issued'], ['desc']).map(contract => (
            <ContractEntry key={contract.contract_id} contract={contract} characterId={characterId} />
          ))}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

Contracts.propTypes = {
  characterId: PropTypes.number.isRequired
}

export default Contracts
