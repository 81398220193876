import { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import api from "../api"
import { EuiText } from "@elastic/eui"

const LocationName = ({ systemID }) => {
  const [systemInfo, setSystemInfo] = useState({})
  useEffect(() => {
    (async () => {
      if (Object.keys(systemInfo).length === 0) setSystemInfo(await api.GetSystem(systemID))
    })()
  }, [systemInfo, systemID])

  return <EuiText size='s'>{systemInfo.name || 'Loading...'} <small>({(systemInfo.security_status || 0).toFixed(2)})</small></EuiText>
}
LocationName.propTypes = {
  systemID: PropTypes.number.isRequired
}

export default LocationName
