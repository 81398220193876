import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Countdown from 'react-countdown'
import orderBy from 'lodash.orderby'
import moment from 'moment'

import {
  EuiText,
  EuiSpacer,
  EuiBasicTable,
  EuiAvatar,
  EuiPageTemplate
} from '@elastic/eui'

import Utils from '../Utils'
import api from '../api'

const getEta = (days, hours, minutes, seconds, completed) => {
  if (completed) return 'Now!'
  const slices = []
  if (days > 0) slices.push(`${days}d`)
  if (hours > 0) slices.push(`${hours}h`)
  if (minutes > 0) slices.push(`${minutes}m`)
  if (seconds > 0) slices.push(`${seconds}s`)
  return slices.join(' ')
}

const getCountdown = dt => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return <span>{getEta(days, hours, minutes, seconds, completed)}</span>
  }
  renderer.propTypes = {
    days: PropTypes.number,
    hours: PropTypes.number,
    minutes: PropTypes.number,
    seconds: PropTypes.number,
    completed: PropTypes.bool
  }
  return <Countdown date={moment.utc(dt).toDate()} renderer={renderer} />
}

const AdminLedger = () => {
  const [extractionsList, setExtractionsList] = useState([])

  useEffect(() => {
    if (extractionsList.length === 0) (async () => setExtractionsList(await api.GetExtractions()))()
  }, [extractionsList])

  const columns = [
    {
      render: function renderType (extraction) {
        return <EuiAvatar imageUrl={Utils.GetTypeRender(extraction.structure.type_id)} size='l' name={extraction.structure.name} />
      },
      width: '45px'
    },
    {
      name: 'Structure',
      render: function renderStructure (extraction) {
        return (
          <EuiText>
            <strong>{extraction.structure.name}</strong><br />
            {extraction.system.name} <small>({extraction.system.security_status.toFixed(2)})</small> / {extraction.region.name}
          </EuiText>
        )
      }
    },
    {
      name: 'Extraction Start',
      field: 'extraction.extraction_start_time',
      render: function (extractionStart) {
        return <EuiText>&nbsp;<br /><small>{moment.utc(extractionStart).format(Utils.DateFormat)}</small></EuiText>
      },
      width: '150px'
    },
    {
      name: 'Chunk Arrival',
      field: 'extraction.chunk_arrival_time',
      render: function (chunkArrival) {
        return <EuiText>{getCountdown(chunkArrival)}<br /><small>{moment.utc(chunkArrival).format(Utils.DateFormat)}</small></EuiText>
      },
      width: '150px'
    },
    {
      name: 'Auto Fracture',
      field: 'extraction.natural_decay_time',
      render: function (naturalDecaly) {
        return <EuiText>{getCountdown(naturalDecaly)}<br /><small>{moment.utc(naturalDecaly).format(Utils.DateFormat)}</small></EuiText>
      },
      width: '150px'
    }
  ]

  return (
    <EuiPageTemplate.Section grow>
      <EuiText><h1>Extractions</h1></EuiText>
      <EuiSpacer />
      <EuiBasicTable
        items={orderBy(extractionsList, ['extraction.chunk_arrival_time', 'extraction.natural_decay_time'])}
        columns={columns}
      />
    </EuiPageTemplate.Section>
  )
}

export default AdminLedger
