import { useEffect, useState } from 'react'

import orderBy from 'lodash.orderby'

import {
  EuiButtonIcon,
  EuiFieldNumber,
  EuiSkeletonText,
  EuiInMemoryTable,
  EuiPageTemplate,
  EuiSpacer,
  EuiText,
  EuiToast,
  EuiGlobalToastList,
  EuiButton
} from '@elastic/eui'

import api from '../api'
import Utils from '../Utils'

const AdminRefineryValues = () => {
  const _DEBUG = true
  const [items, setItems] = useState([])
  const [editingTax, setEditingTax] = useState(false) // Drives the number input field in each table row
  const [newTaxValue, setNewTaxValue] = useState(0)
  const [firstLoadingData, setFirstLoadingData] = useState(true) // Drives the EuiSkeletonText component for the whole table
  const [pendingChanges, setPendingChanges] = useState(false) // Drives the save/discard buttons visibility
  const [fetchData, setFetchData] = useState(true)

  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const [toasts, setToasts] = useState([])

  useEffect(
    () => {
      if (fetchData) {
        setFirstLoadingData(true)
        ;(async () => {
          try {
            // Loading materials
            const refineryValues = await api.GetRefinery()
            if (refineryValues) {
              // Loading all materials names and icons
              Promise.all(
                refineryValues.map(async val => {
                  try {
                    const item = await api.SDE.GetType(val.type_id)
                    return item
                      ? {
                          materialNameEN: item.name.en,
                          icon: (
                            <img
                              src={Utils.GetTypeIcon(item.typeID, 64)}
                              width={32}
                              alt={(item.name || {}).en || 'Loading...'}
                            />
                          ),
                          rarity: Utils.FindMoonRarityValue(item.name.en),
                          ...val,
                          update: 0
                        }
                      : {}
                  } catch {
                    console.log('Error fetching names !')
                  }
                })
              ).then(vals => {
                setItems(vals)
                setFirstLoadingData(false)
                setFetchData(false)
              })
            }
          } catch {
            console.log('ERROR: Error fetching values!')
            setError('Error fetching values. Please try again later')
          }
        })()
      }
    },
    [fetchData]
  )

  const submitUpdates = async () => {
    const itemsToChange = items.filter(itm => itm.update)
    const normalizedItemsToChange = itemsToChange.map(itm => ({
      materials: itm.materials,
      quantities: itm.quantities,
      size: itm.size,
      type_id: itm.type_id,
      tax: itm.tax
    }))
    _DEBUG &&
      console.log(
        'DEBUG: Submitting items for change: ',
        normalizedItemsToChange
      )
    try {
      const { result } = await api.RefinerySetTax(normalizedItemsToChange)
      if (result) {
        setLoading(true)
        setTimeout(() => {
          setPendingChanges(false)
          setLoading(false)
          setError(undefined)
          setToasts(
            toasts.concat({
              title: 'Changes submitted succesfully!',
              color: 'success',
              iconType: 'check',
              toastLifeTimeMs: 4000
            })
          )
        }, 1000)
      }
    } catch (e) {
      console.log('ERROR: patch failed')
      setError('Something went wrong! Please contact the devs')
    }
  }
  const editTax = (typeID, newValue) => {
    _DEBUG && console.log('DEBUG: Updating tax value for item: ', typeID)
    setItems(old => {
      const tmp = old.map(itm => {
        if (itm.type_id === typeID) {
          _DEBUG && console.log('DEBUG: Item found, updating...')
          return { ...itm, tax: newValue * 1, update: 1 }
        } else return itm
      })
      return tmp
    })
    setNewTaxValue(0)
  }

  const actions = [
    {
      name: 'Edit',
      isPrimary: true,
      description: 'Edit tax value',
      icon: 'pencil',
      type: 'icon',
      onClick: item => setEditingTax(item.type_id)
    }
  ]

  const columns = [
    {
      field: 'icon',
      render: function renderIcon (icon) {
        return icon
      },
      width: '45px'
    },
    {
      name: 'Material',
      field: 'materialNameEN',
      sortable: true
    },
    {
      name: 'Rarity',
      field: 'rarity',
      sortable: true,
      render: function renderRarity (rar) {
        return <EuiText>R{rar}</EuiText>
      }
    },
    {
      name: 'Tax',
      width: '10%',
      align: 'center',
      render: function renderMaterial (rowItem) {
        return editingTax === rowItem.type_id
          ? (
            <EuiFieldNumber
              compressed
              min={0}
              value={newTaxValue || rowItem.tax}
              onChange={e => setNewTaxValue(e.target.value)}
              prepend={[
                <EuiButtonIcon
                  iconType='cross'
                  color='danger'
                  onClick={() => setEditingTax(0)}
                  aria-label='cancel'
                />
              ]}
              append={[
                <EuiButtonIcon
                  iconType='check'
                  color='success'
                  onClick={() => {
                    setEditingTax(0)
                    setPendingChanges(true)
                    editTax(rowItem.type_id, newTaxValue)
                  }}
                  aria-label='confirm'
                />
              ]}
            />
            )
          : (
            <EuiText>{rowItem.tax} %</EuiText>
            )
      }
    },
    {
      name: 'Actions',
      actions
    }
  ]

  const renderToolsRight = () => {
    return (
      pendingChanges && [
        <EuiButton
          key='savePendingChanges'
          isDisabled={!pendingChanges}
          onClick={submitUpdates}
        >
          Save Changes
        </EuiButton>,
        <EuiButton
          key='discardPendingChanges'
          color='danger'
          isDisabled={!pendingChanges}
          onClick={() => {
            setPendingChanges(false)
            setFetchData(true)
          }}
        >
          Discard Changes
        </EuiButton>
      ]
    )
  }

  const search = {
    toolsRight: renderToolsRight(),
    box: { incremental: true, placeholder: 'Search material' }
  }

  const sorting = {
    sort: {
      field: 'rarity',
      direction: 'desc'
    }
  }
  return (
    <EuiPageTemplate.Section grow>
      <EuiText>
        <h1>Mining Taxes</h1>
      </EuiText>
      <EuiSpacer />
      {!firstLoadingData
        ? (
          <EuiInMemoryTable
            items={items}
            columns={columns}
            hasActions
            search={search}
            error={error}
            loading={loading}
            sorting={sorting}
          />
          )
        : (
          <EuiSkeletonText lines={3} />
          )}
      <EuiGlobalToastList
        toasts={toasts}
        toastsLifeTimeMs={4000}
        dismissToast={() => setToasts([])}
      />
    </EuiPageTemplate.Section>
  )
}

export default AdminRefineryValues
