import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import orderBy from 'lodash.orderby'

import {
  EuiSpacer,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiLink,
  EuiSkeletonText
} from '@elastic/eui'

import Utils from '../Utils'

import imgBlocked from '../images/blocked.png'
import imgWatched from '../images/watched.png'

import standingExcellent from '../images/standingExcellent.png'
import standingGood from '../images/standingGood.png'
import standingNeutral from '../images/standingNeutral.png'
import standingBad from '../images/standingBad.png'
import standingTerrible from '../images/standingTerrible.png'
import api from '../api'

const ContactEntry = ({ contact }) => {
  const getPortraitSelector = t => {
    if (t === 'character') return Utils.GetCharacterPortrait
    if (t === 'corporation') return Utils.GetCorporationLogo
    if (t === 'alliance') return Utils.GetAllianceLogo
    if (t === 'faction') return Utils.GetCorporationLogo
  }
  const getStandingIcon = standing => {
    if (standing === 10) return standingExcellent
    if (standing === 5) return standingGood
    if (standing === 0) return standingNeutral
    if (standing === -5) return standingBad
    if (standing === -10) return standingTerrible
  }

  const [publicInfo, setPublicInfo] = useState({})

  useEffect(() => {
    if (Object.keys(publicInfo).length > 0) return
    let method
    if (contact.contact_type === 'character') method = api.GetPublicInfo
    if (contact.contact_type === 'corporation') method = api.GetCorporationPublicInfo
    if (contact.contact_type === 'alliance') method = api.GetAlliancePublicInfo
    if (typeof method === 'function') method(contact.contact_id).then(setPublicInfo)
  }, [publicInfo, contact])

  if (!publicInfo || contact.contact_type === 'faction') return <EuiSkeletonText lines={1} />

  return (
    <EuiFlexGroup alignItems='center' className='hoverable' gutterSize='none'>
      <EuiFlexItem style={{ maxWidth: '100px' }}>
        <EuiText style={{ textTransform: 'capitalize' }}>
          {contact.contact_type}
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiLink
          href={Utils.ZKillboard(contact.contact_type, contact.contact_id)}
          target='_blank'
          external={false}
        >
          <img
            src={getPortraitSelector(contact.contact_type)(
              contact.contact_id,
              32
            )}
            width='30'
            alt={publicInfo.name}
            className='radius50'
          />
        </EuiLink>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiLink
          href={Utils.ZKillboard(contact.contact_type, contact.contact_id)}
          target='_blank'
          external={false}
        >
          {publicInfo.name}
        </EuiLink>
      </EuiFlexItem>
      {
      contact.is_blocked &&
        <>
          <EuiFlexItem grow={false}>
            <EuiText>Blocked</EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <img src={imgBlocked} width='16' alt='Blocked' />
          </EuiFlexItem>
        </>
      }
      {
        contact.is_watched &&
          <>
            <EuiFlexItem grow={false}>
              <EuiText>Watched</EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <img src={imgWatched} width='16' alt='Watched' />
            </EuiFlexItem>
          </>
      }
      <EuiFlexItem style={{ maxWidth: '50px' }}>
        <EuiText textAlign='right'>
          {contact.standing && contact.standing > 0 && '+'}
          {contact.standing}
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {contact.standing ?? (
          <img
            src={getStandingIcon(contact.standing)}
            width='16'
            alt={`Standing ${contact.standing}`}
          />
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

ContactEntry.propTypes = {
  contact: PropTypes.object.isRequired
}

const Contacts = ({ characterId }) => {
  const [contacts, setContacts] = useState(null)
  const [errors, setErrors] = useState(null)

  useEffect(() => {
    if (contacts && Object.keys(contacts).length > 0) return
    (async () => {
      try {
        const res = await api.GetContacts(characterId)
        res.length !== 0 && setContacts(res)
      } catch (err) {
        setErrors(err)
      }
    })()
    
  }, [contacts, characterId])

  
  if (errors) return <EuiText color='danger'>{`${errors}`}</EuiText>
  if (!contacts) return <EuiSkeletonText lines={3} />
  if (contacts.error) return <EuiText color='danger'>An error has occurred. Please try again later.</EuiText>

  return (
    <>
      <EuiSpacer />
      <EuiTitle>
        <h3>Contacts</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem>
          {orderBy(contacts, ['standing'], ['desc']).map(contact => (
            <ContactEntry key={contact.contact_id} contact={contact} />
          ))}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

Contacts.propTypes = {
  characterId: PropTypes.number.isRequired
}

export default Contacts
