import { EuiBadge, EuiBasicTable, EuiButton, EuiButtonGroup, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiPageTemplate, EuiSpacer, EuiSuggest, EuiText, EuiTitle } from "@elastic/eui"
import { useEffect, useState } from "react"
import sortBy from 'lodash.sortby'

import api from "../api"
import Utils from '../Utils'

const levelEmoji = [
  '', '1️⃣', '2️⃣', '3️⃣', '4️⃣', '5️⃣'
]

const columns = (allSkills) => [
  {
    field: 'CharacterName',
    name: 'Character Name',
    render: c => <EuiText>{c}</EuiText>
  },
  {
    field: 'TotalSP',
    name: 'Total / Unallocated SP',
    render: (_, x) => <EuiText textAlign="right">
      {Utils.ThousandSeparator(x.TotalSP)}<br />
      {Utils.ThousandSeparator(x.UnallocatedSP)}
      </EuiText>
  },
  {
    field: 'Skills',
    render: (x) => <EuiFlexGroup gutterSize="xs" direction="column">
      {x.map(y => {
        return <EuiFlexItem>
          <EuiFlexGroup alignItems='center' className='hoverable' gutterSize='none'>
          <EuiFlexItem>
            <EuiText textAlign="right">
              <h6>{allSkills.find(s => s.typeID === y.skill).name.en}</h6>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <div className='skillLevelContainer'>
              {[1, 2, 3, 4, 5].map(l => {
                return (
                  <div
                    key={l}
                    className={`skillLevel ${
                      l <= y.level ? 'done' : 'next'
                    }`}
                  />
                )
              })}
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      })}
    </EuiFlexGroup>
  }
]


const SkillFinder = () => {
  const [allSkills, setAllSkills] = useState([])
  const [filter, setFilter] = useState('')
  const [filterSelected, setFilterSelected] = useState()
  const [skillLevel, setSkillLevel] = useState(5)
  const [selectedSkills, setSelectedSkills] = useState([])

  const [vipCharacters, setVipCharacters] = useState([])
  const [pizzaCharacters, setPizzaCharacters] = useState([])
  const [corpSkills, setCorpSkills] = useState([])

  useEffect(() => {
    api.SDE.GetSkills().then(setAllSkills)
    api.GetCorpCharacters(98630834).then(setVipCharacters)
    api.GetCorpCharacters(98528272).then(setPizzaCharacters)
    api.GetCorpSkills().then(setCorpSkills)
  }, [])

  const vipResults = sortBy(vipCharacters.map(c => ({ ...c, lowerCharacterName: c.CharacterName.toLocaleLowerCase() })), 'CharacterName').map(
    char => {
      const charSkills = corpSkills.find(c => c.CharacterID === char.CharacterID)
      if (!charSkills) return null
      const skills = []
      for (const sk of selectedSkills) {
        const skill = charSkills.Skills.skills.find(s => s.skill_id === sk.typeID)
        if (!skill) return null
        if (skill.trained_skill_level < sk.level) return null
        skills.push({
          skill: sk.typeID,
          level: skill.trained_skill_level,
        })
      }
      return {
        CharacterName: char.CharacterName,
        TotalSP: charSkills.Skills.total_sp,
        UnallocatedSP: charSkills.Skills.unallocated_sp,
        Skills: skills
      }
    }).filter(x => !!x && x.Skills.length > 0)

  const pizzaResults = sortBy(pizzaCharacters.map(c => ({ ...c, lowerCharacterName: c.CharacterName.toLocaleLowerCase() })), 'CharacterName').map(
    char => {
      const charSkills = corpSkills.find(c => c.CharacterID === char.CharacterID)
      if (!charSkills) return null
      const skills = []
      for (const sk of selectedSkills) {
        const skill = charSkills.Skills.skills.find(s => s.skill_id === sk.typeID)
        if (!skill) return null
        if (skill.trained_skill_level < sk.level) return null
        skills.push({
          skill: sk.typeID,
          level: skill.trained_skill_level,
        })
      }
      return {
        CharacterName: char.CharacterName,
        TotalSP: charSkills.Skills.total_sp,
        UnallocatedSP: charSkills.Skills.unallocated_sp,
        Skills: skills
      }
    }).filter(x => !!x && x.Skills.length > 0)

  return <EuiPageTemplate.Section grow>
    <EuiTitle>
      <h3>Skill Finder</h3>
    </EuiTitle>
    <EuiSpacer />
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiFormRow fullWidth label="Add Skill Filter">
          <EuiSuggest fullWidth isVirtualized
            value={filterSelected
                ? allSkills.find(f => f.typeID === filterSelected).name.en
                : filter}
            onChange={(searchValue, matchingOptions) => {
              setFilterSelected()
              setFilter(searchValue)
            }}
            onItemClick={(selected) => setFilterSelected(
              allSkills.find(f => f.name.en === selected.label).typeID
            )}
            suggestions={sortBy(allSkills
                .filter(f => f.published && !selectedSkills.map(s => s.typeID).includes(f.typeID)),
                ['groupID', 'name.en']
              )
              .map(s => ({
                type: { iconType: 'indexTemporary', color: 'tint10' },
                label: s.name.en,
                description: s.description.en,
              }))}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFormRow label="Level">
          <EuiButtonGroup
            options={[1, 2, 3, 4, 5].map(l => ({ id: `${l}`, label: `${l}` }))}
            idSelected={skillLevel}
            buttonSize='compressed'
            onChange={lvl => setSkillLevel(lvl)}
          />
        </EuiFormRow>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiFormRow label='&nbsp;'>
          <EuiButton iconType='plus' disabled={!filterSelected}
          onClick={() => {
            setSelectedSkills(s => [...s, { typeID: filterSelected, level: 1 * skillLevel }])
            setFilterSelected()
          }}>Add</EuiButton>
        </EuiFormRow>
      </EuiFlexItem>
    </EuiFlexGroup>
    <EuiSpacer />
    {
      selectedSkills.map(sel => <EuiBadge key={sel.typeID}
        color="hollow"
        iconType="cross"
        iconSide="right"
        iconOnClick={() => {
          setSelectedSkills(s => s.filter(f => f.typeID !== sel.typeID))
        }}
        iconOnClickAriaLabel="Remove">
          {allSkills.find(f => f.typeID === sel.typeID).name.en} {levelEmoji[sel.level]}
      </EuiBadge>)
    }
    <EuiSpacer />
    <EuiText>
      Total VIP: {vipResults.length}<br />
      Total PZBS: {pizzaResults.length}
    </EuiText>
    <EuiSpacer />
    <EuiText>Very Italian People</EuiText>
    <EuiBasicTable
      items={vipResults}
      rowHeader="CharacterName"
      columns={columns(allSkills)}
    />
    <EuiSpacer />
    <EuiText>Pizza Boys</EuiText>
    <EuiBasicTable
      items={pizzaResults}
      rowHeader="CharacterName"
      columns={columns(allSkills)}
    />
  </EuiPageTemplate.Section>
}

export default SkillFinder
