import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  EuiSpacer,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSkeletonText,
  EuiTextAlign
} from '@elastic/eui'

import CharButton from '../components/CharButton'
import api from '../api'

const RelatedCharacters = ({ characterId }) => {
  const [characterList, setCharacterList] = useState({})

  useEffect(
    () => {
      if (characterList && Object.keys(characterList).length === 0) { (async () => setCharacterList(await api.GetCharactersList()))() }
    },
    [characterList]
  )

  const main = Object.values(characterList).find(x => {
    if (x.CharacterID === characterId) return true
    if (x.Alts.find(a => a.CharacterID === characterId)) return true
    return false
  })

  if (!main) return <EuiSkeletonText lines={4} />

  return (
    <>
      <EuiSpacer />
      <EuiTitle>
        <h3>Related Characters</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem>
          <CharButton
            characterId={main.CharacterID}
            characterName={main.CharacterName}
            isAlt={false}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiTitle>
            <EuiTextAlign textAlign='right'>
              <h4>Alts</h4>
            </EuiTextAlign>
          </EuiTitle>
          <EuiSpacer />
          {main.Alts.map(alt => (
            <CharButton
              key={alt.CharacterID}
              characterId={alt.CharacterID}
              characterName={alt.CharacterName}
              isAlt
            />
          ))}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

RelatedCharacters.propTypes = {
  characterId: PropTypes.number.isRequired
}

export default RelatedCharacters
