import { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import utils from "../Utils"
import api from "../api"

const ItemIcon = ({ typeID }) => {
  const [typeInfo, setTypeInfo] = useState({})
  useEffect(() => {
    (async () => {
      if (Object.keys(typeInfo).length === 0) setTypeInfo(await api.SDE.GetType(typeID))
    })()
  }, [typeInfo, typeID])

  return <img src={utils.GetTypeIcon(typeID, 64)} width={32} className='radius50' alt={(typeInfo.name || {}).en || 'Loading...'} />
}
ItemIcon.propTypes = {
  typeID: PropTypes.number.isRequired
}

export default ItemIcon
