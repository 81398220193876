import { useEffect } from 'react'
import { useRecoilState } from 'recoil'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiTextAlign,
  EuiAvatar,
  EuiPageTemplate
} from '@elastic/eui'
import { Route, Routes } from 'react-router-dom'

import Login from './pages/Login'
import Landing from './pages/Landing'

import MainRouter from './components/MainRouter'

import vipLogo from './images/vip.png'
import userState from './userState'
import api from './api'

const AppMain = () => {
  const [userLogin, setUserLogin] = useRecoilState(userState)

  useEffect(() => {
    if (userLogin !== undefined) return
    
    api.GetUserLogin()
      .then(setUserLogin)
      .catch(() => setUserLogin(null))
  }, [userLogin, setUserLogin])

  if (userLogin === undefined) {
    return <EuiPageTemplate className='ssoBackgroundFullVH'>
      <EuiPageTemplate.EmptyPrompt title={<EuiFlexGroup alignItems='center' justifyContent='center'>
        <EuiFlexItem grow={false}>
          <EuiAvatar
            imageUrl={vipLogo}
            size='xl'
            name='Very Italian People'
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <h2>Very Italian People</h2>
        </EuiFlexItem>
      </EuiFlexGroup>}>
        <EuiTextAlign textAlign='center'><EuiLoadingSpinner size='xl' /></EuiTextAlign>
      </EuiPageTemplate.EmptyPrompt>
    </EuiPageTemplate>
  }

  if (userLogin === null) return <Routes>
    <Route exact path='/landing' element={<Landing />} />
    <Route path='*' element={<Login />} />
  </Routes>
  if (userLogin.accessLevel >= 0) return <MainRouter />
}

export default AppMain
