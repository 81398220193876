import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  EuiFlexGrid,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiText,
  EuiPanel,
  EuiLink,
  EuiButton,
  EuiBadge,
  EuiLoadingSpinner,
  EuiCallOut
} from '@elastic/eui'

import Utils from '../Utils'
import api from '../api'

const CharacterSheet = ({ characterId, small }) => {
  const [esiStatus, setESIStatus] = useState(null)
  const [corporationId, setCorporation] = useState(null)
  const [allianceId, setAlliance] = useState(null)
  const [publicInfo, setPublicInfo] = useState(null)
  const [corpPublicInfo, setCorpPublicInfo] = useState(null)
  const [allyPublicInfo, setAllyPublicInfo] = useState(null)
  const [zkbStats, setZkbStats] = useState(null)

  useEffect(() => {
    api.GetESIStatus(characterId).then(esiResult => setESIStatus((esiResult && esiResult.esi) || false))

    if (!small) api.GetZkbChar(characterId).then(setZkbStats)
  }, [small, characterId])

  useEffect(() => {
    if (publicInfo !== null && publicInfo.character_id === characterId) return
    api.GetPublicInfo(characterId).then(x => {
      setPublicInfo({ ...x, character_id: characterId })
      setCorporation(x.corporation_id)
      setAlliance(x.alliance_id)
    })
  }, [characterId, publicInfo])

  useEffect(() => {
    if (corporationId === null || (corpPublicInfo !== null && corpPublicInfo.corporation_id === corporationId)) return
    api.GetCorporationPublicInfo(corporationId).then(x => setCorpPublicInfo({ ...x, corporation_id: corporationId }))
  }, [corporationId, corpPublicInfo])

  useEffect(() => {
    if (allianceId === null || !allianceId || (allyPublicInfo !== null && allyPublicInfo.alliance_id === allianceId)) return
    api.GetAlliancePublicInfo(allianceId).then(x => setAllyPublicInfo({ ...x, alliance_id: allianceId }))
  }, [allianceId, allyPublicInfo])

  if (!publicInfo) return <EuiLoadingSpinner />

  const corporationPublicInfo = corpPublicInfo || {}
  const alliancePublicInfo = allyPublicInfo || {}

  if (small) {
    return (
      <EuiPanel hasShadow>
        <EuiFlexGroup alignItems='center'>
          <EuiFlexItem grow={false}>
            <img src={Utils.GetCharacterPortrait(characterId, 64)} width={64} alt={publicInfo.name} className='radius50' />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText className={esiStatus === false ? 'blink' : null}><h2><strong>{publicInfo.name}</strong></h2></EuiText>
            {
              esiStatus === false && <EuiCallOut title='ESI Expired' color='danger' iconType='alert'><p>Please log this character again!</p></EuiCallOut>
            }
          </EuiFlexItem>
          {
          publicInfo.corporation_id &&
            <>
              <EuiFlexItem grow={false}>
                <EuiLink href={Utils.ZKillboard('corporation', publicInfo.corporation_id)} target='_blank' external={false}>
                  <img src={Utils.GetCorporationLogo(publicInfo.corporation_id, 64)} width={64} alt={corporationPublicInfo.name} className='radius50' />
                </EuiLink>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiLink href={Utils.ZKillboard('corporation', publicInfo.corporation_id)} target='_blank' external={false}>
                  [{corporationPublicInfo.ticker}] {corporationPublicInfo.name}
                </EuiLink>
              </EuiFlexItem>
            </>
        }
          {
          publicInfo.alliance_id &&
            <>
              <EuiFlexItem grow={false}>
                <EuiLink href={Utils.ZKillboard('alliance', publicInfo.alliance_id)} target='_blank' external={false}>
                  <img src={Utils.GetAllianceLogo(publicInfo.alliance_id, 64)} width={64} alt={alliancePublicInfo.name} className='radius50' />
                </EuiLink>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiLink href={Utils.ZKillboard('alliance', publicInfo.alliance_id)} target='_blank' external={false}>
                  [{alliancePublicInfo.ticker}] {alliancePublicInfo.name}
                </EuiLink>
              </EuiFlexItem>
            </>
        }
        </EuiFlexGroup>
      </EuiPanel>
    )
  }

  let zkb = <EuiLoadingSpinner />
  let zkbColor = 'danger'

  if (zkbStats) {
    let zkbMonth = 0
    if (zkbStats.months) {
      const data = zkbStats.months[moment().format('YYYYMM')]
      if (data) {
        zkbMonth += data.shipsDestroyed || 0
      }
    }
    zkb = `${zkbMonth}/100`
    if (zkbMonth > 100) zkbColor = 'primary'
  }

  let secStatusColor = 'warning'
  if (publicInfo.security_status >= 2.5) secStatusColor = 'primary'
  if (publicInfo.security_status <= 0) secStatusColor = 'danger'
  return (
    <EuiPanel hasShadow>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <img src={Utils.GetCharacterPortrait(characterId, 256)} width={256} alt={publicInfo.name} className='radius50' />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText><h2><strong>{publicInfo.name}</strong></h2></EuiText>
          {
          publicInfo.corporation_id &&
            <>
              <EuiSpacer />
              <EuiFlexGroup alignItems='center'>
                <EuiFlexItem grow={false}>
                  <EuiLink href={Utils.ZKillboard('corporation', publicInfo.corporation_id)} target='_blank' external={false}>
                    <img src={Utils.GetCorporationLogo(publicInfo.corporation_id, 64)} width={64} alt={corporationPublicInfo.name} className='radius50' />
                  </EuiLink>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiLink href={Utils.ZKillboard('corporation', publicInfo.corporation_id)} target='_blank' external={false}>
                    {corporationPublicInfo.name} [{corporationPublicInfo.ticker}]
                  </EuiLink>
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
        }
        {
          publicInfo.alliance_id &&
            <>
              <EuiSpacer />
              <EuiFlexGroup alignItems='center'>
                <EuiFlexItem grow={false}>
                  <EuiLink href={Utils.ZKillboard('alliance', publicInfo.alliance_id)} target='_blank' external={false}>
                    <img src={Utils.GetAllianceLogo(publicInfo.alliance_id, 64)} width={64} alt={alliancePublicInfo.name} className='radius50' />
                  </EuiLink>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiLink href={Utils.ZKillboard('alliance', publicInfo.alliance_id)} target='_blank' external={false}>
                    {alliancePublicInfo.name} [{alliancePublicInfo.ticker}]
                  </EuiLink>
                </EuiFlexItem>
              </EuiFlexGroup>
            </>
        }
          <EuiSpacer />
          <EuiFlexGrid columns={2}>
            <EuiFlexItem grow={false}><strong>Sec. Status:</strong></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiBadge color={secStatusColor}>{(publicInfo.security_status || 0).toFixed(2)}</EuiBadge>
            </EuiFlexItem>
          </EuiFlexGrid>
          <EuiSpacer />
          <EuiFlexGrid columns={2}>
            <EuiFlexItem grow={false}><strong>Monthly kills goal:</strong></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiBadge color={zkbColor}>{zkb}</EuiBadge>
            </EuiFlexItem>
          </EuiFlexGrid>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiButton onClick={() => window.open(Utils.ZKillboard('character', characterId))}>zKillboard</EuiButton>
          &nbsp;
          <EuiButton onClick={() => window.open(Utils.EVEWho('character', characterId))}>EVE Who</EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  )
}

CharacterSheet.propTypes = {
  characterId: PropTypes.number.isRequired,
  small: PropTypes.bool
}

export default CharacterSheet
