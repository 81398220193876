import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'

import {
  EuiPageTemplate,
  EuiText,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiFieldSearch,
  EuiButton,
  EuiSelect,
  EuiSwitch,
  EuiFieldNumber
} from '@elastic/eui'

import Api from '../api'
import Utils from '../Utils'

const getPortrait = (type, id) => {
  if (type === 'corporation') return Utils.GetCorporationLogo(id, 64)
  if (type === 'alliance') return Utils.GetAllianceLogo(id, 64)
}

const SearchContainer = ({ onSelect, alreadyAdded }) => {
  const [query, setQuery] = useState('')
  const [results, setResults] = useState([])
  const delayedQuery = useRef(debounce(async q => {
    if (q.length <= 2) return setResults([])
    const r = await Api.AdminSearch('corporation,alliance', q)
    if (r.error) return
    const corps = r.corporation
      ? await Promise.all(r.corporation.slice(0, 6).map(async x => {
          const publicInfo = await Api.GetCorporationPublicInfo(x)
          return {
            id: x,
            name: publicInfo.name,
            ticker: publicInfo.ticker,
            type: 'corporation'
          }
        }))
      : []
    const allys = r.alliance
      ? await Promise.all(r.alliance.slice(0, 6).map(async x => {
          const publicInfo = await Api.GetAlliancePublicInfo(x)
          return {
            id: x,
            name: publicInfo.name,
            ticker: publicInfo.ticker,
            type: 'alliance'
          }
        }))
      : []
    setResults([...allys, ...corps])
  }, 500)).current

  const onChange = e => {
    setQuery(e.target.value)
    delayedQuery(e.target.value)
  }

  return (
    <EuiPanel hasShadow>
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFieldSearch
            fullWidth
            placeholder='Search alliance or corporation...'
            value={query}
            onChange={onChange}
            aria-label='Search alliance or corporation'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup direction='column'>
        {
        results.map(result => <EuiFlexItem key={result.id}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <img src={getPortrait(result.type, result.id)} alt={`[${result.ticker}] ${result.name}`} width={48} className='radius50' />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText>
                <strong>{`[${result.ticker}] ${result.name}`}</strong><br />
                <small style={{ textTransform: 'capitalize' }}>{result.type}</small>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                fill iconType='plusInCircleFilled' color='primary' size='s' iconSide='right'
                onClick={() => onSelect(result)} disabled={alreadyAdded[result.type].find(a => a.id === result.id) !== undefined}
              >
                Add
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
                              </EuiFlexItem>)
      }
      </EuiFlexGroup>
    </EuiPanel>
  )
}

SearchContainer.propTypes = {
  onSelect: PropTypes.func.isRequired,
  alreadyAdded: PropTypes.object.isRequired
}

const AdminPermissions = () => {
  const [changed, setChanged] = useState(false)
  const [discordRoles, setDiscordRoles] = useState([])
  const [alreadyAdded, setAlreadyAdded] = useState({ corporation: [], alliance: [] })

  useEffect(() => {
    (async () => {
      setDiscordRoles(await Api.AdminDiscordGetRoles())
      setAlreadyAdded(await Api.AdminDiscordGetPermissions())
    })()
  }, [])

  const add = result => {
    const newAdded = { ...alreadyAdded }
    if (newAdded[result.type].includes(result.id)) return
    newAdded[result.type].push(result)
    setAlreadyAdded(newAdded)
    setChanged(true)
  }

  const remove = result => {
    if (alreadyAdded[result.type].find(x => x.id === result.id) === undefined) return
    const newAdded = { ...alreadyAdded }
    newAdded[result.type] = newAdded[result.type].filter(x => x.id !== result.id)
    setAlreadyAdded(newAdded)
    setChanged(true)
  }

  const save = async () => {
    await Api.AdminDiscordSetPermissions(alreadyAdded)
    setChanged(false)
  }

  const select = (role, result) => {
    const newAdded = { ...alreadyAdded }
    newAdded[result.type] = newAdded[result.type].filter(x => x.id !== result.id)
    newAdded[result.type].push({ ...result, role })
    setAlreadyAdded(newAdded)
    setChanged(true)
  }

  const setSRPAccess = (srpAccess, result) => {
    const newAdded = { ...alreadyAdded }
    const r = newAdded[result.type].find(x => x.id === result.id)
    r.srpAccess = srpAccess
    setAlreadyAdded(newAdded)
    setChanged(true)
  }

  const setJFAccess = (jfAccess, result) => {
    const newAdded = { ...alreadyAdded }
    const r = newAdded[result.type].find(x => x.id === result.id)
    r.jfAccess = jfAccess
    r.jfRate = 800
    r.jfCollateral = 10
    setAlreadyAdded(newAdded)
    setChanged(true)
  }

  const setJFRate = (jfRate, result) => {
    const newAdded = { ...alreadyAdded }
    const r = newAdded[result.type].find(x => x.id === result.id)
    r.jfRate = jfRate
    setAlreadyAdded(newAdded)
    setChanged(true)
  }

  const setJFCollateral = (jfCollateral, result) => {
    const newAdded = { ...alreadyAdded }
    const r = newAdded[result.type].find(x => x.id === result.id)
    r.jfCollateral = jfCollateral
    setAlreadyAdded(newAdded)
    setChanged(true)
  }

  const roleOptions = discordRoles.map(role => ({ text: role.name, value: role.id }))

  return (
    <EuiPageTemplate.Section grow>
      <EuiText><h1>Permissions Configuration</h1></EuiText>
      <EuiSpacer />
      <SearchContainer onSelect={add} alreadyAdded={alreadyAdded} />
      <EuiSpacer />
      <EuiText><h3>Roles</h3></EuiText>
      <EuiSpacer />
      <EuiFlexGroup direction='column'>
        {
        ['corporation', 'alliance'].map(t => alreadyAdded[t].map(x => <EuiFlexItem key={x.id}>
          <EuiFlexGroup alignItems='center'>
            <EuiFlexItem grow={false}>
              <img src={getPortrait(x.type, x.id)} alt={`[${x.ticker}] ${x.name}`} width={48} className='radius50' />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText>
                <strong>{`[${x.ticker}] ${x.name}`}</strong><br />
                <small style={{ textTransform: 'capitalize' }}>{x.type}</small>
              </EuiText>
            </EuiFlexItem>
            {
              x.jfAccess &&
                <>
                  <EuiFlexItem grow={false}>
                    <EuiFieldNumber defaultValue={x.jfRate || 0} prepend='m3 Rate' append='ISK/m3' onChange={e => setJFRate(parseInt(e.target.value, 10), x)} />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiFieldNumber defaultValue={x.jfCollateral || 0} prepend='Collateral' append='%' onChange={e => setJFCollateral(parseInt(e.target.value, 10), x)} />
                  </EuiFlexItem>
                </>
            }
            <EuiFlexItem grow={false}>
              <EuiSwitch
                label='JF Access'
                checked={x.jfAccess || false}
                onChange={e => setJFAccess(e.target.checked, x)}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiSwitch
                label='SRP Access'
                checked={x.srpAccess || false}
                onChange={e => setSRPAccess(e.target.checked, x)}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiSelect
                value={x.role}
                options={roleOptions}
                onChange={e => select(e.target.value, x)}
                aria-label='Discord Role'
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton fill iconType='minusInCircleFilled' color='danger' onClick={() => remove(x)}>
                Remove
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
                                                                      </EuiFlexItem>))
      }
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup>
        {
        changed &&
          <EuiFlexItem grow={false}>
            <EuiButton fill iconType='save' color='primary' onClick={save}>
              Save
            </EuiButton>
          </EuiFlexItem>
      }
      </EuiFlexGroup>
    </EuiPageTemplate.Section>
  )
}

export default AdminPermissions
