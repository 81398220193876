import { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import api from "../api"

const ItemName = ({ typeID }) => {
  const [typeInfo, setTypeInfo] = useState({})
  useEffect(() => {
    (async () => {
      if (Object.keys(typeInfo).length === 0) setTypeInfo(await api.SDE.GetType(typeID))
    })()
  }, [typeInfo, typeID])

  return (typeInfo.name || {}).en || 'Loading...'
}
ItemName.propTypes = {
  typeID: PropTypes.number.isRequired
}

export default ItemName
