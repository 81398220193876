import moment from 'moment'

const cache = {
  get: key => {
    const cached = localStorage.getItem(key)
    if (!cached) return null
    return JSON.parse(cached)
  },
  put: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
  del: key => localStorage.removeItem(key)
}

const esiBase = 'https://esi.evetech.net/latest'
const evepraisalBase = 'https://evepraisal.com'

const apiCall = async (method, url, body, useCache = false) => {
  if (url.indexOf('undefined') !== -1 || url.indexOf('null') !== -1) return null
  
  if (method === 'GET' && useCache) {
    const cached = cache.get(url)
    if (cached) {
      if (moment(cached.dt).add(1, 'hour').isBefore(moment())) {
        cache.del(url)
      } else {
        return cached.result
      }
    }
  }
  const options = { method }
  if (body) {
    options.body = JSON.stringify(body)
    options.headers = { 'Content-Type': 'application/json' }
  }
  let responseBody
  try {
    const response = await fetch(url, options)
    responseBody = await response.text()
    const result =  JSON.parse(responseBody)
    if (
      method === 'GET' &&
      response.status === 200 &&
      result &&
      !result.error &&
      useCache
    ) {
      cache.put(url, {
        result,
        dt: moment()
      })
    }
    return result
  } catch (e) {
    throw new Error(responseBody || e)
  }
}

const api = {
  GetDiscordUser: _                                      => apiCall('GET',   '/api/user/discord/user'),
  GetUserLogin: _                                        => apiCall('GET',   '/api/user/userLogin'),
  Logout: _                                              => apiCall('GET',   '/logout'),
  GetUserStatus: _                                       => apiCall('GET',   '/api/user/userStatus'),
  GetPublicInfo: characterId                             => apiCall('GET',   `/api/user/publicInfo/${characterId}`, null, true),
  GetESIStatus: characterId                              => apiCall('GET',   `/api/user/esi/${characterId}`, null, true),
  GetCorporationPublicInfo: corporationId                => apiCall('GET',   `/api/user/corporation/${corporationId}`, null, true),
  GetAlliancePublicInfo: allianceId                      => apiCall('GET',   `/api/user/alliance/${allianceId}`, null, true),
  GetStats: characterId                                  => apiCall('GET',   `/api/user/stats/${characterId}`),
  GetSkills: characterId                                 => apiCall('GET',   `/api/user/skills/${characterId}`),
  GetSkillQueue: characterId                             => apiCall('GET',   `/api/user/skillQueue/${characterId}`),
  GetWalletBalance: characterId                          => apiCall('GET',   `/api/user/walletBalance/${characterId}`),
  GetWalletJournal: (characterId, page)                  => apiCall('GET',   `/api/user/walletJournal/${page}/${characterId}`),
  GetWalletTransactions: characterId                     => apiCall('GET',   `/api/user/walletTransactions/${characterId}`),
  GetContacts: characterId                               => apiCall('GET',   `/api/user/contacts/${characterId}`),
  GetContracts: characterId                              => apiCall('GET',   `/api/user/contracts/${characterId}`),
  GetContractItems: (characterId, contractId)            => apiCall('GET',   `/api/user/contracts/${characterId}/${contractId}/items`),
  GetCorpHistory: characterId                            => apiCall('GET',   `/api/user/corpHistory/${characterId}`),
  GetClones: characterId                                 => apiCall('GET',   `/api/user/clones/${characterId}`),
  GetImplants: characterId                               => apiCall('GET',   `/api/user/implants/${characterId}`),
  GetStation: stationId                                  => apiCall('GET',   `/api/user/station/${stationId}`),
  GetStructure: structureId                              => apiCall('GET',   `/api/user/structure/${structureId}`),
  GetSystem: systemId                                    => apiCall('GET',   `/api/user/system/${systemId}`, null, true),
  GetMails: characterId                                  => apiCall('GET',   `/api/user/mails/${characterId}`),
  GetMailingList: characterId                            => apiCall('GET',   `/api/user/mailingLists/${characterId}`),
  GetMail: (characterId, mailId)                         => apiCall('GET',   `/api/user/mail/${characterId}/${mailId}`),
  GetRecentLosses: _                                     => apiCall('GET',   '/api/user/recentLosses'),
  GetSRP: _                                              => apiCall('GET',   '/api/user/srp'),
  GetLossFromURL: url                                    => apiCall('GET',   `/api/user/parseZkb?${new URLSearchParams({ url }).toString()}`),
  RequestSRP: srpRequest                                 => apiCall('POST',  '/api/user/srp', srpRequest),
  GetZkbChar: characterId                                => apiCall('GET',   `/api/user/zkbCharStats/${characterId}`, null, true),
  GetFleets: page                                        => apiCall('GET',   `/api/user/fleets/${page}`),
  PostMumble: password                                   => apiCall('POST',  '/api/user/mumble', { password }),

  FCIsMain: characterId                                  => apiCall('GET',    `/api/fc/isMain/${characterId}`, null, true),
  FCGetCurrentFleet: characterId                         => apiCall('GET',    `/api/fc/currentFleet/${characterId}`),
  FCGetTrackedFleets: _                                  => apiCall('GET',    '/api/fc/trackedFleets'),
  FCGetCurrentFleetMembers: (characterId, fleetId)       => apiCall('GET',    `/api/fc/currentFleet/${characterId}/${fleetId}`),
  FCTrackFleet: (characterId, fleetId, points, fleetType) => apiCall('POST',  `/api/fc/fleet/${characterId}`, { fleetId, points, fc: characterId, fleetType }),
  FCGetFleets: page                                      => apiCall('GET',    `/api/fc/fleets/${page}`),
  FCGetFleet: fleetId                                    => apiCall('GET',    `/api/fc/fleet/${fleetId}`),

  GetCharactersList: _                                   => apiCall('GET',    '/api/hr/listCharacters'),
  GetLedger: _                                           => apiCall('GET',    '/api/hr/ledger'),
  GetStructures: _                                       => apiCall('GET',    '/api/hr/structures'),
  GetStarbases: _                                        => apiCall('GET',    '/api/hr/starbases'),
  GetExtractions: _                                      => apiCall('GET',    '/api/hr/extractions'),
  GetRefinery: _                                         => apiCall('GET',    '/api/hr/refinery'),
  RefinerySetTax: itemsToChange                          => apiCall('PATCH',  '/api/hr/refinery', itemsToChange),
  GetCorpESI: corporationId                              => apiCall('GET',    `/api/hr/corpEsi/${corporationId}`),
  GetCorpSkills: _                                       => apiCall('GET',    '/api/hr/corpSkills'),
  GetCorpCharacters: corporationId                       => apiCall('GET',    `/api/hr/corpCharacters/${corporationId}`),

  AdminGetUsers: _                                       => apiCall('GET',    '/api/admin/users'),
  AdminSetUserAccess: (characterId, access)              => apiCall('PATCH',  `/api/admin/user/${characterId}/${access}`),
  AdminSetUserDiscordAdmin: (characterId, discordAdmin)  => apiCall('PATCH',  `/api/admin/user/discordAdmin/${characterId}/${discordAdmin}`),
  AdminDiscordGetRoles: _                                => apiCall('GET',    '/api/admin/discord/listRoles'),
  AdminDiscordGetPermissions: _                          => apiCall('GET',    '/api/admin/discord/permissions'),
  AdminDiscordSetPermissions: data                       => apiCall('POST',   '/api/admin/discord/permissions', data),
  AdminSearch: (type, search)                            => apiCall('GET',    `/api/admin/search/${type}?${new URLSearchParams({ search }).toString()}`),
  AdminGetSRP: (status, page)                            => apiCall('GET',    `/api/admin/srp/${status}/${page}`),
  AdminUpdateSRP: (srpId, data)                          => apiCall('POST',   `/api/admin/srp/${srpId}`, data),
  AdminGetStatuses: _                                    => apiCall('GET',    '/api/admin/statuses'),
  AdminGetDivisions: corpId                              => apiCall('GET',    `/api/admin/divisions/${corpId}`),
  AdminGetTransactions: (corpId, divisionId)             => apiCall('GET',    `/api/admin/corpTransactions/${corpId}/${divisionId}`),
  AdminGetJournal: (corpId, divisionId)                  => apiCall('GET',    `/api/admin/corpJournal/${corpId}/${divisionId}`),
  AdminGetWallet: corpId                                 => apiCall('GET',    `/api/admin/corpWallet/${corpId}`),
  AdminDeleteFleet: fleetId                              => apiCall('DELETE', `/api/admin/fleet/${fleetId}`),
  AdminGetFats: fats                                     => apiCall('POST',   '/api/admin/allianceFAT', { fats }),

  SDE: {
    GetDogmaAttributeCategory: categoryID                => apiCall('GET',    `/api/sde/dogmaAttributeCategory/${categoryID}`, null, true),
    GetDogmaAttribute: attributeID                       => apiCall('GET',    `/api/sde/dogmaAttribute/${attributeID}`, null, true),
    GetTypeCategory: categoryID                          => apiCall('GET',    `/api/sde/typeCategory/${categoryID}`, null, true),
    GetTypeGroup: groupID                                => apiCall('GET',    `/api/sde/typeGroup/${groupID}`, null, true),
    GetType: typeID                                      => apiCall('GET',    `/api/sde/type/${typeID}`, null, true),
    GetTypeDogma: typeID                                 => apiCall('GET',    `/api/sde/typeDogma/${typeID}`, null, true),
    SearchType: name                                     => apiCall('GET',    `/api/sde/searchType?${new URLSearchParams({ name }).toString()}`, null, true),
    GetSkills: _                                         => apiCall('GET',    '/api/sde/typeSkills')
  },

  ESI: {
    GetSolarSystem: solarSystemID                        => apiCall('GET',    `${esiBase}/universe/systems/${solarSystemID}`, null, true)
  },

  EVEPraisal: {    
    GetMoonOrePrices: _                                  => apiCall('GET',    `${evepraisalBase}/a/126l89.json?live=yes`, null, true),
    GetRefinedPrices: _                                  => apiCall('GET',    `${evepraisalBase}/a/zczav.json?live=yes`, null, true),
    GetItemPrices: typeID                                => apiCall('GET',    `${evepraisalBase}/item/${typeID}.json`, null, true)
  }
}

export default api
