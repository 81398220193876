import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Countdown from 'react-countdown'

import {
  EuiSpacer,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiStat,
  EuiPanel,
  EuiSkeletonText
} from '@elastic/eui'

import api from '../api'

const getEta = (days, hours, minutes, seconds, completed) => {
  if (completed) return '-'
  const slices = []
  if (days > 0) slices.push(`${days}d`)
  if (hours > 0) slices.push(`${hours}h`)
  if (minutes > 0) slices.push(`${minutes}m`)
  if (seconds > 0) slices.push(`${seconds}s`)
  return slices.join(' ')
}

const SkillCountdown = ({ startDate, endDate }) => {
  if (moment.utc(startDate).isBefore(moment.utc())) {
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      return <span>{getEta(days, hours, minutes, seconds, completed)}</span>
    }
    renderer.propTypes = {
      days: PropTypes.number,
      hours: PropTypes.number,
      minutes: PropTypes.number,
      seconds: PropTypes.number,
      completed: PropTypes.bool
    }
    return <Countdown date={moment.utc(endDate).toDate()} renderer={renderer} />
  } else {
    const start = moment.utc(startDate)
    const end = moment.utc(endDate)
    const diffD = end.diff(start, 'days')
    const diffH = end.diff(start, 'hours')
    const diffM = end.diff(start, 'minutes')
    const diffS = end.diff(start, 'seconds')
    const D = diffD
    const H = diffH - D * 24
    const M = diffM - D * 24 * 60 - H * 60
    const S = diffS - D * 24 * 60 * 60 - H * 60 * 60 - M * 60
    return <span>{getEta(D, H, M, S, false)}</span>
  }
}

const QueueEntry = ({ skill, counter }) => {
  const [objectType, setObjectType] = useState(null)

  useEffect(
    () => {
      if (!objectType) { (async () => setObjectType(await api.SDE.GetType(skill.skill_id)))() }
    },
    [objectType, skill.skill_id]
  )

  const lev = skill.finished_level
  return (
    <EuiFlexGroup alignItems='center' className='hoverable' gutterSize='none'>
      <EuiFlexItem grow={false}>
        <EuiText>{`${counter}`.padStart(2, '0')}.</EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText>
          <strong>
            {!objectType ? <EuiSkeletonText lines={1} /> : objectType.name.en}
          </strong>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <div className='skillLevelContainer'>
          {[1, 2, 3, 4, 5].map(l => {
            return (
              <div
                key={l}
                className={`skillLevel ${l < lev ? 'done' : ''} ${
                  l === lev ? 'current' : ''
                } ${l > lev ? 'next' : ''}`}
              />
            )
          })}
        </div>
      </EuiFlexItem>
      <EuiFlexItem grow={false} style={{ minWidth: '160px' }}>
        <EuiText textAlign='right'>
          <SkillCountdown
            startDate={skill.start_date}
            endDate={skill.finish_date}
          />
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

QueueEntry.propTypes = {
  skill: PropTypes.object.isRequired,
  counter: PropTypes.number.isRequired
}

const SkillQueue = ({ characterId }) => {
  const [skillQueue, setSkillQueue] = useState({})
  const [errors, setErrors] = useState(null)

  useEffect(
    () => {
      if (skillQueue && Object.keys(skillQueue).length === 0)
      (async () => {
        try {
          const res = await api.GetSkillQueue(characterId)
          res.length !== 0 && setSkillQueue(res)
        } catch (err) {
          setErrors(err)
        }
      })()
    },
    [characterId, skillQueue]
  )

  if (errors) return <EuiText color='danger'>{errors.toString()}</EuiText>
  if (!skillQueue || Object.keys(skillQueue).length === 0) { return <EuiSkeletonText lines={3} /> }
  if (skillQueue.error) {
    return (
      <EuiText color='danger'>
        An error has occurred. Please try again later.
      </EuiText>
    )
  }

  const filteredQueue = Object.values(skillQueue).filter(skill =>
    moment(skill.finish_date).isAfter(moment.utc())
  )

  let queueCounter = 0
  return (
    <>
      <EuiSpacer />
      <EuiTitle>
        <h3>Skill Queue</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat
              title={`${filteredQueue.length}/50`}
              description='Skills in queue'
              textAlign='center'
            />
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat
              title={
                filteredQueue.length > 0
                  ? (
                    <SkillCountdown
                      startDate={moment.utc().toDate()}
                      endDate={
                      filteredQueue[filteredQueue.length - 1].finish_date
                    }
                    />
                    )
                  : (
                      '-'
                    )
              }
              description='Queue length'
              textAlign='center'
            />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem>
          {filteredQueue.map(skill => {
            queueCounter++
            return (
              <QueueEntry
                key={`${skill.skill_id}_${skill.finished_level}`}
                counter={queueCounter}
                skill={skill}
              />
            )
          })}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

SkillQueue.propTypes = {
  characterId: PropTypes.number.isRequired
}

export default SkillQueue
