import { useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

import {
  EuiIcon,
  EuiSideNav,
  EuiHeader,
  EuiHeaderSectionItem,
  EuiImage,
  EuiPageTemplate
} from '@elastic/eui'

import vipLogo from '../images/vip.png'

import UserMenu from '../components/UserMenu'
import Dashboard from '../pages/Dashboard'
import Freight from '../pages/Freight'
import SrpRequests from '../pages/SrpRequests'
// import Status from '../pages/Status'

import Profile from '../pages/Profile'
import Mail from '../pages/Mail'
import NotFound from '../pages/NotFound'
import AdminCharacters from '../pages/AdminCharacters'
import AdminStructures from '../pages/AdminStructures'
import AdminExtractions from '../pages/AdminExtractions'
import AdminLedger from '../pages/AdminLedger'
import CorpESI from '../pages/CorpESI'
import SkillFinder from '../pages/SkillFinder'
import AdminUsers from '../pages/AdminUsers'
import AdminPermissions from '../pages/AdminPermissions'
import AdminSRP from '../pages/AdminSRP'
import AdminRefineryValues from '../pages/AdminRefineryValues'
import AdminStatuses from '../pages/AdminStatuses'
import userState from '../userState'
import FCFleetTracking from '../pages/FCFleetTracking'
import FCFleetList from '../pages/FCFleetList'
import AdminCorpWallet from '../pages/AdminCorpWallet'
import AdminFATs from '../pages/AdminFats'

const MainRouter = () => {
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [userLogin, setUserLogin] = useRecoilState(userState)

  const accessLevel = userLogin ? userLogin.accessLevel : -1
  const permissions = userLogin ? userLogin.Permissions : {}

  const [isSideNavOpenOnMobile, setIsSideNavOpenOnMobile] = useState(false)
  const toggleOpenOnMobile = () => setIsSideNavOpenOnMobile(!isSideNavOpenOnMobile)

  const sideNav = [
    {
      id: 'mainMenu',
      name: 'Menu',
      icon: <EuiIcon type='dashboardApp' />,
      items: [
        {
          id: 'dashboard',
          name: 'Dashboard',
          icon: <EuiIcon type='dashboardApp' />,
          onClick: () => navigate('/')
        }
      ]
    }
  ]

  // Permission based menu items
  // if (userLogin.fullEsi) {
  //   sideNav[0].items.push({
  //     id: 'status',
  //     name: 'My Status',
  //     icon: <EuiIcon type='scale' />,
  //     onClick: () => navigate('/status')
  //   })
  // }
  if (permissions.jfAccess) {
    sideNav[0].items.push({
      id: 'freight',
      name: 'Freight Service',
      icon: <EuiIcon type='logstashInput' />,
      onClick: () => navigate('/freight')
    })
  }
  if (permissions.srpAccess) {
    sideNav[0].items.push({
      id: 'srp',
      name: 'Ship Replacement',
      icon: <EuiIcon type='indexEdit' />,
      onClick: () => navigate('/srp')
    })
  }

  // Handle Pizza/VIP only things
  const isVIPorPizza = [98630834, 98528272].includes(userLogin.CharacterCorporation)
  if (isVIPorPizza || accessLevel >= 2) {
    sideNav[0].items.push({
      id: 'extractions',
      name: 'Extractions',
      icon: <EuiIcon type='calendar' />,
      onClick: () => navigate('/extractions')
    })
  }

  // FC
  if (accessLevel >= 1) {
    sideNav.push({
      id: 'fc',
      name: 'FC',
      icon: <EuiIcon type='cheer' />,
      items: [
        {
          id: 'fleetTracking',
          name: 'Fleet Tracking',
          icon: <EuiIcon type='eye' />,
          onClick: () => navigate('/fleetTracking')
        },
        {
          id: 'fleetList',
          name: 'Fleet List',
          icon: <EuiIcon type='apmTrace' />,
          onClick: () => navigate('/fleetList')
        }
      ]
    })
  }

  // HR
  if (accessLevel >= 2) {
    sideNav.push({
      id: 'hr',
      name: 'HR',
      icon: <EuiIcon type='users' />,
      items: [
        {
          id: 'chars',
          name: 'Characters List',
          icon: <EuiIcon type='visTable' />,
          onClick: () => navigate('/characters')
        },
        {
          id: 'structures',
          name: 'Structures',
          icon: <EuiIcon type='visBarHorizontal' />,
          onClick: () => navigate('/structures')
        },
        {
          id: 'ledger',
          name: 'Mining Ledger',
          icon: <EuiIcon type='heatmap' />,
          onClick: () => navigate('/ledger')
        },
        {
          id: 'corpesi',
          name: 'Corp ESI',
          icon: <EuiIcon type='reporter' />,
          onClick: () => navigate('/corpesi')
        },
        {
          id: 'skillfinder',
          name: 'Skill Finder',
          icon: <EuiIcon type='visTagCloud' />,
          onClick: () => navigate('/skillfinder')
        }
      ]
    })
  }
  
  // Admin
  if (accessLevel >= 3) {
    sideNav.push({
      id: 'admin',
      name: 'Admin',
      icon: <EuiIcon type='managementApp' />,
      items: [
        {
          id: 'users',
          name: 'Users Administration',
          icon: <EuiIcon type='dashboardApp' />,
          onClick: () => navigate('/users')
        },
        {
          id: 'perms',
          name: 'Permissions Configuration',
          icon: <EuiIcon type='editorComment' />,
          onClick: () => navigate('/permissions')
        },
        {
          id: 'managesrp',
          name: 'Manage SRP',
          icon: <EuiIcon type='indexEdit' />,
          onClick: () => navigate('/managesrp')
        },
        {
          id: 'refinery',
          name: 'Mining Taxes',
          icon: <EuiIcon type='controlsVertical' />,
          onClick: () => navigate('/miningTaxes')
        },
        {
          id: 'statuses',
          name: 'Statuses',
          icon: <EuiIcon type='scale' />,
          onClick: () => navigate('/statuses')
        },
        {
          id: 'wallet',
          name: 'Corp Wallet',
          icon: <EuiIcon type='currency' />,
          onClick: () => navigate('/wallet')
        },
        {
          id: 'fats',
          name: 'Corp FATs',
          icon: <EuiIcon type='visBarHorizontalStacked' />,
          onClick: () => navigate('/fats')
        }
      ]
    })
  }

  return (
    <EuiPageTemplate>
      <EuiHeader position='fixed'>
        <EuiHeaderSectionItem border='none'>
          <span onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
            <EuiImage src={vipLogo} size={24} margin='l' alt='VIP' />
            Very Italian People
          </span>
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem border='none'>
          <UserMenu />
        </EuiHeaderSectionItem>
      </EuiHeader>
      <EuiPageTemplate.Sidebar sticky>
        <EuiSideNav
          mobileTitle={isSideNavOpenOnMobile ? 'Close Menu' : 'Open Menu'}
          toggleOpenOnMobile={toggleOpenOnMobile}
          isOpenOnMobile={isSideNavOpenOnMobile}
          items={sideNav}
          style={{ padding: '1em' }}
        />
      </EuiPageTemplate.Sidebar>
      <Routes>
        {/* User Paths */}
        <Route exact path='/' element={<Dashboard />} />
        <Route path='/freight' element={permissions.jfAccess ? <Freight /> : <NotFound />} />
        <Route path='/srp' element={permissions.srpAccess ? <SrpRequests /> : <NotFound />} />
        {/* <Route path='/status' element={userLogin.fullEsi ? <Status /> : <NotFound />} /> */}

        {/* FC Paths */}
        <Route path='/fleetTracking'  element={accessLevel >= 1 ? <FCFleetTracking /> : <NotFound />} />
        <Route path='/fleetList'  element={accessLevel >= 1 ? <FCFleetList /> : <NotFound />} />

        {/* HR Paths */}
        <Route path='/characters'  element={accessLevel >= 2 ? <AdminCharacters /> : <NotFound />} />
        <Route path='/character/:characterId' element={accessLevel >= 2 ? <Profile /> : <NotFound />} />
        <Route path='/mail/:characterId/:mailId'  element={accessLevel >= 2 ? <Mail /> : <NotFound />} />
        <Route path='/ledger'  element={accessLevel >= 2 ? <AdminLedger /> : <NotFound />} />
        <Route path='/structures'  element={accessLevel >= 2 ? <AdminStructures /> : <NotFound />} />
        <Route path='/extractions'  element={isVIPorPizza || accessLevel >= 2 ? <AdminExtractions /> : <NotFound />} />
        <Route path='/corpesi'  element={accessLevel >= 2 ? <CorpESI /> : <NotFound />} />
        <Route path='/skillfinder' element={accessLevel >= 2 ? <SkillFinder /> : <NotFound />} />

        {/* Admin Paths */}
        <Route path='/users'  element={accessLevel >= 3 ? <AdminUsers /> : <NotFound />} />
        <Route path='/permissions'  element={accessLevel >= 3 ? <AdminPermissions /> : <NotFound />} />
        <Route path='/managesrp'  element={ accessLevel >= 3 ? <AdminSRP /> : <NotFound />} />
        <Route path='/miningTaxes'  element={accessLevel >= 3 ? <AdminRefineryValues /> : <NotFound />} />
        <Route path='/statuses'  element={accessLevel >= 3 ? <AdminStatuses /> : <NotFound />} />
        <Route path='/wallet'  element={accessLevel >= 3 ? <AdminCorpWallet /> : <NotFound />} />
        <Route path='/fats'  element={accessLevel >= 3 ? <AdminFATs /> : <NotFound />} />

        <Route element={<NotFound />} />
      </Routes>
    </EuiPageTemplate>
  )
}

export default MainRouter
