import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import Utils from '../Utils'

import {
  EuiLoadingSpinner
} from '@elastic/eui'
import api from '../api'

const CharButton = ({ characterId, characterName, isAlt }) => {
  const navigate = useNavigate()

  const [corporationId, setCorporation] = useState(null)
  const [allianceId, setAlliance] = useState(null)

  const [publicInfo, setPublicInfo] = useState(null)
  const [corpPublicInfo, setCorpPublicInfo] = useState(null)
  const [allyPublicInfo, setAllyPublicInfo] = useState(null)

  useEffect(() => {
    if (publicInfo !== null && publicInfo.character_id === characterId) return
    api.GetPublicInfo(characterId).then(x => {
      setPublicInfo({ ...x, character_id: characterId })
      setCorporation(x.corporation_id)
      setAlliance(x.alliance_id)
    })
  }, [characterId, publicInfo])

  useEffect(() => {
    if (corporationId === null || (corpPublicInfo !== null && corpPublicInfo.corporation_id === corporationId)) return
    api.GetCorporationPublicInfo(corporationId).then(x => setCorpPublicInfo({ ...x, corporation_id: corporationId }))
  }, [corporationId, corpPublicInfo])

  useEffect(() => {
    if (allianceId === null || !allianceId || (allyPublicInfo !== null && allyPublicInfo.alliance_id === allianceId)) return
    api.GetAlliancePublicInfo(allianceId).then(x => setAllyPublicInfo({ ...x, alliance_id: allianceId }))
  }, [allianceId, allyPublicInfo])

  if (!publicInfo) return <EuiLoadingSpinner />

  const corporationPublicInfo = corpPublicInfo || {}
  const alliancePublicInfo = allyPublicInfo || {}

  return (
    <div
      className={isAlt ? 'charButton charButtonRight' : 'charButton chatButtonMain'}
      onClick={() => navigate(`/character/${characterId}`)}
      style={{ backgroundImage: `url('${Utils.GetCharacterPortrait(characterId, isAlt ? 64 : 128)}')` }}
    >
      <strong>{publicInfo ? publicInfo.name : characterName}</strong>
      <br />
      {
      publicInfo.corporation_id &&
        <>[{corporationPublicInfo.ticker || '-'}] {corporationPublicInfo.name || 'Loading...'}</>
    }
      <br />
      {
      publicInfo.alliance_id &&
        <>[{alliancePublicInfo.ticker || '-'}] {alliancePublicInfo.name || 'Loading...'}</>
    }
    </div>
  )
}

CharButton.propTypes = {
  characterId: PropTypes.number.isRequired,
  characterName: PropTypes.string,
  isAlt: PropTypes.bool
}

export default CharButton
