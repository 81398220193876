import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { EuiLink, EuiLoadingSpinner } from '@elastic/eui'

import api from '../api'
import Utils from '../Utils'

import imgUnknown from '../images/unknown.png'

const MailRecipient = ({ recipient, larger, right }) => {
  const getPortraitSelector = t => {
    if (t === 'character') return Utils.GetCharacterPortrait
    if (t === 'corporation') return Utils.GetCorporationLogo
    if (t === 'alliance') return Utils.GetAllianceLogo
  }

  const [publicInfo, setPublicInfo] = useState({})

  useEffect(() => {
    if (
      recipient &&
        recipient.recipient_type !== 'mailing_list' &&
        publicInfo &&
        Object.keys(publicInfo).length === 0
    ) {
      let method
      if (recipient.recipient_type === 'character') method = api.GetPublicInfo
      else if (recipient.recipient_type === 'corporation') method = api.GetCorporationPublicInfo
      else if (recipient.recipient_type === 'alliance') method = api.GetAlliancePublicInfo
      if (typeof method === 'function') method(recipient.recipient_id).then(setPublicInfo)
    }
  },
  [publicInfo, recipient]
  )

  let style = {}
  if (larger) style = { width: '220px' }

  if (recipient && recipient.recipient_type === 'mailing_list') {
    return (
      <div
        className={`mailRecipient ${right ? 'isRight' : ''}`}
        style={{ ...style, backgroundImage: `url('${imgUnknown}')` }}
      >
        <strong>Unknown Mailing List</strong>
      </div>
    )
  }

  if (!publicInfo || Object.keys(publicInfo).length === 0) { return <EuiLoadingSpinner /> } else {
    const imageUrl = publicInfo.error
      ? imgUnknown
      : getPortraitSelector(recipient.recipient_type)(
        recipient.recipient_id,
        64
      )

    return (
      <div
        className={`mailRecipient ${right ? 'isRight' : ''}`}
        style={{ ...style, backgroundImage: `url('${imageUrl}')` }}
      >
        <EuiLink
          href={Utils.ZKillboard(
            recipient.recipient_type,
            recipient.recipient_id
          )}
          target='_blank'
          external={false}
        >
          <strong>{publicInfo.name || 'Unknown'}</strong>
        </EuiLink>
      </div>
    )
  }
}

MailRecipient.propTypes = {
  recipient: PropTypes.object.isRequired,
  larger: PropTypes.bool,
  right: PropTypes.bool
}

export default MailRecipient
