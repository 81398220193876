import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import uniq from 'lodash.uniq'
import sortBy from 'lodash.sortby'
import sumBy from 'lodash.sumby'
import moment from 'moment'

import {
  EuiPageTemplate,
  EuiText,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiAvatar,
  EuiSuperSelect,
  EuiBasicTable,
  EuiFieldNumber,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiCopy
} from '@elastic/eui'

import { RIGHT_ALIGNMENT } from '@elastic/eui/lib/services'

import Utils from '../Utils'
import api from '../api'
import FormatNumber from '../components/FormatNumber'

const ItemIcon = ({ typeID }) => {
  const [typeInfo, setTypeInfo] = useState({})
  useEffect(() => {
    (async () => {
      if (Object.keys(typeInfo).length === 0) setTypeInfo(await api.SDE.GetType(typeID))
    })()
  }, [typeInfo, typeID])

  return <img src={Utils.GetTypeIcon(typeID, 64)} width={32} alt={(typeInfo.name || {}).en || 'Loading...'} />
}
ItemIcon.propTypes = {
  typeID: PropTypes.number.isRequired
}

const ItemName = ({ typeID }) => {
  const [typeInfo, setTypeInfo] = useState({})
  useEffect(() => {
    (async () => {
      if (Object.keys(typeInfo).length === 0) setTypeInfo(await api.SDE.GetType(typeID))
    })()
  }, [typeInfo, typeID])

  return (typeInfo.name || {}).en || 'Loading...'
}
ItemName.propTypes = {
  typeID: PropTypes.number.isRequired
}

const AdminLedger = () => {
  const [ledgerList, setLedgerList] = useState({})
  const [charactersList, setCharactersList] = useState([])
  const [refineryValues, setRefineryValues] = useState([])
  const [refinedPrices, setRefinedPrices] = useState([])
  const [priceType, setPriceType] = useState('buy')
  const [priceFunc, setPriceFunc] = useState('max')

  const [selectedStation, setSelectedStation] = useState(0)
  const [selectedDate, setSelectedDate] = useState(0)
  const [efficiency, setEfficiency] = useState(parseInt(localStorage.getItem('refineEfficiency') || '72'), 10)
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({})

  useEffect(() => {
    if (refineryValues && Object.keys(refineryValues).length === 0)
      (async () => setRefineryValues(await api.GetRefinery()))()
  }, [refineryValues])

  useEffect(() => {
    if (refinedPrices.length === 0) {
      (async () => {
        const apiResult = await api.EVEPraisal.GetRefinedPrices()
        setRefinedPrices(apiResult.items || [])
      })()
    }
  }, [refinedPrices])

  useEffect(() => {
    if (charactersList && Object.keys(charactersList).length === 0)
      (async () => setCharactersList(await api.GetCharactersList()))()
  })

  useEffect(() => {
    if (Object.keys(ledgerList).length === 0) {
      (async () => setLedgerList(await api.GetLedger()))()
    } else {
      if (selectedStation === 0) {
        const sortedLedgers = sortBy(ledgerList.ledger, ['structure.name'])
        setSelectedStation(sortedLedgers[0].observer.observer_id)
        setSelectedDate(sortedLedgers[0].observerData.map(o => o.last_updated).sort().reverse()[0])
      }
    }
  }, [ledgerList, selectedStation])

  if (!refineryValues || refinedPrices.length === 0) return null

  const stationOptions = ledgerList.ledger
    ? sortBy(ledgerList.ledger, ['structure.name']).map((l, i) => ({
        value: l.observer.observer_id,
        inputDisplay: <EuiFlexGroup gutterSize='s' alignItems='center' responsive={false}>
          <EuiFlexItem grow={false}>
            <EuiAvatar imageUrl={Utils.GetTypeRender(l.structure.type_id)} size='m' name={l.structure.name} />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>{l.structure.name}</EuiText>
          </EuiFlexItem>
                      </EuiFlexGroup>
      }))
    : []

  const datesOptions = ledgerList.ledger && ledgerList.ledger.find(l => l.observer.observer_id === selectedStation)
    ? uniq(ledgerList.ledger.find(l => l.observer.observer_id === selectedStation).observerData.map(o => o.last_updated)).sort().reverse().map(dt => ({
        value: dt,
        inputDisplay: moment(dt).format('YYYY MMMM DD')
      }))
    : []

  const priceTypeOptions = [
    { value: 'buy', inputDisplay: 'Buy' },
    { value: 'sell', inputDisplay: 'Sell' },
    { value: 'all', inputDisplay: 'All' }
  ]

  const priceFuncOptions = [
    { value: 'min', inputDisplay: 'Min' },
    { value: 'avg', inputDisplay: 'Avg' },
    { value: 'max', inputDisplay: 'Max' }
  ]

  const selectedObserver = ((ledgerList.ledger || [])
    .find(l => l.observer.observer_id === selectedStation) || { observerData: [] })
    .observerData.filter(l => l.last_updated === selectedDate)

  const mainChars = []

  for (const observer of selectedObserver) {
    const characterId = observer.character_id
    const main = charactersList.find(x => {
      if (x.CharacterID === characterId) return true
      if (x.Alts.find(a => a.CharacterID === characterId)) return true
      return false
    })
    if (main && !mainChars.includes(main.CharacterID)) mainChars.push(main.CharacterID)
  }

  const ledgerOutput = mainChars.map(mainId => {
    const main = charactersList.find(m => m.CharacterID === mainId)
    const ledgerRaw = selectedObserver.filter(l => l.character_id === mainId || main.Alts.map(a => a.CharacterID).includes(l.character_id))
    const ledger = []
    for (const ledgerEntry of ledgerRaw) {
      const refinery = refineryValues.find(r => r.type_id === ledgerEntry.type_id)
      const refineryValuesProcessed = refinery.quantities.map(q => {
        const quantity = Math.floor(ledgerEntry.quantity / refinery.size * q.quantity / 100 * efficiency)
        const refinedValue = refinedPrices.find(r => r.typeID === q.type_id).prices[priceType][priceFunc]
        return {
          type_id: q.type_id,
          quantity: quantity,
          value: quantity * refinedValue
        }
      })
      const beforeTax = sumBy(refineryValuesProcessed, 'value')
      const afterTax = beforeTax / 100 * (100 - refinery.tax)
      const char = ledgerEntry.character_id === main.CharacterID ? main : main.Alts.find(a => a.CharacterID === ledgerEntry.character_id)
      ledger.push({
        ...ledgerEntry,
        ...char,
        tax: refinery.tax,
        refinery: refineryValuesProcessed,
        beforeTax,
        afterTax
      })
    }
    return { CharacterID: main.CharacterID, main, ledger }
  })

  const toggleDetails = (ledgerEntry) => {
    const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap }
    if (itemIdToExpandedRowMapValues[ledgerEntry.CharacterID]) {
      delete itemIdToExpandedRowMapValues[ledgerEntry.CharacterID]
    } else {
      itemIdToExpandedRowMapValues[ledgerEntry.CharacterID] = (
        <EuiBasicTable items={sortBy(ledgerEntry.ledger, 'character_id')} columns={userLedgerColumns} />
      )
    }
    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues)
  }

  const ledgerColumns = [
    {
      render: function renderType (ledgerEntry) {
        return <img src={Utils.GetCharacterPortrait(ledgerEntry.CharacterID, 64)} width={60} alt={ledgerEntry.main.CharacterName} className='radius50' />
      },
      width: '65px'
    },
    {
      name: 'Character',
      render: function renderType (ledgerEntry) {
        return (
          <EuiCopy textToCopy={ledgerEntry.main.CharacterName}>
            {
            doCopy => <EuiButtonEmpty onClick={doCopy} iconType='copyClipboard'>
              <strong>{ledgerEntry.main.CharacterName}</strong>
                      </EuiButtonEmpty>
          }
          </EuiCopy>
        )
      }
    },
    {
      align: RIGHT_ALIGNMENT,
      name: 'Total',
      render: function renderType (ledgerEntry) {
        const charTotal = sumBy(ledgerEntry.ledger, 'afterTax')
        return (
          <EuiCopy textToCopy={charTotal.toFixed(2)}>
            {
            doCopy => <EuiText textAlign='right'>
              <EuiButtonEmpty onClick={doCopy} iconType='copyClipboard' iconSide='right'>
                <FormatNumber amount={charTotal} isTransaction />
              </EuiButtonEmpty>
                      </EuiText>
          }
          </EuiCopy>
        )
      }
    },
    {
      align: RIGHT_ALIGNMENT,
      width: '40px',
      isExpander: true,
      render: function renderExpand (ledgerEntry) {
        return (
          <EuiButtonIcon
            onClick={() => toggleDetails(ledgerEntry)}
            iconType={itemIdToExpandedRowMap[ledgerEntry.CharacterID] ? 'arrowUp' : 'arrowDown'}
          />
        )
      }
    }
  ]

  const userLedgerColumns = [
    {
      render: function renderType (ledgerEntry) {
        return <img src={Utils.GetCharacterPortrait(ledgerEntry.CharacterID, 64)} width={32} className='radius50' alt={ledgerEntry.CharacterName} />
      },
      width: '40px'
    },
    {
      field: 'CharacterName',
      name: 'Character'
    },
    {
      render: function renderType (ledgerEntry) {
        return <ItemIcon typeID={ledgerEntry.type_id} />
      },
      width: '40px'
    },
    {
      name: 'Material',
      render: function renderType (ledgerEntry) {
        return <>{ledgerEntry.quantity} <ItemName typeID={ledgerEntry.type_id} /></>
      },
      width: '150px'
    },
    {
      name: 'Refined',
      render: function renderType (ledgerEntry) {
        return (
          <EuiFlexGroup direction='column' gutterSize='xs'>
            {ledgerEntry.refinery.map(refinedMaterial => {
              return (
                <EuiFlexItem key={refinedMaterial.type_id}>
                  <EuiFlexGroup>
                    <EuiFlexItem grow={false}>
                      <ItemIcon typeID={refinedMaterial.type_id} />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      {refinedMaterial.quantity} <ItemName typeID={refinedMaterial.type_id} />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <FormatNumber amount={refinedMaterial.value} isTransaction />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              )
            })}
          </EuiFlexGroup>
        )
      }
    },
    {
      name: 'Value',
      render: function renderValue (ledgerEntry) {
        return (
          <EuiFlexGroup direction='column'>
            <EuiFlexItem>
              Before Tax:
              <FormatNumber amount={ledgerEntry.beforeTax} isTransaction />
            </EuiFlexItem>
            <EuiFlexItem>
              After {ledgerEntry.tax}% Tax:
              <FormatNumber amount={ledgerEntry.afterTax} isTransaction />
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      },
      width: '150px'
    }
  ]

  return (
    <EuiPageTemplate.Section grow>
      <EuiText><h1>Ledger</h1></EuiText>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiSuperSelect fullWidth options={stationOptions} valueOfSelected={selectedStation} onChange={newStation => setSelectedStation(newStation)} />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiSuperSelect fullWidth options={datesOptions} valueOfSelected={selectedDate} onChange={newDate => setSelectedDate(newDate)} />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiSuperSelect fullWidth options={priceTypeOptions} valueOfSelected={priceType} onChange={newPriceType => setPriceType(newPriceType)} />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiSuperSelect fullWidth options={priceFuncOptions} valueOfSelected={priceFunc} onChange={newPriceFunc => setPriceFunc(newPriceFunc)} />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFieldNumber
            prepend='Efficiency'
            append='%'
            placeholder='Efficiency'
            fullWidth
            min={0} max={100}
            value={efficiency}
            onChange={e => {
              localStorage.setItem('refineEfficiency', e.target.value)
              setEfficiency(e.target.value)
            }}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>Total ISK:</EuiFlexItem>
        <EuiFlexItem>
          <FormatNumber
            amount={ledgerOutput.map(l => sumBy(l, 'afterTax')).reduce((a, b) => a + b, 0)}
            isTransaction
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiSpacer />
      <EuiBasicTable
        items={ledgerOutput}
        columns={ledgerColumns}
        itemId='CharacterID'
        isExpandable
        itemIdToExpandedRowMap={itemIdToExpandedRowMap}
      />
    </EuiPageTemplate.Section>
  )
}

export default AdminLedger
