import { useState, useEffect } from 'react'
import { useRecoilState } from 'recoil'

import {
    EuiPageTemplate,
    EuiSpacer,
    EuiText,
    EuiButton,
    EuiFlexGroup,
    EuiFlexGrid,
    EuiFlexItem,
    EuiLink,
    EuiPanel,
    EuiLoadingSpinner,
    EuiAvatar,
    EuiTextColor,
    EuiFieldText,
    EuiGlobalToastList,
    EuiIcon,
    copyToClipboard, EuiButtonIcon
} from '@elastic/eui'

import Utils from '../Utils'
import CharacterSheet from '../components/CharacterSheet'
import userState from '../userState'
import api from '../api'

const Dashboard = () => {
  // eslint-disable-next-line no-unused-vars
  const userLogin = useRecoilState(userState)[0]
  const [discord, setDiscord] = useState(false)
  const [newMumblePassword, setMumblePassword] = useState('')
  const [toasts, setToasts] = useState([])

    const mumbleToast = success => setToasts(toasts.concat({
        title: 'Mumble',
        color: success ? 'success' : 'danger',
        text: success
            ? <p>Password updated</p>
            : <p>Something went wrong updating your password</p>
    }))
    const removeToast = removedToast =>
        setToasts(toasts.filter((toast) => toast.id !== removedToast.id))

  useEffect(() => {
    api.GetDiscordUser()
      .then(setDiscord)
      .catch(() => setDiscord(null))
  }, [])

  const discordAuth = () => {
    window.location.href = '/discordLogin'
    return null
  }

  const mumbleUsername = userLogin.CharacterName.replace(' ', '_')
  const updateMumblePassword = async () => {
      try {
          await api.PostMumble(newMumblePassword)
          mumbleToast(true)
      } catch (e) {
          console.error(e)
          mumbleToast(false)
      }
  }
  const newRandomPassword = () => {
      let result = '';
      const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < 10) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
      }
      setMumblePassword(result)
  }

  return (
    <EuiPageTemplate.Section grow>
      <CharacterSheet characterId={userLogin.CharacterID} />
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel hasShadow>
            <EuiText className='discordTitle'>
              <h2>
                <strong>Discord</strong>
              </h2>
            </EuiText>
            {
              discord === false &&
                <EuiText textAlign='center'>
                  <EuiLoadingSpinner size='l' />
                </EuiText>
            }
            {
              discord === null &&
                <EuiText textAlign='center'>
                  <div className='discordButton' onClick={discordAuth}>
                    Login with Discord
                  </div>
                </EuiText>
            }
            {
              discord !== null &&
              discord !== false &&
              Object.keys(discord).length !== 0 &&
                <>
                  <EuiFlexGroup alignItems='center'>
                    <EuiFlexItem>
                      <EuiText>Authenticated correctly!</EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiText textAlign='right'>
                        <EuiTextColor color='primary'>
                          <strong style={{ fontSize: 'larger' }}>
                            {discord.username}
                          </strong>
                        </EuiTextColor>
                        { discord.discriminator !== '0' && <EuiTextColor color='subdued'>
                          <small style={{ fontSize: 'smaller' }}>
                            #{discord.discriminator}
                          </small>
                        </EuiTextColor> }
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiText textAlign='right'>
                        <EuiAvatar
                          name={discord.username}
                          size='xl'
                          imageUrl={Utils.GetDiscordAvatar(discord)}
                        />
                      </EuiText>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  <EuiSpacer />
                  <EuiFlexGroup>
                    <EuiFlexItem>
                      <EuiText textAlign='right'>
                        <div className='discordButton' onClick={discordAuth}>
                          Re-link Discord
                        </div>
                      </EuiText>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </>
            }
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
            <EuiPanel hasShadow>
                <EuiText className='mumbleTitle'>
                    <h2>
                        <strong>Mumble</strong>
                    </h2>
                </EuiText>
                <EuiSpacer />
                <EuiFlexGroup alignItems='center'>
                    {
                        userLogin.Permissions.mumbleAccess
                        ? <EuiFlexItem>
                            <EuiFlexGroup>
                                <EuiFlexItem>
                                    <EuiFieldText placeholder="New password" value={newMumblePassword} onChange={e => setMumblePassword(e.target.value)} />
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiButton onClick={newRandomPassword}>
                                        1. <EuiIcon type='refresh' />
                                    </EuiButton>
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiButton disabled={newMumblePassword.length < 4} onClick={updateMumblePassword}>
                                        2. Set Password
                                    </EuiButton>
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiButton color='success' href={`mumble://${mumbleUsername}@voice.vip-eve.it`}>
                                        3. Open Mumble
                                    </EuiButton>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                            <EuiSpacer />
                            <EuiFlexGroup>
                                <EuiFlexItem>
                                    Server address:<br />
                                    <strong>
                                        voice.vip-eve.it
                                        <EuiButtonIcon  aria-label="Copy server"  iconType="copy"
                                            onClick={() => copyToClipboard('voice.vip-eve.it')} />
                                    </strong>
                                </EuiFlexItem>

                                <EuiFlexItem>
                                    Port:<br />
                                    <strong>
                                        64738
                                        <EuiButtonIcon  aria-label="Copy port"  iconType="copy"
                                                        onClick={() => copyToClipboard('64738')} />
                                    </strong>
                                </EuiFlexItem>

                                <EuiFlexItem>
                                    Username:<br />
                                    <strong>
                                        {mumbleUsername}
                                        <EuiButtonIcon  aria-label="Copy username"  iconType="copy"
                                                        onClick={() => copyToClipboard(mumbleUsername)} /></strong>
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                        : <EuiFlexItem>
                            <EuiText>You don't have permissions to access Mumble</EuiText>
                        </EuiFlexItem>
                    }
                </EuiFlexGroup>
            </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText>
            <h3>
              <strong>Registered Characters:</strong>
            </h3>
          </EuiText>
        </EuiFlexItem>
        {
          userLogin.Alts.length > 0 &&
            <EuiFlexItem grow={false}>
              <EuiButton iconType='gear' disabled>
                Change Main
              </EuiButton>
            </EuiFlexItem>
        }
        {
          userLogin.fullEsi &&
            <EuiFlexItem grow={false}>
              <EuiLink href={`/login?account=${userLogin.CharacterID}`}>
                <EuiButton iconType='link' color='primary'>
                  Add Alt
                </EuiButton>
              </EuiLink>
            </EuiFlexItem>
        }
      </EuiFlexGroup>
      <EuiSpacer size='xl' />
      <EuiFlexGrid columns={1}>
        {
          [userLogin.CharacterID, ...userLogin.Alts].map(alt => (
            <EuiFlexItem key={alt} style={{ width: '100%' }}>
              <CharacterSheet characterId={alt} small />
            </EuiFlexItem>
          ))
        }
      </EuiFlexGrid>
    <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={6000}
    />
    </EuiPageTemplate.Section>
  )
}

export default Dashboard
