import { useState } from 'react'
import sortBy from 'lodash.sortby'

import {
  EuiText,
  EuiSpacer,
  EuiPageTemplate,
  EuiTextArea,
  EuiButton,
  EuiInMemoryTable,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

import api from '../api'

const CORP_YELLOW = 20
const CORP_GREEN = 30
const ALLY_YELLOW = 20
const ALLY_GREEN = 30

const COLORS = [
  '#f38ba8', // RED
  '#f9e2af', // YELLOW
  '#a6e3a1' // GREEN
]
const TEXT_COLORS = {
  [COLORS[0]]: '#11111b',
  [COLORS[1]]: '#11111b',
  [COLORS[2]]: '#11111b'
}


const AdminFATs = () => {
  const [allianceFATs, setAllianceFATs] = useState('')
  const [result, setResult] = useState([])

  const doRequest = async () => {
    const r = await api.AdminGetFats(allianceFATs)
    setResult(sortBy(r.map(e => {
      let CorpColor = 0
      let AllianceColor = 0
      if (e.CorpFAT >= CORP_YELLOW) CorpColor = 1
      if (e.CorpFAT >= CORP_GREEN) CorpColor = 2
      if (e.AllianceFAT >= ALLY_YELLOW) AllianceColor = 1
      if (e.AllianceFAT >= ALLY_GREEN) AllianceColor = 2
      const Color = (
          (CorpColor === 2 && AllianceColor === 0) || 
          (AllianceColor === 2 && CorpColor === 0)
        ) ? 1
        : Math.min(CorpColor, AllianceColor)
      const Total = e.CorpFAT + e.AllianceFAT
      return {
        ...e,
        MainLower: e.Main.toLocaleLowerCase(),
        CorpColor: COLORS[CorpColor],
        AllianceColor: COLORS[AllianceColor],
        Color: COLORS[Color],
        Total
      }
    }), ['MainLower']))
  }

  return (
    <EuiPageTemplate.Section grow>
      <EuiText><h1>VIP FAT</h1></EuiText>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiTextArea fullWidth content={allianceFATs} onChange={e => setAllianceFATs(e.target.value)} />
          <EuiText textAlign='right'>
            <EuiButton disabled={allianceFATs.length === 0} onClick={doRequest} >Process</EuiButton>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <strong>CORP</strong><br />
          Green: {CORP_GREEN}<br />
          Yellow: {CORP_YELLOW}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
        <strong>ALLY</strong><br />
          Green: {ALLY_GREEN}<br />
          Yellow: {ALLY_YELLOW}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiInMemoryTable
        tableCaption='VIP FAT'
        items={result}
        search={true}
        columns={[
          {
            name: 'Main Char',
            truncateText: false,
            sortable: true,
            render: r => <EuiText color={r.Total === 0 ? COLORS[0] : 'white'}>
              {r.Main}
            </EuiText>
          },
          {
            name: 'Corp FAT',
            truncateText: false,
            render: r => 
              <EuiText textAlign='center' style={{
                width: '75px',
                borderRadius: '15px',
                backgroundColor: r.CorpColor,
                color: TEXT_COLORS[r.CorpColor]
              }}>
                {r.CorpFAT}
              </EuiText>
          },
          {
            name: 'Alliance FAT',
            truncateText: false,
            render: r => 
              <EuiText textAlign='center' style={{
                width: '75px',
                borderRadius: '15px',
                backgroundColor: r.AllianceColor,
                color: TEXT_COLORS[r.AllianceColor]
              }}>
                {r.AllianceFAT}
              </EuiText>
          },
          {
            name: 'Total',
            truncateText: false,
            render: r => 
              <EuiText textAlign='center' style={{
                width: '75px',
                borderRadius: '15px',
                backgroundColor: r.Color,
                color: TEXT_COLORS[r.Color]
              }}>
                {r.Total}
              </EuiText>
          }
        ]}
      />
    </EuiPageTemplate.Section>
  )
}

export default AdminFATs
