import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  EuiSpacer,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiLink,
  EuiSkeletonText
} from '@elastic/eui'

import Utils from '../Utils'
import api from '../api'
import FormatNumber from '../components/FormatNumber'

const TransactionEntry = ({ transaction }) => {
  const [objectType, setObjectType] = useState(null)

  const [clientPublicInfo, setClientPublicinfo] = useState({})
  const [updating, setUpdating] = useState(false)

  useEffect(
    () => {
      if (!objectType) { (async () => setObjectType(await api.SDE.GetType(transaction.type_id)))() }
    },
    [objectType, transaction.type_id]
  )

  useEffect(
    () => {
      if (clientPublicInfo && Object.keys(clientPublicInfo).length === 0) {
        setUpdating(true)
        ;(async () => {
          setUpdating(false)
          setClientPublicinfo(await api.GetPublicInfo(transaction.client_id))
        })()
      }
    },
    [clientPublicInfo, transaction.client_id]
  )

  if (updating) return <EuiSkeletonText lines={1} />

  const buy = transaction.is_buy ? -1 : 1
  const total = transaction.unit_price * transaction.quantity * buy
  return (
    <EuiFlexGroup alignItems='center' className='hoverable' gutterSize='none'>
      <EuiFlexItem grow={false}>
        <EuiText>{moment(transaction.date).format(Utils.DateFormat)}</EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <img
          src={Utils.GetTypeIcon(transaction.type_id)}
          width={32}
          alt={transaction.type_id}
          className='radius50'
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText>
          {!objectType
            ? (
              <EuiSkeletonText lines={1} />
              )
            : (
              <>
                x{transaction.quantity} <strong>{objectType.name.en}</strong>
              </>
              )}
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText textAlign='right'>
          <FormatNumber amount={total} isTransaction />
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {updating
          ? (
            <EuiSkeletonText lines={1} />
            )
          : (
            <EuiLink
              href={Utils.ZKillboard('character', transaction.client_id)}
              target='_blank'
              external={false}
            >
              <img
                src={Utils.GetCharacterPortrait(transaction.client_id, 32)}
                width={30}
                alt={clientPublicInfo.name}
                className='radius50'
              />
            </EuiLink>
            )}
      </EuiFlexItem>
      <EuiFlexItem>
        {updating
          ? (
            <EuiSkeletonText lines={1} />
            )
          : (
            <EuiLink
              href={Utils.ZKillboard('character', transaction.client_id)}
              target='_blank'
              external={false}
            >
              {clientPublicInfo.name}
            </EuiLink>
            )}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

TransactionEntry.propTypes = {
  transaction: PropTypes.object.isRequired
}

const Transactions = ({ characterId }) => {
  const [transactionsList, setTransactionsList] = useState({})
  const [errors, setErrors] = useState(null)

  useEffect(() => {
    if (transactionsList && Object.keys(transactionsList).length > 0) return
    (async () => {
      try {
        const res = await api.GetWalletTransactions(characterId)
        res.length !== 0 && setTransactionsList(res)
      } catch (err) {
        setErrors(err)
      }
    })()
  }, [characterId, transactionsList])

  if (errors) return <EuiText color='danger'>{errors.toString()}</EuiText>
  if (!transactionsList) return <EuiSkeletonText lines={3} />
  if (transactionsList.error) {
    return <EuiText color='danger'>
      An error has occurred. Please try again later.
    </EuiText>
  }

  return (
    <>
      <EuiSpacer />
      <EuiTitle>
        <h3>Market Transactions</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem>
          {Object.keys(transactionsList).map(transIndx => (
            <TransactionEntry
              key={transactionsList[transIndx].transaction_id}
              transaction={transactionsList[transIndx]}
            />
          ))}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

Transactions.propTypes = {
  characterId: PropTypes.number.isRequired
}

export default Transactions
