import {
  EuiPageTemplate,
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiText,
  EuiPanel,
  EuiSpacer
} from '@elastic/eui'

import vipLogo from '../images/vip.png'
import loginButton from '../images/ssologin.png'

const Login = () => {

  return <EuiPageTemplate className='ssoBackgroundFullVH'>
    <EuiPageTemplate.EmptyPrompt title={<EuiFlexGroup alignItems='center' justifyContent='center'>
        <EuiFlexItem grow={false}>
          <EuiAvatar
            imageUrl={vipLogo}
            size='xl'
            name='Very Italian People'
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <h2>Very Italian People</h2>
        </EuiFlexItem>
      </EuiFlexGroup>}>
      <EuiPanel>
        <EuiFlexGroup alignItems='center'>
          <EuiFlexItem>
            <EuiText textAlign='center' color='white'>
              VIP &amp; PizzaBoys Login
          </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText textAlign='center'>
              <EuiLink href='/login'>
            <img src={loginButton} style={{ marginBottom: 0 }} alt='VIP / PizzaBoys Login' />
          </EuiLink>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
      <EuiSpacer />
      <EuiPanel>
        <EuiFlexGroup alignItems='center'>
          <EuiFlexItem>
            <EuiText textAlign='center' color='white'>
              Blue Login
          </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText textAlign='center'>
              <EuiLink href='/login?ssoType=public'>
            <img src={loginButton} style={{ marginBottom: 0 }} alt='Blue Login' />
          </EuiLink>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
    </EuiPageTemplate.EmptyPrompt>
  </EuiPageTemplate>
}

export default Login
