import { EuiAvatar, EuiBadge, EuiButton, EuiFlexGroup, EuiFlexItem, EuiPageTemplate, EuiPanel, EuiSpacer, EuiText, EuiTitle } from "@elastic/eui"
import React, { useEffect, useState } from "react"
import orderBy from 'lodash.orderby'
import api from "../api"

import utils from "../Utils"

import moment from "moment"
import LocationName from "../components/LocationName"
import ItemName from "../components/ItemName"
import ItemIcon from "../components/ItemIcon"

const Character = ({ characterID, isSmall, participants }) => {
  const [publicInfo, setPublicInfo] = useState(null)
  const [isMain, setIsMain] = useState(false)

  useEffect(() => {
    api.GetPublicInfo(characterID).then(setPublicInfo)
    api.FCIsMain(characterID).then(r => setIsMain(r.result))
  }, [characterID])

  const fleetMember = participants.find(p => p.CharacterID === characterID)
  
  return <EuiFlexGroup alignItems='center'>
    <EuiFlexItem grow={false} style={{ width: '40px', alignItems: 'center' }}>
      <EuiAvatar imageUrl={utils.GetCharacterPortrait(characterID)} size={isSmall ? 's' : 'l'} name={`${publicInfo?.name}`} />
    </EuiFlexItem>
    <EuiFlexItem>
      <EuiText size='s'>
        <strong>{publicInfo?.name || characterID}</strong>
        { !isSmall && <>
          <br />
          <EuiBadge color={isMain ? 'success' : 'danger'}>{ isMain ? 'main' : 'alt' }</EuiBadge>
        </>}
      </EuiText>
    </EuiFlexItem>
    
    {
      !isSmall && fleetMember && <>
        <EuiFlexItem grow={false}><ItemIcon typeID={fleetMember?.ShipType} /></EuiFlexItem>
        <EuiFlexItem><strong><ItemName typeID={fleetMember?.ShipType} /></strong></EuiFlexItem>
        <EuiFlexItem><LocationName systemID={fleetMember?.SolarSystem} /></EuiFlexItem>
      </>
    }
  </EuiFlexGroup>
}

const Fleet = ({ fleet }) => {
  const [participantsOpen, setParticipantsOpen] = useState(false)
  return <>
    <EuiPanel>
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem>
          <strong>{fleet.FleetType}</strong>
          <i>{fleet.Points} {fleet.Points === 1 ? 'point' : 'points'}</i>
          <small>{fleet.FleetID}</small>
        </EuiFlexItem>
        <EuiFlexItem>
          {moment(fleet.StartTime).format(utils.DateFormat)}<br />
          {moment(fleet.EndTime).format(utils.DateFormat)}<br />
          Duration: {moment.duration(moment(fleet.EndTime).diff(moment(fleet.StartTime))).humanize()}
        </EuiFlexItem>
        <EuiFlexItem>
          <Character characterID={fleet.FC} participants={fleet.Participants} isSmall /><br />
          <strong>Participants: {fleet.Participants.length}</strong>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiButton iconType={participantsOpen ? 'arrowDown' : 'arrowRight'} onClick={() => setParticipantsOpen(!participantsOpen)}>
          Participants
      </EuiButton>
        { participantsOpen && <EuiPanel color="subdued">
          {
            orderBy(fleet.Participants, ['ShipType', 'SolarSystem']).map(m => <Character key={m.CharacterID} characterID={m.CharacterID} participants={fleet.Participants} />)
          }
      </EuiPanel> }
    </EuiPanel>
    <EuiSpacer />
  </>
}

const FCFleetList = () => {
  const [fleets, setFleets] = useState([])
  const [page, setPage] = useState(0)
  const [isLoading, setLoading] = useState(false)


  useEffect(_ => {
    setLoading(true)
    api.FCGetFleets(page).then(f => {
      setFleets(f)
      setLoading(false)
    })
  }, [page])

  const paginator = (
    <EuiFlexGroup alignItems='center' justifyContent='spaceBetween'>
      <EuiFlexItem grow={false}>
        <EuiButton
          iconType='arrowLeft'
          iconSide='left'
          onClick={() => setPage(page - 1)}
          disabled={isLoading || page <= 0}
        >
          Previous
        </EuiButton>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButton
          iconType='arrowRight'
          iconSide='right'
          onClick={() => setPage(page + 1)}
          disabled={isLoading || fleets.length < 10}
        >
          Next
        </EuiButton>
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  return <EuiPageTemplate.Section grow>
    <EuiTitle>
      <h3>Fleet List</h3>
    </EuiTitle>
    <EuiSpacer />
    {paginator}
    <EuiSpacer />
    {fleets.map(fleet => <Fleet fleet={fleet} key={fleet._id} />)}
    <EuiSpacer />
    {paginator}
  </EuiPageTemplate.Section>
}

export default FCFleetList
