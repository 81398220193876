import { useNavigate } from 'react-router-dom'
import {
  EuiAvatar,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink, EuiPageTemplate,
  EuiPanel,
  EuiSpacer,
  EuiText, EuiTitle
} from '@elastic/eui'

import vipLogo from '../images/vip.png'


const Landing = () => {
  const navigate = useNavigate()

  return <EuiPageTemplate className='ssoBackgroundFullVH'>
    <EuiFlexGroup alignItems='center' justifyContent='center'>
      <EuiFlexItem style={{ maxWidth: '75%' }}>
        <EuiSpacer size='xxl' />
        <EuiPanel>
          <EuiFlexGroup alignItems='center' justifyContent='center'>
            <EuiFlexItem grow={false}><EuiAvatar imageUrl={vipLogo} name='Very Italian People' size='xl' /></EuiFlexItem>
            <EuiFlexItem grow={false}><EuiTitle><h2>Very Italian People</h2></EuiTitle></EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size='xxl' />
          <div style={{ maxWidth: '850px', margin: 'auto' }}>
            <EuiText>An EVE Online corporation that is part of the competitive alliance "Init." Our corporation is focused on PvP gameplay and we are actively recruiting skilled pilots who are interested in participating in large-scale fleet battles, small-scale skirmishes and a wide range of other activities.</EuiText>
            <EuiSpacer size='xxl' />
            <EuiText>As part of the Init alliance, we have access to a wide range of resources and opportunities for PvP and non-PvP content. Our alliance is well-known for its aggressive and successful tactics, and we are constantly pushing the boundaries of what is possible in EVE Online.</EuiText>
            <EuiSpacer size='xxl' />
            <EuiText>In addition to fleet battles, we also provide a variety of corp other activities for our members such as frequent small-scale roam, wormhole krabbing, moon mining and more. We strive to offer a diverse set of content for our members to enjoy and participate in.</EuiText>
            <EuiSpacer size='xxl' />
            <EuiText>If you're looking for an exciting and challenging gaming experience, our corporation is the perfect fit for you. We have a strong community of players who are always willing to help out new members and share their knowledge and experience.</EuiText>
            <EuiSpacer size='xxl' />
          </div>
          <EuiTitle><EuiText textAlign='center'><h2>How to apply to join Very Italian People</h2></EuiText></EuiTitle>
          <EuiSpacer size='l' />
          <div style={{ maxWidth: '850px', margin: 'auto' }}>
            <EuiText>
              <ul>
                <li>Follow <EuiLink onClick={_ => navigate('/')}>this link</EuiLink> and register your alts</li>
                <li>Join our <EuiLink href="https://discord.gg/Z4hQ4VR7" target="_blank">Discord server</EuiLink> or add the in-game chat "<strong>VIP. PUB</strong>"</li>
                <li>Contact one of our Officers/Directors in discord or in game and ask to join us</li>
              </ul>
            </EuiText>
          </div>
          <EuiSpacer size='xxl' />
          <EuiTitle><EuiText textAlign='center'><h2>Also, check out some of our PvP engagements</h2></EuiText></EuiTitle>
          <EuiSpacer size='l' />
          <EuiFlexGroup wrap={true} alignItems='center' justifyContent='center'>
            <EuiFlexItem>
              <EuiText textAlign='center'>
                Nix at the gate, Bump the Nix! <EuiLink href="https://zkillboard.com/kill/83116952/" target="_blank">Nyx killmail</EuiLink>
                <EuiSpacer size='xs' />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/70WOqQ3iDOc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText textAlign='center'>
                It's Christmas guys! It's Christmas!
                <EuiSpacer size='xs' />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/P8jkGbZeXGI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText textAlign='center'>
                Another day of the week!
                <EuiSpacer size='xs' />
                <iframe width="560" height="315" src="https://www.youtube.com/embed/9l6Udy9uwRg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
        <EuiSpacer size='xxl' />
      </EuiFlexItem>
    </EuiFlexGroup>
  </EuiPageTemplate>
}

export default Landing
