
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  EuiSpacer,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSkeletonText,
  EuiAccordion
} from '@elastic/eui'

import Utils from '../Utils'
import api from '../api'

const Location = ({ locationType, locationId, numImplants }) => {
  const [locationDetails, setLocationDetails] = useState({})
  useEffect(() => {
    if (Object.keys(locationDetails || {}).length === 0) {
      (async () => {
        let res = {}
        if (locationType === 'structure') res = await api.GetStructure(locationId)
        if (locationType === 'station') res = await api.GetStation(locationId)
        setLocationDetails(res)
      })()
    }
  }, [locationType, locationId, locationDetails])
  if (Object.keys(locationDetails || {}).length === 0) return <EuiSkeletonText lines={3} />

  if (locationDetails?.error) return <EuiText color='danger'>An error has occurred. Please try again later.</EuiText>

  if (Object.keys(locationDetails || {}).length === 0) return <EuiSkeletonText lines={1} />

  return (
    <EuiFlexGroup alignItems='center'>
      <EuiFlexItem grow={false}>
        <img src={Utils.GetTypeIcon(locationDetails.type_id)} width={48} alt={locationDetails.name} className='radius50' />
      </EuiFlexItem>
      <EuiFlexItem>
        {locationDetails.name}
        {
        numImplants !== undefined &&
          <EuiText color='subdued'>{numImplants} implant{numImplants !== 1 ? 's' : ''}</EuiText>
      }
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

Location.propTypes = {
  locationType: PropTypes.string.isRequired,
  locationId: PropTypes.number.isRequired,
  numImplants: PropTypes.number
}

const Implant = ({ typeId }) => {
  const [typeInfo, setTypeInfo] = useState({})
  useEffect(() => {
    if (Object.keys(typeInfo || {}).length === 0) (async () => setTypeInfo(await api.SDE.GetType(typeId)))()
  }, [typeId, typeInfo])
  if (typeInfo?.error) return <EuiText color='danger'>An error has occurred. Please try again later.</EuiText>

  if (Object.keys(typeInfo || {}).length === 0) return <EuiSkeletonText lines={1} />

  return (
    <EuiFlexGroup alignItems='center'>
      <EuiFlexItem grow={false}>
        <img src={Utils.GetTypeIcon(typeId)} width={32} alt={typeInfo.name.en} className='radius50' />
      </EuiFlexItem>
      <EuiFlexItem>
        {typeInfo.name.en}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

Implant.propTypes = {
  typeId: PropTypes.number.isRequired
}

const Clones = ({ characterId }) => {
  const [clones, setClones] = useState({})
  const [implants, setImplants] = useState([])
  const [errors, setErrors] = useState(null)

  useEffect(() => {
    if (Object.keys(clones || {}).length > 0) return 
    (async () => {
      try {
        const res = await api.GetClones(characterId)
        res.length !== 0 && setClones(res)
      } catch (err) {
        setErrors(err)
      }
    })()
  }, [characterId, clones])
  useEffect(() => {
    if (implants.length === 0) (async () => setImplants(await api.GetImplants(characterId)))()
  }, [characterId, implants])

  if (errors) return <EuiText color='danger'>{errors.toString()}</EuiText>
  if (Object.keys(clones || {}).length === 0) return <EuiSkeletonText lines={3} />

  if (clones?.error) return <EuiText color='danger'>An error has occurred. Please try again later.</EuiText>

  return (
    <>
      <EuiSpacer />
      <EuiTitle>
        <h3>Home Station</h3>
      </EuiTitle>
      <EuiSpacer />
      <Location locationType={clones.home_location.location_type} locationId={clones.home_location.location_id} />
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem grow={false}>Last Clone Jump</EuiFlexItem>
        <EuiFlexItem>{moment.utc(clones.last_clone_jump_date || 0).format(Utils.DateFormat)}</EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem grow={false}>Last Station Change</EuiFlexItem>
        <EuiFlexItem>{moment.utc(clones.last_station_change_date || 0).format(Utils.DateFormat)}</EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      {
      implants.length > 0 &&
        <>
          <EuiTitle>
            <h3>Current Implants</h3>
          </EuiTitle>
          <EuiSpacer />
          {implants.map(i => <Implant key={i} typeId={i} />)}
        </>
    }
      <EuiSpacer />
      <EuiTitle>
        <h3>Jump Clones</h3>
      </EuiTitle>
      <EuiSpacer />
      {
      (clones.jump_clones || []).map((jc, n) => {
        const implants = jc.implants || []
        implants.sort()
        return (
          <>
            <EuiAccordion
              id={`JC${jc.location_id}_${n}`}
              key={`JC${jc.location_id}_${n}`}
              buttonContent={<Location locationType={jc.location_type} locationId={jc.location_id} numImplants={implants.length} />}
              arrowDisplay='right'
              paddingSize='l'
              className='skillGroup'
            >
              {
              implants.length > 0
                ? jc.implants.map(i => <Implant key={`JC${jc.location_id}_${n}_${i}`} typeId={i} />)
                : <EuiText color='subdued'>No implants</EuiText>
            }
            </EuiAccordion>
            <EuiSpacer />
          </>
        )
      })
    }
    </>
  )
}

Clones.propTypes = {
  characterId: PropTypes.number.isRequired
}

export default Clones
