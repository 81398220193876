import { useState, useEffect } from 'react'
import moment from 'moment'
import { useRecoilState } from 'recoil'
import { NumericFormat } from 'react-number-format'

import {
  EuiBadge,
  EuiPageTemplate,
  EuiTitle,
  EuiFieldText,
  EuiButton,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiSkeletonText,
  EuiAvatar,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiTextArea,
  EuiGlobalToastList,
  EuiImage
} from '@elastic/eui'

import sprImg from '../images/srp.png'

import FormatNumber from '../components/FormatNumber'
import Utils from '../Utils'
import api from '../api'
import userState from '../userState'

const SRPStatus = ({ status }) => {
  switch (status) {
    case 1:
      return <EuiBadge color='primary'>Accepted</EuiBadge>
    case 2:
      return <EuiBadge color='danger'>Rejected</EuiBadge>
    case 0:
    default:
      return <EuiBadge color='default'>Pending</EuiBadge>
  }
}

const SRPPayout = ({ payoutType, payoutValue }) => {
  switch (payoutType) {
    case 1:
      return (
        <EuiBadge color='primary'>
          <NumericFormat
            value={parseFloat(payoutValue.toFixed(2), 10)}
            displayType='text'
            thousandSeparator
            suffix=' ISK'
          />
        </EuiBadge>
      )
    case 2:
      return (
        <EuiBadge color='primary'>
          <NumericFormat
            value={parseFloat(payoutValue.toFixed(2), 10)}
            displayType='text'
            thousandSeparator
            suffix=' ISK Contract'
          />
        </EuiBadge>
      )
    case 0:
    default:
      return null
  }
}

const RecentLoss = ({ data, onSuccess }) => {
  // eslint-disable-next-line no-unused-vars
  const [userLogin, setUserLogin] = useRecoilState(userState)
  const [solarSystem, setSolarSystem] = useState({
    name: 'Loading...',
    security_status: 0
  })
  const [ship, setShip] = useState({ name: { en: 'Loading...' } })
  const [comment, setComment] = useState('')
  const [character, setCharacter] = useState('Loading...')

  const [isModalVisible, setIsModalVisible] = useState(false)
  const closeModal = () => setIsModalVisible(false)
  const showModal = () => setIsModalVisible(true)

  const [toasts, setToasts] = useState([])

  useEffect(
    () => {
      ;(async () => {
        const charData = await api.GetPublicInfo(data.characterID)
        setCharacter(charData.name)
      })()
    },
    [data.characterID]
  )

  const addToast = success =>
    setToasts(
      toasts.concat({
        title: 'SRP Request',
        color: success ? 'success' : 'danger',
        text: success
          ? <p>Your request has been submitted successfully</p>
          : <p>Something went wrong submitting your request</p>
      })
    )

  const removeToast = removedToast =>
    setToasts(toasts.filter(toast => toast.id !== removedToast.id))

  const submitSRPRequest = async () => {
    closeModal()
    const { result } = await api.RequestSRP({
      ...data,
      comment
    })
    addToast(result)
    if (result) onSuccess()
  }

  let modal

  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>SRP Request</EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            <EuiTextArea
              placeholder='FC / Fleet ping / Additional info...'
              value={comment}
              onChange={e => setComment(e.target.value)}
              fullWidth
            />
          </EuiModalBody>

          <EuiModalFooter>
            <EuiButton fill onClick={closeModal}>
              Close
            </EuiButton>
            <EuiButton fill color='primary' onClick={submitSRPRequest}>
              Request
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  useEffect(
    () => {
      ;(async () => {
        setShip(await api.SDE.GetType(data.shipType))
        setSolarSystem(await api.ESI.GetSolarSystem(data.solarSystem))
      })()
    },
    [data.shipType, data.solarSystem]
  )

  return (
    <EuiFlexItem>
      <EuiFlexGroup direction='row' alignItems='center'>
        <EuiFlexItem grow={false}>
          <EuiAvatar
            imageUrl={Utils.GetTypeRender(data.shipType)}
            size='l'
            name={ship.name.en}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <strong>{ship.name.en}</strong>
            <br />
            {character}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false} className='fix200'>
          <EuiText>
            {solarSystem.name}{' '}
            <small>({solarSystem.security_status.toFixed(2)})</small>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiText>
            <FormatNumber amount={data.totalValue} />
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiText>{moment.utc(data.time).format(Utils.DateFormat)}</EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {userLogin.CharacterID === data.characterID ||
          userLogin.Alts.includes(data.characterID)
            ? (
              <EuiButton
                fill
                color='primary'
                size='s'
                onClick={showModal}
              >
                Request SRP
              </EuiButton>
              )
            : (
              <EuiButton fill color='danger' size='s' disabled>
                Denied
              </EuiButton>
              )}
          {modal}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            onClick={() =>
              window.open(`http://zkillboard.com/kill/${data.killmailId}/`)}
            iconType='popout'
            aria-label='Open in zKillboard'
            size='s'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiGlobalToastList
        toasts={toasts}
        dismissToast={removeToast}
        toastLifeTimeMs={6000}
      />
    </EuiFlexItem>
  )
}

const SrpRequest = ({ data }) => {
  const [solarSystem, setSolarSystem] = useState({
    name: 'Loading...',
    security_status: 0
  })
  const [ship, setShip] = useState()

  useEffect(
    () => {
      ;(async () => {
        setShip(await api.SDE.GetType(data.shipType))
        setSolarSystem(await api.ESI.GetSolarSystem(data.solarSystem))
      })()
    },
    [data.shipType, data.solarSystem]
  )

  return (
    <EuiFlexItem>
      <EuiFlexGroup direction='row' alignItems='center'>
        <EuiFlexItem grow={false}>
          <EuiAvatar
            imageUrl={Utils.GetTypeRender(data.shipType)}
            size='xl'
            name={ship?.name?.en || 'Loading...'}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText>
            <strong>{ship?.name?.en || 'Loading...'}</strong>
            <br />
            {data.CharacterName}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem className='fix200'>
          <EuiText>
            {solarSystem.name}{' '}
            <small>({solarSystem.security_status.toFixed(2)})</small>
            <br />
            {data.comment}
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiText>
            <FormatNumber amount={data.totalValue} />
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiText>{moment.utc(data.time).format(Utils.DateFormat)}</EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false} className='fix200'>
          <EuiText>
            <SRPStatus status={data.status} />
            <SRPPayout
              payoutType={data.payoutType}
              payoutValue={data.payoutValue}
            />
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            onClick={() =>
              window.open(`http://zkillboard.com/kill/${data.killmailId}/`)}
            iconType='popout'
            aria-label='Open in zKillboard'
            size='s'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>
  )
}

const Srp = () => {
  const [rawRecentLosses, setRawRecentLosses] = useState(null)
  const [recentLosses, setRecentLosses] = useState(null)
  const [srpData, setSrpData] = useState(null)
  const [zkbLink, setZkbLink] = useState('')
  const [zkbLoss, setZkbLoss] = useState(null)

  const reloadList = async () => {
    // const { losses } = await api.GetRecentLosses()
    // setRawRecentLosses(losses)
    setSrpData(await api.GetSRP())
    setZkbLink('')
  }

  useEffect(() => {
    reloadList()
  }, [])

  // useEffect(
  //   () => {
  //     if (!srpData || !rawRecentLosses) return
  //     const srpIDs = srpData.map(l => l.killmailId)
  //     const filteredLosses = rawRecentLosses
  //       .filter(l => !srpIDs.includes(l.killmailId)) // Filter already requested SRPs
  //       .filter(
  //         l =>
  //           ![
  //             670,
  //             33328, // Capsules
  //             596,
  //             601,
  //             588,
  //             606 // Corvettes
  //           ].includes(l.shipType)
  //       ) // Filter non SPR ships
  //       .slice(0, 5) // View only 5 most recent losses
  //     setRecentLosses(filteredLosses)
  //   },
  //   [rawRecentLosses, srpData]
  // )

  useEffect(
    () => {
      if (zkbLink.length > 0) {
        ;(async () => {
          const { losses } = await api.GetLossFromURL(zkbLink)
          setZkbLoss(losses.length > 0 && losses[0])
        })()
      } else {
        setZkbLoss(null)
      }
    },
    [zkbLink]
  )

  return (
    <EuiPageTemplate.Section grow>
      <EuiTitle>
        <h3>Ship Replacement Program</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel>
            <EuiTitle>
              <strong>Informations</strong>
            </EuiTitle>
            <EuiSpacer />
            <EuiImage src={sprImg} alt='SRP' />
            <EuiSpacer size='xl' />
            <EuiText textAlign='center' color='subdued'>
              Per richiedere SRP premere il bottone "Reqest SRP" della relativa
              lossmail. Se la lossmail non dovesse essere presente nell'elenco é
              possibile importarla da zkillbord incollando l'url nell'apposito
              campo sottostante.
            </EuiText>
            <EuiSpacer />
            <EuiText textAlign='center' color='subdued'>
              To request SRP press the green button next to the relative
              lossmail. If the lossmail is not present in the list you can
              import it from Zkillboard by pasting the url in the box below.
            </EuiText>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      {/* <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel>
            <EuiTitle>
              <strong>Recent Losses</strong>
            </EuiTitle>
            <EuiSpacer />
            <EuiFlexGroup>
              <EuiFlexItem>
                {
                  recentLosses === null
                    ? <EuiSkeletonText lines={5} />
                    : <EuiFlexGroup direction='column'>
                        {recentLosses.map(recentLoss => (
                          <RecentLoss
                            key={recentLoss.killmailId}
                            data={recentLoss}
                            onSuccess={reloadList}
                          />
                        ))}
                      </EuiFlexGroup>
                }
                {
                  recentLosses !== null && recentLosses.length === 0 && <EuiText>No losses!</EuiText>
                }
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer /> */}
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel>
            <EuiTitle>
              <strong>Add From zKillboard</strong>
            </EuiTitle>
            <EuiSpacer />
            <EuiFlexGroup direction='column'>
              <EuiFlexItem>
                <EuiFlexGroup>
                  <EuiFlexItem>
                    <EuiFieldText
                      fullWidth
                      value={zkbLink}
                      onChange={e => setZkbLink(e.target.value)}
                    />
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
              <EuiFlexItem>
                {
                  zkbLoss === null
                    ? null
                    : <RecentLoss data={zkbLoss} onSuccess={reloadList} />
                }
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiPanel>
            <EuiTitle>
              <strong>Past Requests</strong>
            </EuiTitle>
            <EuiSpacer />
            <EuiFlexGroup>
              <EuiFlexItem>
                {
                  srpData === null
                  ? <EuiSkeletonText lines={5} />
                  : <EuiFlexGroup direction='column'>
                    {srpData.map(srpRequest => (
                      <SrpRequest
                        key={srpRequest.killmailId}
                        data={srpRequest}
                      />
                    ))}
                  </EuiFlexGroup>
                }
                {
                  srpData !== null && srpData.length === 0 &&
                  <EuiText>No SRP requests.</EuiText>
                }
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPageTemplate.Section>
  )
}

export default Srp
