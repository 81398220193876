import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'

import {
  EuiSpacer,
  EuiTitle,
  EuiSkeletonText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiText
} from '@elastic/eui'

import Utils from '../Utils'

import MailRecipient from '../components/MailRecipient'
import MailSender from '../components/MailSender'
import api from '../api'

const Mail = () => {
  const navigate = useNavigate()
  const characterId = parseInt(useParams().characterId, 10)
  const mailId = parseInt(useParams().mailId, 10)
  const [mail, setMail] = useState({})

  useEffect(
    () => {
      if (mail && Object.keys(mail).length === 0) { (async () => setMail(await api.GetMail(characterId, mailId)))() }
    },
    [characterId, mailId, mail]
  )

  if (!mail || Object.keys(mail).length === 0) { return <EuiSkeletonText lines={3} /> }
  if (!mail) return <EuiSkeletonText lines={5} />

  const div = document.createElement('div')
  div.innerHTML = mail.body.replace(/<br>/g, '\n')
  const text = div.textContent || div.innerText || ''

  return (
    <>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiButton
            onClick={() => navigate(`/character/${characterId}#mail`)}
            iconType='arrowLeft'
          >
            Back
          </EuiButton>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle>
            <h2>Mail</h2>
          </EuiTitle>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem>
          <EuiTitle>
            <h3>
              <strong>{mail && mail.subject}</strong>
            </h3>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {mail && moment.utc(mail.timestamp).format(Utils.DateFormat)}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText textAlign='left'>From:</EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiText textAlign='right'>To:</EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <MailSender sender={{ sender_id: mail.from }} larger />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {mail &&
            mail.recipients.map(recipient => (
              <MailRecipient
                key={recipient.recipient_id}
                recipient={recipient}
                larger
                right
              />
            ))}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiText>
            <pre className='mailBody'>{text}</pre>
          </EuiText>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

export default Mail
