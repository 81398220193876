import PropTypes from 'prop-types'
import { NumericFormat } from 'react-number-format'

const FormatNumber = ({ amount, suffix, ignoreColor, isTransaction }) => {
  let color = '#98A2B3'
  if (!ignoreColor) {
    if (amount < 0) color = isTransaction ? '#BD271E' : '#017D73'
    if (amount > 0) color = isTransaction ? '#017D73' : '#BD271E'
  }
  return (
    <span style={{ color }}>
      <NumericFormat
        value={parseFloat(amount.toFixed(2), 10)}
        displayType='text'
        thousandSeparator
        suffix={suffix || ' ISK'}
      />
    </span>
  )
}

FormatNumber.propTypes = {
  amount: PropTypes.number.isRequired,
  suffix: PropTypes.string,
  ignoreColor: PropTypes.bool,
  isTransaction: PropTypes.bool
}

export default FormatNumber
