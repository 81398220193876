import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  EuiLoadingSpinner,
  EuiFlexItem,
  EuiTextColor
} from '@elastic/eui'

import api from '../api'

const ZKillboardStatistics = ({ characterId }) => {
  const [stats, setStats] = useState(null)

  useEffect(() => {
    if (stats !== null) return
    api.GetZkbChar(characterId).then(setStats)
  }, [characterId, stats])

  if (!stats) return <EuiLoadingSpinner />

  const last1Month = { k: 0, d: 0 }
  const last3Months = { k: 0, d: 0 }
  const last6Months = { k: 0, d: 0 }
  const last12Months = { k: 0, d: 0 }

  if (stats.months) {
    const data = stats.months[moment().format('YYYYMM')]
    if (data) {
      last1Month.k += data.shipsDestroyed || 0
      last1Month.d += data.shipsLost || 0
    }

    for (let i = 0; i < 3; i++) {
      const x = stats.months[moment().subtract(i, 'months').format('YYYYMM')]
      if (!x) continue
      last3Months.k += x.shipsDestroyed || 0
      last3Months.d += x.shipsLost || 0
    }

    for (let i = 0; i < 6; i++) {
      const x = stats.months[moment().subtract(i, 'months').format('YYYYMM')]
      if (!x) continue
      last6Months.k += x.shipsDestroyed || 0
      last6Months.d += x.shipsLost || 0
    }

    for (let i = 0; i < 12; i++) {
      const x = stats.months[moment().subtract(i, 'months').format('YYYYMM')]
      if (!x) continue
      last12Months.k += x.shipsDestroyed || 0
      last12Months.d += x.shipsLost || 0
    }
  }

  const statStyle = {
    minWidth: '50px'
  }

  const kLimit = 100
  const multi1 = 1
  const multi3 = 1.5
  const multi6 = 3
  const multi12 = 4.5

  return (
    <>
      <EuiFlexItem grow={false} style={statStyle}>
        <EuiTextColor color='subdued'><small style={{ fontSize: 'smallest' }}>1 Month</small></EuiTextColor>
        <EuiTextColor color={last1Month.k < (kLimit * multi1) ? 'danger' : 'primary'}>K: {last1Month.k}</EuiTextColor>
        D: {last1Month.d}
      </EuiFlexItem>
      <EuiFlexItem grow={false} style={statStyle}>
        <EuiTextColor color='subdued'><small style={{ fontSize: 'smallest' }}>3 Months</small></EuiTextColor>
        <EuiTextColor color={last3Months.k < (kLimit * multi3) ? 'danger' : 'primary'}>K: {last3Months.k}</EuiTextColor>
        D: {last3Months.d}
      </EuiFlexItem>
      <EuiFlexItem grow={false} style={statStyle}>
        <EuiTextColor color='subdued'><small style={{ fontSize: 'smallest' }}>6 Months</small></EuiTextColor>
        <EuiTextColor color={last6Months.k < (kLimit * multi6) ? 'danger' : 'primary'}>K: {last6Months.k}</EuiTextColor>
        D: {last6Months.d}
      </EuiFlexItem>
      <EuiFlexItem grow={false} style={statStyle}>
        <EuiTextColor color='subdued'><small style={{ fontSize: 'smallest' }}>12 Months</small></EuiTextColor>
        <EuiTextColor color={last12Months.k < (kLimit * multi12) ? 'danger' : 'primary'}>K: {last12Months.k}</EuiTextColor>
        D: {last12Months.d}
      </EuiFlexItem>
    </>
  )
}

ZKillboardStatistics.propTypes = {
  characterId: PropTypes.number.isRequired
}

export default ZKillboardStatistics
