
import { useState } from 'react'
import { useRecoilState } from 'recoil'

import {
  EuiPageTemplate,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiStat,
  EuiSpacer,
  EuiText,
  EuiFieldNumber,
  EuiAvatar,
  EuiCallOut
} from '@elastic/eui'

import Utils from '../Utils'
import userState from '../userState'
import FormatNumber from '../components/FormatNumber'

const minPrice = 15000000

const Freight = () => {
  const [volume, setVolume] = useState(0)
  const [collateral, setCollateral] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [userLogin, setUserLogin] = useRecoilState(userState)

  const permissions = userLogin.Permissions || {}

  let reward = permissions.jfRate * volume + collateral / 100 * permissions.jfCollateral
  if (reward < minPrice) reward = minPrice

  const setNumberValue = (value, func) => {
    let v = parseInt(value, 10)
    if (Number.isNaN(v)) v = 0
    func(v)
  }

  const pickupStations = [
    {
      system: '1DQ1-A',
      name: '- 1-st Imperial Palace',
      type: 35834
    },
    {
      system: 'YZ-LQL',
      name: 'VII - Moon 1 - Guardian Angels Assembly Plant',
      type: 29388
    },
    {
      system: 'Esaeel',
      name: '- Beta',
      type: 35835
    },
    /*{
      system: 'Huna',
      name: '- Two',
      type: 35835
    },
    {
      system: 'Jasson',
      name: 'I - Moon 4 - Emperor Family Bureau',
      type: 29389
    }*/
  ]

  return (
    <EuiPageTemplate.Section grow>
      <EuiTitle>
        <h3>Freight Service</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiCallOut title='Attention' color='danger' iconType='alert'>
        <p>
          Freight Service is currently not available
        </p>
      </EuiCallOut>
      <EuiSpacer />
      <EuiFlexGroup className='blur'>
        <EuiFlexItem>
          <EuiPanel>
            <EuiTitle>
              <strong>Informations</strong>
            </EuiTitle>
            <EuiSpacer />
            <EuiText>
              Please follow the below instructions to correctly set up a courier contract to us, if you do not follow them correctly your contract will be <strong>REJECTED</strong>.
            </EuiText>
            <EuiSpacer />
            <EuiText color='danger'>
              <strong>No assembled containers!</strong> Please, do not include any kind of assembled containers in your courier contracts! This includes plastic wraps from other courier contracts. It will result in your contract being cancelled or delayed.
            </EuiText>
            <EuiSpacer />
            <EuiText color='accent'>
              Maximum volume per contract: <strong>358.000 m<sup>3</sup></strong>. If you need to move more volume, please split up in multiple contracts that respects maximum volume.
            </EuiText>
            <EuiSpacer />
            <EuiText color='subdued'>
              If you decide to not put any collateral, you won&apos;t get reimbursed in the possibility one of our JFs gets ganked.
            </EuiText>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup className='blur'>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat title={<FormatNumber amount={minPrice} isTransaction />} description='Min.' textAlign='center' titleColor='primary' />
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat title={`${permissions.jfRate} ISK/m3`} description='Rate' textAlign='center' titleColor='primary' />
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat title={`${permissions.jfCollateral} %`} description='Collateral' textAlign='center' titleColor='primary' />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiText className='blur'><h3><strong>Cost Calculator</strong></h3></EuiText>
      <EuiSpacer />
      <EuiFlexGroup className='blur'>
        <EuiFlexItem>
          <EuiPanel>
            <EuiFlexGroup direction='column'>
              <EuiFlexItem>
                <EuiStat title={<FormatNumber amount={volume} isTransaction suffix=' m3' />} description='Volume' textAlign='center' titleColor='primary' />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFieldNumber value={volume} fullWidth prepend='Volume' append='m3' onChange={e => setNumberValue(e.target.value, setVolume)} max={300000} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>
            <EuiFlexGroup direction='column'>
              <EuiFlexItem>
                <EuiStat title={<FormatNumber amount={collateral} isTransaction />} description='Collateral' textAlign='center' titleColor='primary' />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFieldNumber value={collateral} fullWidth prepend='Collateral' append='ISK' onChange={e => setNumberValue(e.target.value, setCollateral)} />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup className='blur'>
        <EuiFlexItem>
          <EuiPanel>
            <EuiStat title={<FormatNumber amount={reward} isTransaction />} description='Reward' textAlign='center' titleColor='primary' />
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup className='blur'>
        <EuiFlexItem>
          <EuiPanel>
            <EuiTitle>
              <strong>Contract Details</strong>
            </EuiTitle>
            <EuiSpacer />
            <EuiText>
              <ul>
                <li>Contract to: <strong>Very Italian Transports</strong></li>
                <li>Reward: <strong><FormatNumber amount={reward} isTransaction /></strong></li>
                <li>Collateral: <strong><FormatNumber amount={collateral} isTransaction /></strong></li>
                <li>Expiration: <strong>14 days</strong></li>
                <li>Days to complete: <strong>14 days</strong></li>
              </ul>
            </EuiText>
          </EuiPanel>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiPanel>
            <EuiTitle>
              <strong>Pick-up / Drop-off Locations</strong>
            </EuiTitle>
            <EuiSpacer />
            <EuiFlexGroup direction='column'>
              {
              pickupStations.map(station => <EuiFlexItem key={`${station.system} ${station.name}`}>
                <EuiFlexGroup direction='row' alignItems='center'>
                  <EuiFlexItem grow={false}>
                    <EuiAvatar imageUrl={Utils.GetTypeRender(station.type)} size='l' name={`${station.system} ${station.name}`} />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText>
                      <strong>{station.system}</strong> {station.name}
                    </EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
                                            </EuiFlexItem>)
            }
            </EuiFlexGroup>
          </EuiPanel>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPageTemplate.Section>
  )
}

export default Freight
