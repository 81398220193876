import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import orderBy from 'lodash.orderby'

import {
  EuiPageTemplate,
  EuiText,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiFieldSearch,
  EuiSelect,
  EuiAvatar,
  EuiBadge,
  EuiTextColor
} from '@elastic/eui'

import Api from '../api'

import Utils from '../Utils'
import userState from '../userState'

const AdminUsers = () => {
  // eslint-disable-next-line no-unused-vars
  const [userLogin, setUserLogin] = useRecoilState(userState)
  const [filter, setFilter] = useState('')
  const [users, setUsers] = useState([])

  const updateUsers = async () => setUsers(await Api.AdminGetUsers())

  useEffect(() => {
    updateUsers()
  }, [])

  const filteredList = orderBy(users, ['CharacterName'])
    .filter(character => character.CharacterName.toLocaleLowerCase().includes(filter.toLocaleLowerCase()))

  const aclOptions = [
    { value: 0, text: 'User' },
    { value: 1, text: 'FC' },
    { value: 2, text: 'HR' },
    { value: 3, text: 'Admin' }
  ]

  const changeAcl = async (characterId, access) => {
    await Api.AdminSetUserAccess(characterId, access)
    updateUsers()
  }

  return (
    <EuiPageTemplate.Section grow>
      <EuiText><h1>Users Administration</h1></EuiText>
      <EuiSpacer />
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem>
          <EuiFieldSearch
            fullWidth
            placeholder='Search Users..'
            value={filter}
            onChange={e => setFilter(e.target.value)}
            aria-label='Filter Users'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      {
      filteredList.map(char => {
        let altsBadgeColor = 'hollow'
        if (char.Alts.length === 0) altsBadgeColor = 'danger'
        if (char.Alts.length >= 15) altsBadgeColor = 'accent'
        return (
          <EuiPanel key={char.CharacterID} paddingSize='s'>
            <EuiFlexGroup alignItems='center'>
              <EuiFlexItem grow={false}>
                <EuiAvatar
                  name={char.CharacterName}
                  size='l'
                  imageUrl={Utils.GetCharacterPortrait(char.CharacterID, 128)}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiText>
                  <strong>{char.CharacterName}</strong>
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiBadge color={altsBadgeColor}>
                  {char.Alts} Alt<small>/s</small>
                </EuiBadge>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                {
                char.Discord
                  ? <EuiFlexGroup alignItems='center'>
                    <EuiFlexItem>
                      <EuiText>
                        <small style={{ fontSize: 'smaller' }}>Discord:</small><br />
                        <strong dangerouslySetInnerHTML={{ __html: (char.Discord.username || '').replace(/ /i, '&nbsp;') }} />
                        { char.Discord.discriminator !== '0' && <EuiTextColor color='subdued'><small stype={{ fontSize: 'smaller' }}>#{char.Discord.discriminator}</small></EuiTextColor> }
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiAvatar
                        name={`${char.Discord.username}#${char.Discord.discriminator}`}
                        imageUrl={Utils.GetDiscordAvatar(char.Discord)}
                        size='l'
                      />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                  : <EuiText color='danger' className='blink'>Discord not linked</EuiText>
              }
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiText>
                  <EuiSelect
                    options={aclOptions}
                    value={char.Access}
                    onChange={e => changeAcl(char.CharacterID, e.target.value)}
                    aria-label='Access Level'
                    disabled={userLogin.CharacterID === char.CharacterID}
                  />
                </EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiPanel>
        )
      })
    }
    </EuiPageTemplate.Section>
  )
}

export default AdminUsers
