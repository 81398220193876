import { EuiAvatar, EuiBadge, EuiButton, EuiContextMenuItem, EuiContextMenuPanel, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPageTemplate, EuiPanel, EuiPopover, EuiSpacer, EuiText, EuiTitle } from "@elastic/eui"
import React, { useCallback, useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import api from "../api"

import userState from "../userState"
import utils from "../Utils"

import moment from "moment"
import LocationName from "../components/LocationName"
import ItemName from "../components/ItemName"
import ItemIcon from "../components/ItemIcon"

const levels = [
  {
    label: 'CTA',
    points: 6,
    description: 'Absolutely Critical Op. Major Defensive stuff. Everyone is needed for it, plan to be there. No excuses.'
  },
  {
    label: 'StratOP',
    points: 4,
    description: 'Strategic Op, if you can attend it pls do. Mainly offensive and some minor defensive stuff too.'
  },
  {
    label: 'Roaming',
    points: 2,
    description: 'Fun Op, if you want to have fun with small gang fights and learn pvp mechanics make sure to join!'
  },
  {
    label: 'Logistics/Administration',
    points: 1,
    description: 'For all structure maintenance and logistics, probing and prepping Wormhole and OPs, and for administration stuff!'
  },
]

const Character = ({ fleetMember, isSmall }) => {
  const [publicInfo, setPublicInfo] = useState(null)
  const [isMain, setIsMain] = useState(false)

  useEffect(() => {
    api.GetPublicInfo(fleetMember.character_id).then(setPublicInfo)
    api.FCIsMain(fleetMember.character_id).then(r => setIsMain(r.result))
  }, [fleetMember])

  return <EuiFlexGroup alignItems='center'>
    <EuiFlexItem grow={false} style={{ width: '40px', alignItems: 'center' }}>
      <EuiAvatar imageUrl={utils.GetCharacterPortrait(fleetMember.character_id)} size={isSmall ? 's' : 'l'} name={`${publicInfo?.name}`} />
    </EuiFlexItem>
    <EuiFlexItem>
      <EuiText size={isSmall ? 'xs' : 's'}>
        <strong>{publicInfo?.name || fleetMember.character_id}</strong>
        <br />
        {fleetMember.role_name} <EuiBadge color={isMain ? 'success' : 'danger'}>{ isMain ? 'main' : 'alt' }</EuiBadge>
      </EuiText>
    </EuiFlexItem>
    
    <EuiFlexItem grow={false}><ItemIcon typeID={fleetMember.ship_type_id} /></EuiFlexItem>
    <EuiFlexItem><strong><ItemName typeID={fleetMember.ship_type_id} /></strong></EuiFlexItem>
    <EuiFlexItem><LocationName systemID={fleetMember.solar_system_id} /></EuiFlexItem>
  </EuiFlexGroup>
}

const Fleet = ({ characterId, tracked, callback }) => {
  const [fleetData, setFleetData] = useState(null)
  const [fleetMembers, setFleetMembers] = useState(null)
  const [isPopoverOpen, setPopover] = useState(false)

  useEffect(() => {
    api.FCGetCurrentFleet(characterId).then(setFleetData).catch(e => {})
  }, [characterId])
  useEffect(() => {
    if (fleetData === null) return
    api.FCGetCurrentFleetMembers(characterId, fleetData.fleet_id).then(setFleetMembers).catch(e => {})
  }, [characterId, fleetData])

  const items = levels.map((l, k) => {
    const handleClick = () => {
      setPopover(false)
      api.FCTrackFleet(characterId, fleetData.fleet_id, l.points, l.label).then(callback)
    }
    return <EuiContextMenuItem key={k} icon={<EuiIcon type={`https://img.icons8.com/color/96/000000/${l.points}-c.png`} size='xl' />} onClick={handleClick}>
      <strong>{l.label}</strong> { ' — ' } {l.points} {l.points === 1 ? 'Point' : 'Points'}<br />
      <small>{l.description}</small>
    </EuiContextMenuItem>
  })

  if (fleetData === null || fleetMembers === null) return null
  return <>
    <EuiPanel>
      {
        tracked && !tracked.Ended
          ? <>
            <EuiText color='success'>
              Fleet is currently being tracked since: <strong>{moment(tracked.StartTime).format('LLL')}</strong>
            </EuiText>
            <EuiText color='subdued'>
              Fleet points: <strong>{tracked.Points}</strong>
            </EuiText>
          </>
          : <EuiPopover
            button={
              <EuiButton iconType='arrowDown' iconSide='right'>
                Track this fleet ({fleetMembers.length} Members)
              </EuiButton>
            }
            isOpen={isPopoverOpen}
            closePopover={() => setPopover(false)}
            panelPaddingSize='none'
            onClick={() => setPopover(!isPopoverOpen)}
          >
            <EuiContextMenuPanel items={items} />
          </EuiPopover>
      }
      <EuiSpacer />
      <Character fleetMember={fleetMembers.find(m => m.character_id === characterId)} />
      {
        fleetMembers.filter(m => m.character_id !== characterId).map(m => <Character key={m.character_id} fleetMember={m} isSmall />)
      }
    </EuiPanel>
    <EuiSpacer />
  </>
}

const FCFleetTracking = () => {
  // eslint-disable-next-line no-unused-vars
  const userLogin = useRecoilState(userState)[0]

  const [trackedFleets, setTrackedFleets] = useState([])

  const loadTracked = useCallback(() => api.FCGetTrackedFleets().then(setTrackedFleets), [])

  useEffect(() => {
    loadTracked()
  }, [loadTracked])

  return <EuiPageTemplate.Section grow>
    <EuiTitle>
      <h3>Fleet Tracking</h3>
    </EuiTitle>
    <EuiSpacer />
    <EuiText>Current fleets from your characters:</EuiText>
    <EuiSpacer />
    <Fleet characterId={userLogin.CharacterID} tracked={trackedFleets.find(f => f.FC === userLogin.CharacterID)} callback={loadTracked} />
    {
      userLogin.Alts.map(a => {
        return <Fleet key={a} characterId={a}  tracked={trackedFleets.find(f => f.FC === a)} callback={loadTracked} />
      })
    }
  </EuiPageTemplate.Section>
}

export default FCFleetTracking
