import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  EuiSpacer,
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton,
  EuiLink,
  EuiSkeletonText,
  EuiText
} from '@elastic/eui'

import Utils from '../Utils'
import api from '../api'

const CorpEntry = ({ corp }) => {
  const [corpPublicInfo, setCorpPublicInfo] = useState({})

  useEffect(
    () => {
      if (corpPublicInfo && Object.keys(corpPublicInfo).length === 0) {
        (async () =>
          setCorpPublicInfo(
            await api.GetCorporationPublicInfo(corp.corporation_id)
          ))()
      }
    },
    [corp.corporation_id, corpPublicInfo]
  )

  const diff = corp.end_date.diff(corp.start_date, 'days') + 1
  return (
    <>
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem grow={false}>
          <img
            src={Utils.GetCorporationLogo(corp.corporation_id, 64)}
            className='radius50'
            alt={corpPublicInfo.name}
            width={64}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <strong>
            [{corpPublicInfo.ticker}] {corpPublicInfo.name}
          </strong>
        </EuiFlexItem>
        <EuiFlexItem>
          {corp.start_date.format(Utils.DateFormat)}
          <br />
          {corp.end_date.format(Utils.DateFormat)}
        </EuiFlexItem>
        <EuiFlexItem style={{ minWidth: 100 }} grow={false}>
          {diff} {diff === 1 ? 'Day' : 'Days'}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton size='s'>
            <EuiLink
              href={Utils.ZKillboard('corporation', corp.corporation_id)}
              target='_blank'
              external={false}
            >
              zKillboard
            </EuiLink>
          </EuiButton>
          <EuiButton size='s'>
            <EuiLink
              href={Utils.EVEWho('corporation', corp.corporation_id)}
              target='_blank'
              external={false}
            >
              EVE Who
            </EuiLink>
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
    </>
  )
}
CorpEntry.propTypes = {
  corp: PropTypes.object.isRequired
}

const CorpHistory = ({ characterId }) => {
  const [corpHistory, setCorpHistory] = useState({})
  const [errors, setErrors] = useState(null)

  useEffect(
    () => {
      if (corpHistory && Object.keys(corpHistory).length > 0) return
      (async () => {
        try {
          const res = await api.GetCorpHistory(characterId)
          res.length !== 0 && setCorpHistory(res)
        } catch (err) {
          setErrors(err)
        }
      })()
    },
    [characterId, corpHistory]
  )

  if (errors) return <EuiText color='danger'>{errors.toString()}</EuiText>
  if (!corpHistory) return <EuiSkeletonText lines={3} />
  if (corpHistory.error) {
    return (
      <EuiText color='danger'>
        An error has occurred. Please try again later.
      </EuiText>
    )
  }

  for (const i in corpHistory) {
    const corp = corpHistory[i]
    if (i === '0') {
      corp.end_date = moment()
    } else {
      corp.end_date = moment(corpHistory[i - 1].start_date)
    }
    corp.start_date = moment(corp.start_date)
    corpHistory[i] = corp
  }
  return (
    <>
      <EuiSpacer />
      <EuiTitle>
        <h3>Corp History</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem>
          {corpHistory &&
            Object.keys(corpHistory).map(corpIndx => (
              <CorpEntry
                key={corpHistory[corpIndx].record_id}
                corp={corpHistory[corpIndx]}
              />
            ))}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

CorpHistory.propTypes = {
  characterId: PropTypes.number.isRequired
}

export default CorpHistory
