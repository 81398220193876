import { useState, useEffect, useMemo } from 'react'

import {
  EuiPageTemplate,
  EuiText,
  EuiSpacer,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButtonGroup,
  EuiBadge,
  EuiFieldSearch
} from '@elastic/eui'

import Utils from '../Utils'
import api from '../api'

const ESIEntry = ({ data, filter }) => {
  const [charInfo, setCharInfo] = useState({})
  const [mainInfo, setMainInfo] = useState({})
  useEffect(() => {
    (async () => setCharInfo(await api.GetPublicInfo(data.characterID)))()
  }, [data])
  useEffect(() => {
    if (data.main === 0) return
    (async () => setMainInfo(await api.GetPublicInfo(data.main)))()
  }, [data])

  if (filter.length > 0 && Object.keys(charInfo || {}).length > 0 && (data.main === 0 || Object.keys(mainInfo || {}).length > 0)) {
    const lowerFilter = filter.toLocaleLowerCase()
    let shouldDisplay = false
    if (charInfo.name.toLocaleLowerCase().includes(lowerFilter)) shouldDisplay = true
    if (data.main > 0 && mainInfo.name.toLocaleLowerCase().includes(lowerFilter)) shouldDisplay = true
    if (!shouldDisplay) return null
  }

  return (
    <EuiFlexItem key={data.CharacterID} className='hover'>
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem grow={false}>
          <img src={Utils.GetCharacterPortrait(data.characterID, 32)} width={32} alt={charInfo ? charInfo.name : 'Loading...'} className='radius50' />
        </EuiFlexItem>
        <EuiFlexItem>
          {charInfo ? charInfo.name : 'Loading...'}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {
        data.main === 0 || (mainInfo ? mainInfo.name : 'Loading...')
      }
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {
        data.main === 0 || <img src={Utils.GetCharacterPortrait(data.main, 32)} width={32} alt={mainInfo ? mainInfo.name : 'Loading...'} className='radius50' />
      }
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {
        data.main > 0 || <EuiBadge color={data.discord ? 'primary' : 'danger'}>Discord</EuiBadge>
      }
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiBadge color={data.esi ? 'primary' : 'danger'}>ESI</EuiBadge>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>
  )
}

const CorpSRP = () => {
  const [corporation, setCorporation] = useState('0')
  const [results, setResults] = useState([])
  const [filter, setFilter] = useState('')
  const corporations = useMemo(() => [
    {
      id: '0',
      value: 98630834,
      label: 'Very Italian People',
      text: 'VIP'
    },
    {
      id: '1',
      value: 98528272,
      label: 'Pizza Boys',
      text: 'PZBS'
    },
    {
      id: '2',
      value: 98644299,
      label: 'Very Italian Disinfestation',
      text: 'VID'
    },
    {
      id: '3',
      value: 98108996,
      label: 'Unknown Mining Venture',
      text: 'UNKO'
    }
  ], [])

  useEffect(() => {
    (async () => setResults(await api.GetCorpESI(corporations.find(x => x.id === corporation).value)))()
  }, [corporations, corporation])

  return (
    <EuiPageTemplate.Section grow>
      <EuiText><h1>Corporation ESI Check</h1></EuiText>
      <EuiSpacer />
      <EuiButtonGroup
        legend='Corporation'
        options={corporations}
        idSelected={corporation}
        onChange={setCorporation}
        buttonSize='compressed'
        isFullWidth
        color='primary'
      />
      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem>
          <EuiFieldSearch
            fullWidth
            placeholder='Search Main or Alts..'
            value={filter}
            onChange={e => setFilter(e.target.value)}
            aria-label='Filter Characters'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiFlexGroup direction='column' gutterSize='s'>
        {
        results.map(result => <ESIEntry key={result.characterID} filter={filter} data={result} />)
      }
      </EuiFlexGroup>
    </EuiPageTemplate.Section>
  )
}

export default CorpSRP
