import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

import {
  EuiSpacer,
  EuiTitle,
  EuiSkeletonText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiLink,
  EuiButtonIcon,
  EuiText
} from '@elastic/eui'

import Utils from '../Utils'

import MailRecipient from '../components/MailRecipient'
import MailSender from '../components/MailSender'
import api from '../api'

const MailEntry = ({ owner, mail }) => {
  const navigate = useNavigate()
  return (
    <>
      <EuiFlexGroup className='hoverable' gutterSize='none'>
        <EuiFlexItem>
          <EuiLink
            onClick={() => navigate(`/mail/${owner}/${mail.mail_id}`)}
          >
            {mail.subject}
          </EuiLink>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <MailSender sender={{ sender_id: mail.from }} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {mail.recipients.map(recipient => (
            <MailRecipient
              key={recipient.recipient_id}
              recipient={recipient}
              right
            />
          ))}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          {moment.utc(mail.timestamp).format(Utils.DateFormat)}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            onClick={() => window.open(`/mail/${owner}/${mail.mail_id}`)}
            iconType='popout'
            aria-label='Open in a new window'
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiHorizontalRule margin='s' />
    </>
  )
}

MailEntry.propTypes = {
  mail: PropTypes.object.isRequired,
  owner: PropTypes.number.isRequired
}

const Mails = ({ characterId }) => {
  const [mailList, setMailList] = useState({})
  const [errors, setErrors] = useState(null)

  useEffect(
    () => {
      if (mailList && Object.keys(mailList).length > 0) return 
      (async () => {
        try {
          const res = await api.GetMails(characterId)
          res.length !== 0 && setMailList(res)
        } catch (err) {
          setErrors(err)
        }
      })()
    },
    [characterId, mailList]
  )

  if (errors) return <EuiText color='danger'>{errors.toString()}</EuiText>
  if (!mailList || Object.keys(mailList).length === 0) return <EuiSkeletonText lines={3} />

  if (mailList.error) {
    return (
      <EuiText color='danger'>
        An error has occurred. Please try again later.
      </EuiText>
    )
  }

  return (
    <>
      <EuiSpacer />
      <EuiTitle>
        <h3>Mail</h3>
      </EuiTitle>
      <EuiSpacer />
      <EuiFlexGroup alignItems='center'>
        <EuiFlexItem>
          {mailList &&
            Object.keys(mailList).map(mailIndx => (
              <MailEntry
                key={mailList[mailIndx].mail_id}
                owner={characterId}
                mail={mailList[mailIndx]}
              />
            ))}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

Mails.propTypes = {
  characterId: PropTypes.number.isRequired
}

export default Mails
