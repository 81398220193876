
import { useState, useEffect, useMemo } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import {
  EuiPageTemplate,
  EuiSpacer,
  EuiTabs,
  EuiTab
} from '@elastic/eui'

import CharacterSheet from '../components/CharacterSheet'
import CorpHistory from './CorpHistory'
import Mails from './Mails'
import Contracts from './Contracts'
import Clones from './Clones'
import Transactions from './Transactions'
import Journal from './Journal'
import Contacts from './Contacts'
import Skills from './Skills'
import SkillQueue from './SkillQueue'
import RelatedCharacters from './RelatedCharacters'

const Profile = () => {
  const location = useLocation()
  const [selectedTabId, setSelectedTabId] = useState('chars')
  const characterId = parseInt(useParams().characterId, 10)

  const tabs = [
    {
      id: 'chars',
      name: 'Characters',
      content: <RelatedCharacters characterId={characterId} />
    },
    {
      id: 'corpHistory',
      name: 'Corp History',
      content: <CorpHistory characterId={characterId} />
    },
    {
      id: 'jumpClones',
      name: 'Jump Clones',
      content: <Clones characterId={characterId} />
    },
    {
      id: 'mail',
      name: 'Mail',
      content: <Mails characterId={characterId} />
    },
    {
      id: 'contracts',
      name: 'Contracts',
      content: <Contracts characterId={characterId} />
    },
    {
      id: 'market',
      name: 'Market',
      content: <Transactions characterId={characterId} />
    },
    {
      id: 'wallet',
      name: 'Wallet',
      content: <Journal characterId={characterId} />
    },
    {
      id: 'contacts',
      name: 'Contacts',
      content: <Contacts characterId={characterId} />
    },
    {
      id: 'skills',
      name: 'Skills',
      content: <Skills characterId={characterId} />
    },
    {
      id: 'skillQueue',
      name: 'Skill Queue',
      content: <SkillQueue characterId={characterId} />
    }
  ]

  const selectedTabContent = useMemo(() => {
    return tabs.find((obj) => obj.id === selectedTabId)?.content;
  }, [selectedTabId])

  const onSelectedTabChanged = id => setSelectedTabId(id)

  const renderTabs = () => {
    return tabs.map((tab, index) => (
      <EuiTab
        key={index}
        href={tab.href}
        onClick={() => onSelectedTabChanged(tab.id)}
        isSelected={tab.id === selectedTabId}
        disabled={tab.disabled}
        prepend={tab.prepend}
        append={tab.append}
      >
        {tab.name}
      </EuiTab>
    ))
  }

  return (
    <EuiPageTemplate.Section grow>
      <CharacterSheet characterId={characterId} />
      <EuiSpacer />
      <EuiTabs expand={true}>
        {renderTabs()}
      </EuiTabs>
      <EuiSpacer />
      {selectedTabContent}
    </EuiPageTemplate.Section>
  )
}

export default Profile
